@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.card {
  font-size: .875rem;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html, body, h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.masonry_tab_nav::-webkit-scrollbar {    width: 10px !important;
  height: 5px !important;}
.masonry_tab_nav::-webkit-scrollbar-thumb {
    background-color: #03377f;
}
.items_tab_new{cursor: pointer;}
.style-loader{
  display: "flex";
  flex-direction: "row"; 
  justify-content: "center"; 
  align-items: "center",;
  margin-top: "25vh"
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px !important;
  text-align: left;
  background-color: #fff;
  color: #787878 !important;
  overflow-x: hidden;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
.dark_logo .img-fluid,.light_logo .img-fluid{max-width:120px;}
header .img-fluid{max-width:150px;}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #03377f;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #641971;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
}
button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  --webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  --webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  --webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  --webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  --webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
span.gradbtn a{
  color: #fff;
}
.gradbtn.grad-bordbtn a{
  color: #000
}
.toggle_model .switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  transform: translateY(50%);
  position: relative;
}
.toggle_model.switch input {
  display: none;
}
.toggle_model .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #03377f, 0 0 4px #03377f;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: .4s;
  background: #03377f;
}
.toggle_model .slider:before{
  width: 100% !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " ("attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #9c27b0;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #641971;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
/* ............v............ */
:root{
  --theme-grad: -webkit-linear-gradient(89deg, #03377f,#03377f);
  --theme-pink:  #f05a2e;
}
.timed_sec .input-group-append p {
  line-height: 0.8;
}
.bordbot input {
  border: none !important;
}
.btn.cancel{
  background: #8080806e;
  color: black;
}
.btn.cancel:hover{
  background: #8080806e;
  color: black;
  font-weight: 600;
}
.followers_overall .card {
  box-shadow: 0 0 5px 5px #e2e2e2;

}
.proposal_panel_overall .col-12 .card .card-body {
  width: 100%;
}
.sociallinks span{
  display: inline-block;
}
div.mobile_nav {
  background: white !important;
  width: 400px !important;
}
.copyright_txt{
  color: #9d9d9e  !important;
  margin-bottom:0px;
  text-align:center;
}
.notfound{
  grid-column-start: 2;
    grid-column-end: 5;
}
footer .new_des_footer{
  width: 40%;
}

.scrollheight{
  height: auto;
}
.header-items button {
  padding-left: 0;
}
button.create_btn:focus {
  color: black !important;
  background: white;
}
.explore .card_inner_item h3 span{
  background: -webkit-linear-gradient(#03377f, #245278);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 14px;
}
.masonry_likes span {
  font-size: 12px;
  color: #9b9b9b;
  font-weight: 300;
}

.recentlyadded .remaintime .item_inner_img {
  height: auto;
  margin:10px auto;
  aspect-ratio: 1/1;
}
.recentlyadded .itemd_heih_adj .card_inner_item {
  min-height: 300px;
}
.d-grid {
  display: grid;
}
.remaintime .remaintime {
  position: absolute;
  bottom: 17px;
}
button.btn.newbtn {
  background-image: var(--theme-grad);
}
span.gradbtn {
  display: block;
  background-image: var(--theme-grad);
  height: 38px;
  width: 134px;
  margin: auto;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
}
span.gradbtn.grad-bordbtn {
  background: white;
  color: #000;
}
span.gradbtn.grad-bordbtn {
  background: white;
  color: #000;
}
button.btn.newbtn {
  background-image: var(--theme-grad);
  padding: 0;
  height: 42px;
  width: 138px;
  border-radius: 10px;
}
.header-items {
  flex: unset !important;
}
.header-input{
  width: 100%;
}
/* .header-input ul li input,.header-input{
  width: 100%;
} */
.header-input ul li input {
  border-radius: 5px;
}
/* .header-input li:first-child,.header-input ul{
  width: 100%;
} */
.nameimg img {
  height: 25px;
  width: 25px !important;
  border-radius: 50%;
  background: white;
  padding: 3px;
  box-shadow: 0 0 7px 4px #e6e6e6;
}
.nameimg {
  display: flex;
  justify-content: space-between;
}
.container-theme {
  max-width: 90% !important;
}
.badge-dark {
  background-image: var(--theme-grad);
}
.masonry .item h3 {
  background: var(----theme-grad);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.masonry .item h3 span {
  background: -webkit-linear-gradient(-11deg,#03377f,#03377f);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 13px;
}
.banner-r img {
 object-fit: contain;
 max-width:100%;
 width:100%;
 border-top-left-radius:20px;
 border-top-right-radius:20px;
}
.create_btn a {
  color: white;
}
 .noti_ul_dd li
 {
   list-style-type: none;
 
   padding-top: 10px;
   padding-bottom: 10px;
 }
 .buyeruliil li,.explore li
 {
   list-style-type: none;
 
   /* padding-top: 10px; */
   /* padding-bottom: 10px; */
 }
 .buyerbtnn .makeStyles-button-65{
   background-color: #fff !important;
   color: #000;
 }

 .noti_ul_dd
 {
   padding-left: 0px;
 }
 header .noti_ul #menu-list
{
background-color: #fff !important;
max-width: 300px !important;
box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -o-box-shadow: 0 0 10px rgba(0,0,0,0.6);
    border-radius: 0px !important;
    border:1px solid rgba(0,0,0,0.1);
}
header .main_navbar .MuiPaper-elevation1{max-width:350px !important;}
/* .noti_ul #menu-list:before
{
  content: '';
	display: block;  
	position: absolute;
	left: 47%;
	bottom: 100%;
	width: 0;
	height: 0;
	border-bottom: 10px solid #747474;
	border-top: 10px solid transparent;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
  top:-20px;
 
} */
.img_user_noti
{
 width:40px;
 height: 40px;
}
.img_user_activity
{
  width: 65px;
    height: 65px;
  border-radius: 50%;
  border: 1px solid #5b3d81;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}
.noti_ul #menu-list .noti_ul_dd li
{
 margin: 0px !important;
 margin-bottom: 0px !important;
 padding:15px 0px;
}
header .nto_scrol_div{width:auto;}
header .MuiList-padding li.MuiListItem-button{margin-left:0px;}
.noti_time
{
 color:#c8dbff;
 font-weight: 600;
}
.noti_msg
{
 color:#c8dbff;
 word-break: break-word;
 white-space: break-spaces;
}
.purple_circle_dot
{
  width: 10px;
  height: 10px;
  background-color: #7352a3;
  border-radius: 50%;
  display: inline-block;
}
.white_circle_dot
{
  width: 8px;
  height: 8px;
  background-color: #787473;
  border-radius: 50%;
  display: inline-block;
}
.font_10
{
  font-size: 10px !important;
}
header svg,.main_navbar svg
  {
    fill:#a879f6 !important;
  }
  header svg:hover,.main_navbar svg:hover
  {
    fill:#7352a3 !important;
    color:#7352a3 !important;
  }
  .noti_head .see_all_btn span
  {
font-size: 12px !important;
  }
  .noti_ul #menu-list {
    background-color: #fff !important;
    min-width: 250px !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -o-box-shadow: 0 0 10px rgba(0,0,0,0.6);
    border-radius: 25px;
}


@media only screen and (max-width: 1279px)
{

.noti_ul #menu-list {
    left: -35px;
    position: absolute;
}
}

.noti_head span {
    font-weight: 500;
    font-size: 20px;
    color: #fff;
}

.create_btn.see_all_btn {
    padding: 5px 8px !important;
    font-size: 12px !important;
    background-color: #7352a3 !important;
    height: 30px !important;
    min-width: unset !important;
    font-weight: 500 !important;
}

@media only screen and (max-width: 1279px)
{
/* .nto_scrol_div {
    height: 150px !important;
} */

.no_noti {
    color: #9a9696;
    font-size: 14px;
    text-align: center;
    padding: 20px 0px;
    margin-bottom: 0px;
}
}
@media only screen and (min-width: 1280px)
{
  .noti_parent {
    min-width: 350px !important;
}
.banner_head h3{font-size: 60px !important;}

.banercontentDet h2{font-size: 16px !important; }
.banercontent{width: 100% !important;}
.banercontentDet p{text-align: left;}
.banercontent img{width: 50px !important;}

.noti_parent_class {
  min-width: 300px !important;
}
}


@media only screen and (min-width: 1280px)

{.img_user_noti {
    width: 40px!important;
    height: 40px!important;
    margin-right: 20px!important;
}

}












.noti_ul_dd li img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 5px !important;
  border-radius: 0 !important;
}
.w-100{
  width:100%
}
/* .logo_span{position: absolute;top: 15px;left: 15px;} */
.wallet-lists {
  width: 100%;
  cursor: pointer;
  border: 1px solid #80808082;
  padding: 12px;
  border-radius: 7px;
  margin: 5px 0;
  display: inline-block;
}
.follow_media .media_text img {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}
.nav-tabs.masonry_tab .nav-link.active, .items_tab.nav-tabs.masonry_tab .nav-link.active {
  line-height: 2;
}
.items_tab.nav-tabs.masonry_tab .nav-link{
  color:#000;
  line-height: 2;
}
.mid-cont {
  max-height: 300px;
  width: 100%;
  text-align: center;
  position: relative;
}
.mid-cont audio{
  display: none;
}
.info .mid-cont audio{
  position: absolute;
  bottom: 20%;
  left: 30%;
}
.mid-cont .img-fluid {
  height: 100%;
}
.connect_ul li {
  list-style-type: none;
}
.connect_ul{
  padding-left: 0;
  
}
@media only screen and (min-width:319px) and (max-width:576px){
  .info .mid-cont audio{
    position: absolute;
    bottom: 5%;
    left: 10%;
  }
}







caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  --webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  --webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  --webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  --webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  --webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}
h1,
.h1 {
  font-size: 3.3125rem;
}
h2,
.h2 {
  font-size: 2.25rem;
}
h3,
.h3 {
  font-size: 1.5625rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 1.0625rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 7rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014 \00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e91e63;
  word-break: break-word;
}
a>code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* .......v.......... */
.items_tab.nav-tabs.masonry_tab .nav-link {
  box-shadow: 0 0 5px 4px #cfcaca;
}
.masonry_likes {
  white-space: nowrap;
}
.tab_head{
  display: block;
}
.hotcoll_lists {
  margin: 7px;
}
 .carousel-nav .owl-nav.disabled button,  .carousel-nav .owl-nav button{
  margin-top: 0;
}
.carousel-nav .owl-nav.disabled button, .carousel-nav .owl-nav button{
  border: 1px solid #f4f4f4 !important;
  background-image: -webkit-radial-gradient(45deg,#9433d1,#6933fc);
  height: 40px;
  color: #000 !important;
  width: 40px;
  border-radius: 50px;
  border-radius: 50px;
  box-shadow:0 0 6px 2px #00000021;
  opacity: 1;
}

.carousel-nav .owl-nav.disabled button span, .carousel-nav .owl-nav button span{
  color:#fff !important;
  font-weight: 300;
  padding: 5px 15px !important;
  font-size: 48px;
  line-height: 0.8;
}


.carousel-nav .owl-nav.disabled button:hover,.carousel-nav .owl-nav button:hover {
  background-image: linear-gradient(to bottom,#ffffff 0,#ffffff) !important;
  border: 1px solid #000;
  padding: 5px 15px !important;
}
.carousel-nav .owl-nav.disabled button:hover span, .carousel-nav .owl-nav button:hover span{
  color: #000 !important;
}

.masonry
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.masonry .item {
  display: inline-block;
  padding: 5px;
  width: 100%;
  --webkit-transition: 1s ease all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 20px;
}

.card_inner_item
{
  border-radius: 20px;
  padding: 15px 10px;
  box-shadow:0px 0px 20px -5px rgb(0 0 0 / 22%);
  background: #000;
  position: relative;
  margin: 5px;
}
.masonry .item:hover .card_inner_item{
  border-color: #e5e5ff !important;
}

.masonry .item h2 ,.navtabs .item h2,
 .dropdown-card .item h2{
  color: #000;
  font-size: 15px;
    font-weight: 500;
  text-align: left;
  margin: 0px !important;
  margin: 5px 0 8px !important;
}
.masonry .item h3,.navtabs .item h3 ,
 .dropdown-card .item h3{
  font-size: 14px;
  color: #000;
  font-weight: 900;
margin-top: 8px;
}
.masonry .item h4, .dropdown-car .item h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #fff;
}

.masonry_likes i {
  color: #fc6efc;
}

.masonry .item .fa-ellipsis-h {
  color: #d2d2d2;
}
.multiple_image_gallery {
  display: flex;
  flex-wrap: wrap;
}
.multiple_image_gallery img {
  margin: 3px;
  width: 65px;
}

.masonry_tab {
  border-radius: 0;
  white-space: nowrap;
  display: inline-block;
  min-width: 100%;
}
.nav-tabs.masonry_tab .nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 15px !important;
  display: inline-block;
  border-bottom: 0;
  background-color: #000;
  border: 1px solid #000;
  margin-bottom: 15px;
  /* margin: 0 15px; */
}
.nav-tabs.masonry_tab .nav-link.active,.items_tab.nav-tabs.masonry_tab .nav-link.active {
  background-color: #3d3d3d;
  color: #fff;
  border: 1px solid #3d3d3d;
}
.nav-tabs.masonry_tab .nav-link:hover, 
.nav-tabs.masonry_tab .nav-link:focus{

 color: #245278;
}
.items_tab.nav-tabs.masonry_tab .nav-link {
  border: none !important;
  position: relative;
  background-color: transparent;
  border-radius: 10px !important;
  /* padding-bottom: 15px !important; */
}
.explore_tab_content {
  margin-top: 30px;
}
.item_multiple {
  position: relative;
}
.items_tab_outer {
  padding-top: 15px;
  /* margin-top: -65px !important; */
}
/* .items_tab {
  height: 70px;
} */
.img_tick img
{
  max-width: 20px !important;
}
.img_tick.img_tick_lg
{
  top:-8px !important;
  right: 8px !important;
}
.img_tick.img_tick_lg img
{
  max-width: 18px !important;

}
.media_num.font_14
{
  font-size: 14px !important;
  line-height: 15px;
}
.img_tick.left_tick
{
  right: unset !important;
  left:3px !important;
}
.media_text_big_1 {
	font-size: 16px;
	color: #052f5f;
	line-height: 20px;
	margin-bottom: 5px;
	font-weight: 600;
}
.media_text_big_1 .text_blk {
	color: #052f5f;
	font-weight: 700;
}
.buynowbtnt button{
  border-radius: 5px;
  border: 1px solid #245278;
  color: #fff;
  padding: 3px 30px;
  margin-bottom: 0;
  background-color: #245278;
}
.buynowbtnt button:hover{color:#245278;background-color: transparent;}
.media_text_big_2 .text_blk {
	color: #052f5f;
	font-size: 16px !important;
}
.media_text_big_2 {
	font-size: 18px;
	color: #fff;
	line-height: 20px;
	font-weight: 700;
	margin-bottom: 0px;
}
.item_inner_img
{

  border-radius: 15px;
    overflow: hidden;
    height: 230px;
    display: flex;
align-items: center;
justify-content: center;
/* border:1px solid gray; */
}


.img_user_new_sm
{
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
}
.activity_media .img_tick.img_tick_lg img
{
  max-width: 25px !important;
}
.img_tick_div
{
  position: relative;
}
.img_tick
{
  position:absolute;
  right: 3px;
  top: -5px;
}
.fon_w_600
{
  font-weight: 600;
}

.btn.btn_purple_sm
{
  background-color: #ab092b;
  border: 1px solid #af0a2d;
  border-radius: 7px;
  padding: 2px 10px;
  transition: 0.2s all;
  color: #ffffff;
  margin: 0px !important;
  text-transform: inherit !important;
}
.btn.btn_purple_sm:hover,.btn.btn_purple_sm:focus
{
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #fff;

}
.white-box {
  background: white;
  box-shadow: 0 0 5px #c1bbbb;
  flex:1;
  border-radius: 10px;
}
.status{
  gap:20px
}
.white-box h3 {
  font-weight: 900;
  font-size: 42px;
  color: #474747;
  margin-bottom: 0;
}
.white-box p {
  font-size: 15px;
  color: #3f3e3e;
}
h1.banner-head {
  font-size: 57px;
  font-weight: bold;
  color: black;
}
h1.banner-head span {
  color: #ca113e;
}
p.banner-parg {
  font-size: 18px;
  color: #9c9a9a;
  margin-bottom: 22px;
}

.banner-r h3 {
  font-size: 37px;
  color: #3f3e3e;
  margin-bottom: 0;
  line-height: 1;
}
.banner-r p {
  color: #3f3e3e;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
  line-height: 1;
}
span.bnbcount {
  font-size: 48px !important;
  color: #3f3e3e !important;
  font-weight: 600;
}
.banner-r span {
  font-size: 18px;
  color: #3f3e3e;
  font-weight: 600;
}
.sellerlists span.img_tick {
  bottom: 0;
  top: auto;
}
.coll-imgs-r{
  background: url(./assets/images/banner-btm1.png);
}
.coll-imgs-l {
  background: url(./assets/images/banner-btm1.png);
}
.coll-imgs {

  height: 250px;
  border-radius: 20px;
}
.coll-cont {
  position: absolute;
  top: 16px;
  left: 51px;
  width: 70%;
  height: 270px;
  display: flex;
  align-items: flex-end;
}
.coll-img {
  border-radius: 20px;
  height: 270px;
  color: white;
  padding: 20px;
  margin-bottom: 30px;
}
.coll-imgs {
  /* background: url(/static/media/img_05.2ea3afc3.png); */
  height: 294px;
  border-radius: 20px;
}
.coll-img h3 {
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
}
.coll-img p {
  font-size: 13px;
  line-height: 1.5;
}
.sellerlists h5,.sellerlists p{
  color: #6c6d6d;
}
.sellerlists h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
.sellerlists p{
   font-size: 12px;
}
.sellerlists .white-box {
  padding: 10px;
}
.sellerlists img {
  width: 43px !important;
  height: 43px;
}
.sellerlists .img_tick_div {
  margin-right: 4px;
}
.sellerlists .img_tick img {
  height: 16px;
}
.sellerlists {
  line-height: 1;
  margin-bottom: 30px;
  filter: drop-shadow(9px 9px 5px #d5d4d4);
  border-radius: 8px;
}
.img-border{
  border: 1px solid gray;
  border-radius: 50%;
  padding: 5px;
  width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.section-head,.section-head-exp{
  font-size: 35px;
  color: #245278;
  font-weight: 600;
  flex-shrink: 0;
  /*  */
  line-height: 1;
}
.section-head {
  margin: 0 0 40px 0;
}
.navtabs .nav-link,.nav-tabs .nav-link {
  margin-right: 12px !important;
  margin-left: 0 !important;
}

section {
  padding: 50px 0;
}
.navtabs .items_tab {
  justify-content: left;
  border-bottom: 0;
}
.img-grid{
  display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
}
.hotcoll_lists_img
{
  max-height: 250px;
  min-height: 250px;
  border:1px solid gray;
  display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
border-radius: 10px;

}
.img-grid_img
{
  max-height: 80px;
  min-height: 80px;
  border:1px solid gray;
  display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
border-radius: 10px;
}
.hotcoll_lists img {
  border-radius: 10px;
}
.hotcoll_lists .img-grid {
  margin-top: 7px;
}
.hotcoll-cont .cont {
  justify-content: space-between;
  align-items: center;
}
.hotcoll-cont p,.hotcoll-cont span {
  color: #424040;
  font-size: 14px;
  text-align: left;
  margin-bottom: 3px;
  font-weight: 700;
}
.img-cont{
  justify-content: left;
    gap: 5px;
}
.hotcoll-cont {
  margin: 6px 0;
}
button.create_btn.bg-white {
  background: white;
  color: #000;
  border-color: #000;
}
section.body-top {
  padding-top: 100px;
}
.banner-r .banner-r-img
{
  width: 100%;
  max-width: 250px;
  height: 250px;
  margin-right: 15px;
  border-radius: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  /* border: 1px solid grey; */

}
.creators_details img {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  border-radius: 50%;
}
.creators_details {
  padding-left: 5px;
}
.timeleft {
  position: absolute;
  bottom: 0px;
  left: 10px;
  background: linear-gradient(to right,#a30726 0,#66d0128c);
  
  border-radius: 9px;
  padding: 0px 2px;
}
/* .liveauction .item_inner_img .img-fluid {
  height: 163px;
} */
.d-flex.creators_details img:nth-child(2) {
  position: absolute;
  right: 13px;
}
.d-flex.creators_details{
  position: relative;
}
.remaintime {
  position: relative;
}
.timeleft span {
  background: white;
  border-radius: 8px;
  padding: 2px 12px 3px 12px
}
button.btn.bg-theme {
  background-image: var(--theme-grad);
  border: 1px solid #03377f;
  color: #fff;
  border-radius: 7px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 45px;
}


.hotcoll_carouse .owl-nav.disabled button, .hotcoll_carouse .owl-nav button {
  background-image: var(--theme-grad);
  padding: 0px 12px;
  color: white;
  font-size: 27px;
  border-radius: 6px;
}

.hotcoll-cont .create_btn {
  cursor: default;
}
.liveauction .dropdown-menu.show, .dropdown-card .dropdown-menu.show {
  transform: translate3d(-83%, 33px, 0px) !important;
}
.drop-down {
  background: transparent;
  border: none;
}
.navtabs .img-fluid {
  width: 100%;
}

.navbar .dropdown-menu .dropdown-item a{
  margin-bottom: 5px;
}
.item_inner_img .img-fluid{
  width:100%;
}
.dropdown-card .item {
  margin-bottom: 30px;
}
header .create_btn {
    height: 45px !important;
}




.filteradd{
  width: 20px !important;
  margin-right: 10px;
}

.explore_enf{
  display: flex;
}



@media(max-width:575px){
  .bid_form label
  {
    font-size: 16px !important;
  }
  .bannergrid .bannerTitle h4{font-size:14px;text-transform:capitalize}
  .explore_enf{
   display: flex;
   flex-direction: column;
   margin: auto;

  }
  .coll-img h3 {
    font-size: 20px;
  }
  .inner_div_info{
    height:auto ! important;
  }
  .newsletter_grp input {
    font-size: 13px !important;
  }
  
}
@media(max-width:420px){div.mobile_nav {
  background: white !important;
  width: 280px !important;
}}

@media(max-width:991px){
  .section-head, .section-head-exp,.info_title {
    font-size: 20px;
  }
  .primary_label {
    font-size: 14px !important;}
  .subxcribe{padding:10px 0px}
  .ethactiosban img {
    height: 400px;
    object-fit: cover !important;
    width:100%;
  }
  .newsletter_grp_append{right:49px !important;}
  .foote, .foote1{    justify-content: start !important;
    align-items: start;}
  .signoutwallet{margin-left: 0 !important;}
  body .banner_head h1{font-size: 40px !important;}
  .foote{width: 100% !important;}
  .baner_image{width: 100% !important;}
  .search_inp{max-width: 100% !important;}
  .mobile_nav.dark_theme{
    background:#000 !important;
  }
  
  button.create_btn{
    padding: 10px 30px !important;
    margin: auto;
   
  }
  /* .mobile_nav.dark_theme .dark svg{
    left:12px;
  } */
  .dark_theme .rowone {
    background-color: #0b0b0b;
    border: 1px solid #29143e;
}
.dark_theme .topcreators .rowone{
background:#000;
}
  .dark_theme .MuiToolbar-regular button.hamburger_icon svg{
    fill:#fff !important;
  }
  .sec_he_sm
  {
    font-size: 18px !important;
  }
  .items_tab .nav-link .tab_head {
    font-size: 14px !important;
    font-weight: 400;
}
.media_text , .media_num{
  font-size: 12px;
}
  h1.banner-head {
      font-size: 38px;
  }
  .hotcoll-cont .create_btn {
    padding: 0px 6px;
  }
  .white-box h3 {
    font-size: 30px;
  }
  span.bnbcount {
    font-size: 25px !important;
  }
  .banner-r h3 {
    font-size: 30px;
  }
}















@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (max-width:1600px) and (min-width: 1280px) {
  .icon_txt
  {
    display: none !important;
  }
  .auctions{gap: 50px !important; }
  .banercontentDet{margin-left: 5px !important;}
  .banercontentDet p{font-size: 12px !important;}
  .hearting span{font-size: 16px !important;}
  .star-rating i{font-size: 12px !important;}
  .auctioncards h3{font-size: 14px !important;}
}
@media (min-width: 1200px) {
button.banner_btn
{
margin-bottom: 40px;
}

  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.473);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.473);
  color: #245278;
  font-size: 20px;
  font-weight: 600 !important;
}
.table tbody+tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.06);
}
.table .table {
  background-color: #fafafa;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #e3c3e9;
}
.table-hover .table-primary:hover {
  background-color: #dab0e2;
}
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #dab0e2;
}
.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}
.table-success,
.table-success>th,
.table-success>td {
  background-color: #cde9ce;
}
.table-hover .table-success:hover {
  background-color: #bbe1bd;
}
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #bbe1bd;
}
.table-info,
.table-info>th,
.table-info>td {
  background-color: #b8ecf3;
}
.table-hover .table-info:hover {
  background-color: #a2e6ef;
}
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #a2e6ef;
}
.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffe2b8;
}
.table-hover .table-warning:hover {
  background-color: #ffd89f;
}
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffd89f;
}
.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #fccac7;
}
.table-hover .table-danger:hover {
  background-color: #fbb3af;
}
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #fbb3af;
}
.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}
.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}
.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fafafa;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(0, 0, 0, 0.06);
}
.table-dark {
  color: #fafafa;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .primary_btn, button.primary_btn
  {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .baner_image{margin-top:40px;}
  .newsletter_grp_append {right: 49px !important;}
  .ethactiosban img {
    object-fit: cover !important;
    width: 100%;
  }
  .explore_enf{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .mode button p{
    margin-left:11px !important;
  }
  .new_des_footer{
    padding:0px;
    padding-left:10px;
  }
  .notowe{
    justify-content: flex-start;
  }
  .table-responsive-md>.table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive>.table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  border-color: #245278;
  outline: 0;
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.form-control::placeholder {
  color: #aca4a1 !important;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.4375rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
  height: calc(2.125rem + 2px);
}
.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: 0.5625rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
  height: calc(4.125rem + 2px);
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #4caf50;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #4caf50;
}
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #4caf50;
}
.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #6ec071;
}
.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #4caf50;
}
.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f44336;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #f44336;
}
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #f44336;
}
.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  background-color: #fbb4af;
}
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  background-color: #f77066;
}
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #f44336;
}
.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.46875rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25), none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
  box-shadow: none;
}
.btn-primary:hover {
  color: #fff;
  background-color: #802091;
  border-color: #771e86;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none, 0 0 0 0.2rem #245278;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #245278;
  border-color: #245278;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #245278;
  border-color: #245278;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem #245278;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: none;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: none;
}
.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: none;
}
.btn-info:hover {
  color: #fff;
  background-color: #009aae;
  border-color: #008fa1;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #008fa1;
  border-color: #008394;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: none;
}
.btn-warning:hover {
  color: #fff;
  background-color: #d98100;
  border-color: #cc7a00;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc7a00;
  border-color: #bf7200;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: none;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: none;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
  color: #9c27b0;
  background-color: transparent;
  background-image: none;
  border-color: #9c27b0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #9c27b0;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00bcd4;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9800;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #9c27b0;
  background-color: transparent;
}
.btn-link:hover {
  color: #641971;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #999;
}
.btn-lg,
.btn-group-lg>.btn {
  padding: 1.125rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm>.btn {
  padding: 0.40625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block+.btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.show {
  display: block;
}
tr.collapse.show {
  display: table-row;
}
tbody.collapse.show {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #9c27b0;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}
.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group>.btn:first-child {
  margin-left: 0;
}
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after {
  margin-left: 0;
}
.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}
.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group>.form-control,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file:focus {
  z-index: 3;
}
.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px;
}
.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group>.custom-file {
  display: flex;
  align-items: center;
}
.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
  border-bottom: 1px solid #000;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: #245278;
  box-shadow: none;
}
.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem #24527860;
}
.custom-control-input:active~.custom-control-label::before {
  color: #fff;
  background-color: #dfa0ea;
  box-shadow: none;
}
.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #245278;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  background-color: #245278;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: #245278;
}
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: #245278;
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #245278;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: #245278;
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d2d2d2;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #245278;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(209, 118, 225, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(2.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(4.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.4375rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus~.custom-file-control {
  border-color: #d176e1;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-file-input:focus~.custom-file-control::before {
  border-color: #d176e1;
}
.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.4375rem + 2px);
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  background-color: transparent;
  border: 0 solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.4375rem + 2px) - 0 * 2);
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  content: "Browse";
  background-color: transparent;
  border-left: 0 solid #d2d2d2;
  border-radius: 0 0 0 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fafafa;
  border-color: #dee2e6 #dee2e6 #fafafa;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #9c27b0;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content>.tab-pane {
  display: none;
}
.tab-content>.active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar>.container,
.navbar>.container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .newsletter_grp_append {
    right: 0px !important;
  }
  body .banner_head h1 {
    font-size: 30px !important;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand>.container,
.navbar-expand>.container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
.social_list {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
}
.social_list li {
  width: auto;
}
.social_links_header {
  background-image: linear-gradient(to right, #fe1301 0%, #ff7713 100%);
  border-radius: 40px !important;
  box-sizing: border-box;
  color: #7d8082 !important;
  display: block;
  height: 45px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 3px !important;
  text-decoration: none;
  width: 45px;
  z-index: 2;
  box-shadow: 0px 0px 1px 5px rgb(47 51 54 / 88%);
  font-size: 16px !important;
  /* margin-right: 25px; */
}
.social_links_header:hover {
	color: #fff;
}
.social_links_header i {
	align-items: center;
	background-image: linear-gradient(#2b2a2c, #4a5157);
	border-radius: 40px;
	display: flex;
	justify-content: center;
	height: 100%;
	transition: background .5s ease;
  width: 100%;
  text-shadow: 4px 6px 5px rgb(0 0 0 / 71%);
}
.social_links_header:hover i {
  background: transparent;
  color: #fff;
}
a.mobile_menu {
  color: #fff;
}
a.mobile_menu svg {
  vertical-align: middle;
  margin-right: 8px;
}
a.mobile_menu:hover {
  color: #ff7800;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}
.card>hr {
  margin-right: 0;
  margin-left: 0;
}
.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link+.card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header+.list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group>.card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group>.card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group>.card:first-child .card-img-top,
  .card-group>.card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group>.card:first-child .card-img-bottom,
  .card-group>.card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group>.card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group>.card:last-child .card-img-top,
  .card-group>.card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group>.card:last-child .card-img-bottom,
  .card-group>.card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group>.card:only-child {
    border-radius: 0.25rem;
  }
  .card-group>.card:only-child .card-img-top,
  .card-group>.card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group>.card:only-child .card-img-bottom,
  .card-group>.card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #9c27b0;
  background-color: transparent;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  color: #641971;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 500;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #51145c;
  background-color: #ebd4ef;
  border-color: #e3c3e9;
}
.alert-primary hr {
  border-top-color: #dab0e2;
}
.alert-primary .alert-link {
  color: #2c0b32;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.alert-success hr {
  border-top-color: #bbe1bd;
}
.alert-success .alert-link {
  color: #18381a;
}
.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3;
}
.alert-info hr {
  border-top-color: #a2e6ef;
}
.alert-info .alert-link {
  color: #00353b;
}
.alert-warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8;
}
.alert-warning hr {
  border-top-color: #ffd89f;
}
.alert-warning .alert-link {
  color: #523100;
}
.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #551713;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #9c27b0;
  transition: width 0.6s ease;
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: inherit;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: inherit;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #51145c;
  background-color: #e3c3e9;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #51145c;
  background-color: #dab0e2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #51145c;
  border-color: #51145c;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #285b2a;
  background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}
.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #00626e;
  background-color: #a2e6ef;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00626e;
  border-color: #00626e;
}
.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #854f00;
  background-color: #ffd89f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #854f00;
  border-color: #854f00;
}
.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #7f231c;
  background-color: #fbb3af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  --webkit-appearance: none;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  cursor: pointer;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #9c27b0;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #771e86;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #4caf50;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3d8b40;
}
.badge-info {
  color: #fff;
  background-color: #00bcd4;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #008fa1;
}
.badge-warning {
  color: #212529;
  background-color: #ff9800;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #cc7a00;
}
.badge-danger {
  color: #fff;
  background-color: #f44336;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ea1c0d;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer> :not(:first-child) {
  margin-left: .25rem;
}
.modal-footer> :not(:last-child) {
  margin-right: .25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: rgba(97, 97, 97, 0.9);
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: rgba(97, 97, 97, 0.9);
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}
.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}
.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
/* .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
} */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #9c27b0 !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #771e86 !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #4caf50 !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important;
}
.bg-info {
  background-color: #00bcd4 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important;
}
.bg-warning {
  background-color: #ff9800 !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc7a00 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #9c27b0 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #4caf50 !important;
}
.border-info {
  border-color: #00bcd4 !important;
}
.border-warning {
  border-color: #ff9800 !important;
}
.border-danger {
  border-color: #f44336 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .headerflexs{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #9c27b0 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #771e86 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}
.text-success {
  color: #4caf50 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #3d8b40 !important;
}
.text-info {
  color: #00bcd4 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #008fa1 !important;
}
.text-warning {
  color: #ff9800 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #cc7a00 !important;
}
.text-danger {
  color: #f44336 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #ea1c0d !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}
.text-muted,
.bmd-help {
  color: #555 !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
a:focus {
  outline: none;
}
button:focus {
  outline: none;
}
.bmd-layout-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bmd-layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.bmd-layout-header {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000px;
  transform: translateZ(0);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-content {
  position: relative;
  z-index: 1;
  display: inline-block;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-spacer {
  flex-grow: 1;
}
.bmd-layout-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@supports (pointer-events: auto) {
  .bmd-layout-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }
}
.btn {
  position: relative;
  padding: 12px 30px;
  margin: 0.3125rem 1px;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  /* text-transform: uppercase; */
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0.2rem;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn:active:focus:focus,
.btn:active:focus.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.btn-primary {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
}
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:hover {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
}
.btn.btn-primary:active,
.btn.btn-primary.active,
.open>.btn.btn-primary.dropdown-toggle,
.show>.btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:active:hover,
.btn.btn-primary:active:focus,
.btn.btn-primary:active.focus,
.btn.btn-primary.active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active.focus,
.open>.btn.btn-primary.dropdown-toggle:hover,
.open>.btn.btn-primary.dropdown-toggle:focus,
.open>.btn.btn-primary.dropdown-toggle.focus,
.show>.btn.btn-primary.dropdown-toggle:hover,
.show>.btn.btn-primary.dropdown-toggle:focus,
.show>.btn.btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #9124a3;
  border-color: #3f1048;
}
.open>.btn.btn-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #9c27b0;
}
.open>.btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #9124a3;
}
.btn.btn-primary.disabled:focus,
.btn.btn-primary.disabled.focus,
.btn.btn-primary:disabled:focus,
.btn.btn-primary:disabled.focus {
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.btn.btn-primary.disabled:hover,
.btn.btn-primary:disabled:hover {
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.btn.btn-primary.btn-link {
  background-color: transparent;
  color: #9c27b0;
  box-shadow: none;
}
.btn.btn-primary.btn-link:hover,
.btn.btn-primary.btn-link:focus,
.btn.btn-primary.btn-link:active {
  background-color: transparent;
  color: #9c27b0;
}
.btn.btn-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
  border-color: #ccc;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary:focus,
.btn.btn-secondary.focus,
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary:active,
.btn.btn-secondary.active,
.open>.btn.btn-secondary.dropdown-toggle,
.show>.btn.btn-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:active:hover,
.btn.btn-secondary:active:focus,
.btn.btn-secondary:active.focus,
.btn.btn-secondary.active:hover,
.btn.btn-secondary.active:focus,
.btn.btn-secondary.active.focus,
.open>.btn.btn-secondary.dropdown-toggle:hover,
.open>.btn.btn-secondary.dropdown-toggle:focus,
.open>.btn.btn-secondary.dropdown-toggle.focus,
.show>.btn.btn-secondary.dropdown-toggle:hover,
.show>.btn.btn-secondary.dropdown-toggle:focus,
.show>.btn.btn-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #8c8c8c;
}
.open>.btn.btn-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #fafafa;
}
.open>.btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f2f2f2;
}
.btn.btn-secondary.disabled:focus,
.btn.btn-secondary.disabled.focus,
.btn.btn-secondary:disabled:focus,
.btn.btn-secondary:disabled.focus {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary.disabled:hover,
.btn.btn-secondary:disabled:hover {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary:focus,
.btn.btn-secondary:active,
.btn.btn-secondary:hover {
  box-shadow: 0 14px 26px -12px rgba(250, 250, 250, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(250, 250, 250, 0.2);
}
.btn.btn-secondary.btn-link {
  background-color: transparent;
  color: #fafafa;
  box-shadow: none;
}
.btn.btn-secondary.btn-link:hover,
.btn.btn-secondary.btn-link:focus,
.btn.btn-secondary.btn-link:active {
  background-color: transparent;
  color: #fafafa;
}
.btn.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info:focus,
.btn.btn-info.focus,
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info:active,
.btn.btn-info.active,
.open>.btn.btn-info.dropdown-toggle,
.show>.btn.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:active:hover,
.btn.btn-info:active:focus,
.btn.btn-info:active.focus,
.btn.btn-info.active:hover,
.btn.btn-info.active:focus,
.btn.btn-info.active.focus,
.open>.btn.btn-info.dropdown-toggle:hover,
.open>.btn.btn-info.dropdown-toggle:focus,
.open>.btn.btn-info.dropdown-toggle.focus,
.show>.btn.btn-info.dropdown-toggle:hover,
.show>.btn.btn-info.dropdown-toggle:focus,
.show>.btn.btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #00aec5;
  border-color: #004b55;
}
.open>.btn.btn-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #00bcd4;
}
.open>.btn.btn-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #00aec5;
}
.btn.btn-info.disabled:focus,
.btn.btn-info.disabled.focus,
.btn.btn-info:disabled:focus,
.btn.btn-info:disabled.focus {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn.btn-info.disabled:hover,
.btn.btn-info:disabled:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-info.btn-link {
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none;
}
.btn.btn-info.btn-link:hover,
.btn.btn-info.btn-link:focus,
.btn.btn-info.btn-link:active {
  background-color: transparent;
  color: #00bcd4;
}
.btn.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success:focus,
.btn.btn-success.focus,
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success:active,
.btn.btn-success.active,
.open>.btn.btn-success.dropdown-toggle,
.show>.btn.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:active:hover,
.btn.btn-success:active:focus,
.btn.btn-success:active.focus,
.btn.btn-success.active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active.focus,
.open>.btn.btn-success.dropdown-toggle:hover,
.open>.btn.btn-success.dropdown-toggle:focus,
.open>.btn.btn-success.dropdown-toggle.focus,
.show>.btn.btn-success.dropdown-toggle:hover,
.show>.btn.btn-success.dropdown-toggle:focus,
.show>.btn.btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #47a44b;
  border-color: #255627;
}
.open>.btn.btn-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #4caf50;
}
.open>.btn.btn-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #47a44b;
}
.btn.btn-success.disabled:focus,
.btn.btn-success.disabled.focus,
.btn.btn-success:disabled:focus,
.btn.btn-success:disabled.focus {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success.disabled:hover,
.btn.btn-success:disabled:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success:hover {
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.btn.btn-success.btn-link {
  background-color: transparent;
  color: #4caf50;
  box-shadow: none;
}
.btn.btn-success.btn-link:hover,
.btn.btn-success.btn-link:focus,
.btn.btn-success.btn-link:active {
  background-color: transparent;
  color: #4caf50;
}
.btn.btn-warning {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning:focus,
.btn.btn-warning.focus,
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning:active,
.btn.btn-warning.active,
.open>.btn.btn-warning.dropdown-toggle,
.show>.btn.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:active:hover,
.btn.btn-warning:active:focus,
.btn.btn-warning:active.focus,
.btn.btn-warning.active:hover,
.btn.btn-warning.active:focus,
.btn.btn-warning.active.focus,
.open>.btn.btn-warning.dropdown-toggle:hover,
.open>.btn.btn-warning.dropdown-toggle:focus,
.open>.btn.btn-warning.dropdown-toggle.focus,
.show>.btn.btn-warning.dropdown-toggle:hover,
.show>.btn.btn-warning.dropdown-toggle:focus,
.show>.btn.btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #f08f00;
  border-color: #804c00;
}
.open>.btn.btn-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #ff9800;
}
.open>.btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f08f00;
}
.btn.btn-warning.disabled:focus,
.btn.btn-warning.disabled.focus,
.btn.btn-warning:disabled:focus,
.btn.btn-warning:disabled.focus {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning.disabled:hover,
.btn.btn-warning:disabled:hover {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}
.btn.btn-warning.btn-link {
  background-color: transparent;
  color: #ff9800;
  box-shadow: none;
}
.btn.btn-warning.btn-link:hover,
.btn.btn-warning.btn-link:focus,
.btn.btn-warning.btn-link:active {
  background-color: transparent;
  color: #ff9800;
}
.btn.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger:focus,
.btn.btn-danger.focus,
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger:active,
.btn.btn-danger.active,
.open>.btn.btn-danger.dropdown-toggle,
.show>.btn.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:active:hover,
.btn.btn-danger:active:focus,
.btn.btn-danger:active.focus,
.btn.btn-danger.active:hover,
.btn.btn-danger.active:focus,
.btn.btn-danger.active.focus,
.open>.btn.btn-danger.dropdown-toggle:hover,
.open>.btn.btn-danger.dropdown-toggle:focus,
.open>.btn.btn-danger.dropdown-toggle.focus,
.show>.btn.btn-danger.dropdown-toggle:hover,
.show>.btn.btn-danger.dropdown-toggle:focus,
.show>.btn.btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #f33527;
  border-color: #a21309;
}
.open>.btn.btn-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #f44336;
}
.open>.btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f33527;
}
.btn.btn-danger.disabled:focus,
.btn.btn-danger.disabled.focus,
.btn.btn-danger:disabled:focus,
.btn.btn-danger:disabled.focus {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger.disabled:hover,
.btn.btn-danger:disabled:hover {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger:hover {
  box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}
.btn.btn-danger.btn-link {
  background-color: transparent;
  color: #f44336;
  box-shadow: none;
}
.btn.btn-danger.btn-link:hover,
.btn.btn-danger.btn-link:focus,
.btn.btn-danger.btn-link:active {
  background-color: transparent;
  color: #f44336;
}
.btn.btn-rose {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose:focus,
.btn.btn-rose.focus,
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose:active,
.btn.btn-rose.active,
.open>.btn.btn-rose.dropdown-toggle,
.show>.btn.btn-rose.dropdown-toggle {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:active:hover,
.btn.btn-rose:active:focus,
.btn.btn-rose:active.focus,
.btn.btn-rose.active:hover,
.btn.btn-rose.active:focus,
.btn.btn-rose.active.focus,
.open>.btn.btn-rose.dropdown-toggle:hover,
.open>.btn.btn-rose.dropdown-toggle:focus,
.open>.btn.btn-rose.dropdown-toggle.focus,
.show>.btn.btn-rose.dropdown-toggle:hover,
.show>.btn.btn-rose.dropdown-toggle:focus,
.show>.btn.btn-rose.dropdown-toggle.focus {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #7b0c32;
}
.open>.btn.btn-rose.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #e91e63;
}
.open>.btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #ea2c6d;
}
.btn.btn-rose.disabled:focus,
.btn.btn-rose.disabled.focus,
.btn.btn-rose:disabled:focus,
.btn.btn-rose:disabled.focus {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose.disabled:hover,
.btn.btn-rose:disabled:hover {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose:focus,
.btn.btn-rose:active,
.btn.btn-rose:hover {
  box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
}
.btn.btn-rose.btn-link {
  background-color: transparent;
  color: #e91e63;
  box-shadow: none;
}
.btn.btn-rose.btn-link:hover,
.btn.btn-rose.btn-link:focus,
.btn.btn-rose.btn-link:active {
  background-color: transparent;
  color: #e91e63;
}
.btn,
.btn.btn-default {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn:hover,
.btn.btn-default:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn:focus,
.btn.focus,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default.focus,
.btn.btn-default:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn:active,
.btn.active,
.open>.btn.dropdown-toggle,
.show>.btn.dropdown-toggle,
.btn.btn-default:active,
.btn.btn-default.active,
.open>.btn.btn-default.dropdown-toggle,
.show>.btn.btn-default.dropdown-toggle {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn:active:hover,
.btn:active:focus,
.btn:active.focus,
.btn.active:hover,
.btn.active:focus,
.btn.active.focus,
.open>.btn.dropdown-toggle:hover,
.open>.btn.dropdown-toggle:focus,
.open>.btn.dropdown-toggle.focus,
.show>.btn.dropdown-toggle:hover,
.show>.btn.dropdown-toggle:focus,
.show>.btn.dropdown-toggle.focus,
.btn.btn-default:active:hover,
.btn.btn-default:active:focus,
.btn.btn-default:active.focus,
.btn.btn-default.active:hover,
.btn.btn-default.active:focus,
.btn.btn-default.active.focus,
.open>.btn.btn-default.dropdown-toggle:hover,
.open>.btn.btn-default.dropdown-toggle:focus,
.open>.btn.btn-default.dropdown-toggle.focus,
.show>.btn.btn-default.dropdown-toggle:hover,
.show>.btn.btn-default.dropdown-toggle:focus,
.show>.btn.btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #919191;
  border-color: #595959;
}
.open>.btn.dropdown-toggle.bmd-btn-icon,
.open>.btn.btn-default.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #999999;
}
.open>.btn.dropdown-toggle.bmd-btn-icon:hover,
.open>.btn.btn-default.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #919191;
}
.btn.disabled:focus,
.btn.disabled.focus,
.btn:disabled:focus,
.btn:disabled.focus,
.btn.btn-default.disabled:focus,
.btn.btn-default.disabled.focus,
.btn.btn-default:disabled:focus,
.btn.btn-default:disabled.focus {
  background-color: #999999;
  border-color: #999999;
}
.btn.disabled:hover,
.btn:disabled:hover,
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover {
  background-color: #999999;
  border-color: #999999;
}
.btn:focus,
.btn:active,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.btn-link,
.btn.btn-default.btn-link {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active,
.btn.btn-default.btn-link:hover,
.btn.btn-default.btn-link:focus,
.btn.btn-default.btn-link:active {
  background-color: transparent;
  color: #999999;
}
.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover {
  background-color: #ffffff;
  color: #999999;
}
.btn.btn-white.btn-link {
  color: #ffffff;
  background: transparent;
  box-shadow: none;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active {
  text-decoration: none !important;
}
.btn.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.btn.btn-disabled:hover {
  box-shadow: none;
}
.btn.btn-raised.btn-link,
.btn-group-raised .btn.btn-link {
  box-shadow: none;
}
.btn.btn-raised.btn-link.active,
.btn-group-raised .btn.btn-link.active {
  box-shadow: none;
}
.btn.btn-raised.btn-link:hover,
.btn.btn-raised.btn-link:focus,
.btn.btn-raised.btn-link:active,
.btn-group-raised .btn.btn-link:hover,
.btn-group-raised .btn.btn-link:focus,
.btn-group-raised .btn.btn-link:active {
  box-shadow: none;
}
fieldset[disabled][disabled] .btn.btn-raised,
.btn.btn-raised.disabled,
.btn.btn-raised:disabled,
.btn.btn-raised[disabled],
fieldset[disabled][disabled] .btn-group-raised .btn,
.btn-group-raised .btn.disabled,
.btn-group-raised .btn:disabled,
.btn-group-raised .btn[disabled] {
  box-shadow: none;
}
.btn.btn-outline,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-outline-info,
.btn.btn-outline-success,
.btn.btn-outline-warning,
.btn.btn-outline-danger {
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
.btn.btn-outline {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:focus,
.btn.btn-outline.focus,
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:active,
.btn.btn-outline.active,
.open>.btn.btn-outline.dropdown-toggle,
.show>.btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline:active:hover,
.btn.btn-outline:active:focus,
.btn.btn-outline:active.focus,
.btn.btn-outline.active:hover,
.btn.btn-outline.active:focus,
.btn.btn-outline.active.focus,
.open>.btn.btn-outline.dropdown-toggle:hover,
.open>.btn.btn-outline.dropdown-toggle:focus,
.open>.btn.btn-outline.dropdown-toggle.focus,
.show>.btn.btn-outline.dropdown-toggle:hover,
.show>.btn.btn-outline.dropdown-toggle:focus,
.show>.btn.btn-outline.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open>.btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline.disabled:focus,
.btn.btn-outline.disabled.focus,
.btn.btn-outline:disabled:focus,
.btn.btn-outline:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.disabled:hover,
.btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline:focus,
.bg-inverse .btn.btn-outline.focus,
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline:active,
.bg-inverse .btn.btn-outline.active,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline:active:hover,
.bg-inverse .btn.btn-outline:active:focus,
.bg-inverse .btn.btn-outline:active.focus,
.bg-inverse .btn.btn-outline.active:hover,
.bg-inverse .btn.btn-outline.active:focus,
.bg-inverse .btn.btn-outline.active.focus,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.disabled:focus,
.bg-inverse .btn.btn-outline.disabled.focus,
.bg-inverse .btn.btn-outline:disabled:focus,
.bg-inverse .btn.btn-outline:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline.disabled:hover,
.bg-inverse .btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.btn-link {
  background-color: transparent;
}
.btn.btn-outline-primary {
  color: #9c27b0;
  background-color: transparent;
  border-color: #9c27b0;
}
.btn.btn-outline-primary:hover {
  color: #9c27b0;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #9c27b0;
}
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:hover {
  color: #9c27b0;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #9c27b0;
}
.btn.btn-outline-primary:active,
.btn.btn-outline-primary.active,
.open>.btn.btn-outline-primary.dropdown-toggle,
.show>.btn.btn-outline-primary.dropdown-toggle {
  color: #9c27b0;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #9c27b0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-primary:active:hover,
.btn.btn-outline-primary:active:focus,
.btn.btn-outline-primary:active.focus,
.btn.btn-outline-primary.active:hover,
.btn.btn-outline-primary.active:focus,
.btn.btn-outline-primary.active.focus,
.open>.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.btn.btn-outline-primary.dropdown-toggle.focus {
  color: #9c27b0;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #9c27b0;
}
.open>.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-primary.disabled:focus,
.btn.btn-outline-primary.disabled.focus,
.btn.btn-outline-primary:disabled:focus,
.btn.btn-outline-primary:disabled.focus {
  background-color: transparent;
  border-color: #9c27b0;
}
.btn.btn-outline-primary.disabled:hover,
.btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #9c27b0;
}
.bg-inverse .btn.btn-outline-primary {
  color: #9c27b0;
  background-color: transparent;
  border-color: #9c27b0;
}
.bg-inverse .btn.btn-outline-primary:hover {
  color: #9c27b0;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary:focus,
.bg-inverse .btn.btn-outline-primary.focus,
.bg-inverse .btn.btn-outline-primary:hover {
  color: #9c27b0;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary:active,
.bg-inverse .btn.btn-outline-primary.active,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle {
  color: #9c27b0;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-primary:active:hover,
.bg-inverse .btn.btn-outline-primary:active:focus,
.bg-inverse .btn.btn-outline-primary:active.focus,
.bg-inverse .btn.btn-outline-primary.active:hover,
.bg-inverse .btn.btn-outline-primary.active:focus,
.bg-inverse .btn.btn-outline-primary.active.focus,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus {
  color: #9c27b0;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary.disabled:focus,
.bg-inverse .btn.btn-outline-primary.disabled.focus,
.bg-inverse .btn.btn-outline-primary:disabled:focus,
.bg-inverse .btn.btn-outline-primary:disabled.focus {
  background-color: transparent;
  border-color: #9c27b0;
}
.bg-inverse .btn.btn-outline-primary.disabled:hover,
.bg-inverse .btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #9c27b0;
}
.btn.btn-outline-primary.btn-link {
  background-color: transparent;
}
.btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary.active,
.open>.btn.btn-outline-secondary.dropdown-toggle,
.show>.btn.btn-outline-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-secondary:active:hover,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active.focus,
.open>.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.btn.btn-outline-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open>.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-secondary.disabled:focus,
.btn.btn-outline-secondary.disabled.focus,
.btn.btn-outline-secondary:disabled:focus,
.btn.btn-outline-secondary:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.disabled:hover,
.btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary:focus,
.bg-inverse .btn.btn-outline-secondary.focus,
.bg-inverse .btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary:active,
.bg-inverse .btn.btn-outline-secondary.active,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-secondary:active:hover,
.bg-inverse .btn.btn-outline-secondary:active:focus,
.bg-inverse .btn.btn-outline-secondary:active.focus,
.bg-inverse .btn.btn-outline-secondary.active:hover,
.bg-inverse .btn.btn-outline-secondary.active:focus,
.bg-inverse .btn.btn-outline-secondary.active.focus,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.disabled:focus,
.bg-inverse .btn.btn-outline-secondary.disabled.focus,
.bg-inverse .btn.btn-outline-secondary:disabled:focus,
.bg-inverse .btn.btn-outline-secondary:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.disabled:hover,
.bg-inverse .btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.btn-link {
  background-color: transparent;
}
.btn.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
}
.btn.btn-outline-info:focus,
.btn.btn-outline-info.focus,
.btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
}
.btn.btn-outline-info:active,
.btn.btn-outline-info.active,
.open>.btn.btn-outline-info.dropdown-toggle,
.show>.btn.btn-outline-info.dropdown-toggle {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-info:active:hover,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active.focus,
.open>.btn.btn-outline-info.dropdown-toggle:hover,
.open>.btn.btn-outline-info.dropdown-toggle:focus,
.open>.btn.btn-outline-info.dropdown-toggle.focus,
.show>.btn.btn-outline-info.dropdown-toggle:hover,
.show>.btn.btn-outline-info.dropdown-toggle:focus,
.show>.btn.btn-outline-info.dropdown-toggle.focus {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #00bcd4;
}
.open>.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-info.disabled:focus,
.btn.btn-outline-info.disabled.focus,
.btn.btn-outline-info:disabled:focus,
.btn.btn-outline-info:disabled.focus {
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info.disabled:hover,
.btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info:focus,
.bg-inverse .btn.btn-outline-info.focus,
.bg-inverse .btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info:active,
.bg-inverse .btn.btn-outline-info.active,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-info:active:hover,
.bg-inverse .btn.btn-outline-info:active:focus,
.bg-inverse .btn.btn-outline-info:active.focus,
.bg-inverse .btn.btn-outline-info.active:hover,
.bg-inverse .btn.btn-outline-info.active:focus,
.bg-inverse .btn.btn-outline-info.active.focus,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle.focus {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.disabled:focus,
.bg-inverse .btn.btn-outline-info.disabled.focus,
.bg-inverse .btn.btn-outline-info:disabled:focus,
.bg-inverse .btn.btn-outline-info:disabled.focus {
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info.disabled:hover,
.bg-inverse .btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info.btn-link {
  background-color: transparent;
}
.btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success:focus,
.btn.btn-outline-success.focus,
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success:active,
.btn.btn-outline-success.active,
.open>.btn.btn-outline-success.dropdown-toggle,
.show>.btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-success:active:hover,
.btn.btn-outline-success:active:focus,
.btn.btn-outline-success:active.focus,
.btn.btn-outline-success.active:hover,
.btn.btn-outline-success.active:focus,
.btn.btn-outline-success.active.focus,
.open>.btn.btn-outline-success.dropdown-toggle:hover,
.open>.btn.btn-outline-success.dropdown-toggle:focus,
.open>.btn.btn-outline-success.dropdown-toggle.focus,
.show>.btn.btn-outline-success.dropdown-toggle:hover,
.show>.btn.btn-outline-success.dropdown-toggle:focus,
.show>.btn.btn-outline-success.dropdown-toggle.focus {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #4caf50;
}
.open>.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-success.disabled:focus,
.btn.btn-outline-success.disabled.focus,
.btn.btn-outline-success:disabled:focus,
.btn.btn-outline-success:disabled.focus {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.disabled:hover,
.btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success:focus,
.bg-inverse .btn.btn-outline-success.focus,
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success:active,
.bg-inverse .btn.btn-outline-success.active,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-success:active:hover,
.bg-inverse .btn.btn-outline-success:active:focus,
.bg-inverse .btn.btn-outline-success:active.focus,
.bg-inverse .btn.btn-outline-success.active:hover,
.bg-inverse .btn.btn-outline-success.active:focus,
.bg-inverse .btn.btn-outline-success.active.focus,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle.focus {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.disabled:focus,
.bg-inverse .btn.btn-outline-success.disabled.focus,
.bg-inverse .btn.btn-outline-success:disabled:focus,
.bg-inverse .btn.btn-outline-success:disabled.focus {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success.disabled:hover,
.bg-inverse .btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.btn-link {
  background-color: transparent;
}
.btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning:active,
.btn.btn-outline-warning.active,
.open>.btn.btn-outline-warning.dropdown-toggle,
.show>.btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-warning:active:hover,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active.focus,
.open>.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.btn.btn-outline-warning.dropdown-toggle.focus {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #ff9800;
}
.open>.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-warning.disabled:focus,
.btn.btn-outline-warning.disabled.focus,
.btn.btn-outline-warning:disabled:focus,
.btn.btn-outline-warning:disabled.focus {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.disabled:hover,
.btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning:focus,
.bg-inverse .btn.btn-outline-warning.focus,
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning:active,
.bg-inverse .btn.btn-outline-warning.active,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-warning:active:hover,
.bg-inverse .btn.btn-outline-warning:active:focus,
.bg-inverse .btn.btn-outline-warning:active.focus,
.bg-inverse .btn.btn-outline-warning.active:hover,
.bg-inverse .btn.btn-outline-warning.active:focus,
.bg-inverse .btn.btn-outline-warning.active.focus,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.disabled:focus,
.bg-inverse .btn.btn-outline-warning.disabled.focus,
.bg-inverse .btn.btn-outline-warning:disabled:focus,
.bg-inverse .btn.btn-outline-warning:disabled.focus {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning.disabled:hover,
.bg-inverse .btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.btn-link {
  background-color: transparent;
}
.btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.btn.btn-outline-danger:active,
.btn.btn-outline-danger.active,
.open>.btn.btn-outline-danger.dropdown-toggle,
.show>.btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-danger:active:hover,
.btn.btn-outline-danger:active:focus,
.btn.btn-outline-danger:active.focus,
.btn.btn-outline-danger.active:hover,
.btn.btn-outline-danger.active:focus,
.btn.btn-outline-danger.active.focus,
.open>.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.btn.btn-outline-danger.dropdown-toggle.focus {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #f44336;
}
.open>.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-danger.disabled:focus,
.btn.btn-outline-danger.disabled.focus,
.btn.btn-outline-danger:disabled:focus,
.btn.btn-outline-danger:disabled.focus {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.disabled:hover,
.btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger:focus,
.bg-inverse .btn.btn-outline-danger.focus,
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger:active,
.bg-inverse .btn.btn-outline-danger.active,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-danger:active:hover,
.bg-inverse .btn.btn-outline-danger:active:focus,
.bg-inverse .btn.btn-outline-danger:active.focus,
.bg-inverse .btn.btn-outline-danger.active:hover,
.bg-inverse .btn.btn-outline-danger.active:focus,
.bg-inverse .btn.btn-outline-danger.active.focus,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.disabled:focus,
.bg-inverse .btn.btn-outline-danger.disabled.focus,
.bg-inverse .btn.btn-outline-danger:disabled:focus,
.bg-inverse .btn.btn-outline-danger:disabled.focus {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger.disabled:hover,
.bg-inverse .btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.btn-link {
  background-color: transparent;
}
.btn.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg .btn {
  padding: 1.125rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.333333;
  border-radius: 0.2rem;
}
.btn.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm .btn {
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn.btn-round {
  border-radius: 30px;
}
.btn.btn-fab,
.btn.btn-just-icon {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}
.btn.btn-fab.btn-round,
.btn.btn-just-icon.btn-round {
  border-radius: 50%;
}
.btn-group-sm .btn.btn-fab,
.btn.btn-fab.btn-sm,
.btn-group-sm>.btn.btn-fab,
.btn.btn-fab.btn-fab-mini,
.btn-group-sm .btn.btn-just-icon,
.btn.btn-just-icon.btn-sm,
.btn-group-sm>.btn.btn-just-icon,
.btn.btn-just-icon.btn-fab-mini {
  height: 30px;
  min-width: 30px;
  width: 30px;
}
.btn-group-sm .btn.btn-fab .material-icons,
.btn-group-sm .btn.btn-fab .fa,
.btn.btn-fab.btn-sm .material-icons,
.btn-group-sm>.btn.btn-fab .material-icons,
.btn.btn-fab.btn-sm .fa,
.btn-group-sm>.btn.btn-fab .fa,
.btn.btn-fab.btn-fab-mini .material-icons,
.btn.btn-fab.btn-fab-mini .fa,
.btn-group-sm .btn.btn-just-icon .material-icons,
.btn-group-sm .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-sm .material-icons,
.btn-group-sm>.btn.btn-just-icon .material-icons,
.btn.btn-just-icon.btn-sm .fa,
.btn-group-sm>.btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-fab-mini .material-icons,
.btn.btn-just-icon.btn-fab-mini .fa {
  font-size: 17px;
  line-height: 29px;
}
.btn-group-lg .btn.btn-fab,
.btn.btn-fab.btn-lg,
.btn-group-lg>.btn.btn-fab,
.btn-group-lg .btn.btn-just-icon,
.btn.btn-just-icon.btn-lg,
.btn-group-lg>.btn.btn-just-icon {
  height: 57px;
  min-width: 57px;
  width: 57px;
  line-height: 56px;
}
.btn-group-lg .btn.btn-fab .material-icons,
.btn-group-lg .btn.btn-fab .fa,
.btn.btn-fab.btn-lg .material-icons,
.btn-group-lg>.btn.btn-fab .material-icons,
.btn.btn-fab.btn-lg .fa,
.btn-group-lg>.btn.btn-fab .fa,
.btn-group-lg .btn.btn-just-icon .material-icons,
.btn-group-lg .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-lg .material-icons,
.btn-group-lg>.btn.btn-just-icon .material-icons,
.btn.btn-just-icon.btn-lg .fa,
.btn-group-lg>.btn.btn-just-icon .fa {
  font-size: 32px;
  line-height: 56px;
}
.btn.btn-fab .material-icons,
.btn.btn-fab .fa,
.btn.btn-just-icon .material-icons,
.btn.btn-just-icon .fa {
  margin-top: 0;
  position: absolute;
  width: 100%;
  transform: none;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 41px;
  font-size: 20px;
}
.btn-just-icon.btn-lg,
.btn-group-lg>.btn-just-icon.btn {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
}
.input-group-btn>.btn {
  border: 0;
}
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
  position: relative;
  display: inline-block;
  top: 0;
  margin-top: -1em;
  margin-bottom: -1em;
  font-size: 1.1rem;
  vertical-align: middle;
}
.bg-inverse fieldset[disabled][disabled] .btn,
.bg-inverse .btn.disabled,
.bg-inverse .btn:disabled,
.bg-inverse .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .input-group-btn .btn,
.bg-inverse .input-group-btn .btn.disabled,
.bg-inverse .input-group-btn .btn:disabled,
.bg-inverse .input-group-btn .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .btn-group,
.bg-inverse .btn-group.disabled,
.bg-inverse .btn-group:disabled,
.bg-inverse .btn-group[disabled],
.bg-inverse fieldset[disabled][disabled] .btn-group-vertical,
.bg-inverse .btn-group-vertical.disabled,
.bg-inverse .btn-group-vertical:disabled,
.bg-inverse .btn-group-vertical[disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}
.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
  border-radius: 0 0 0.25rem 0.25rem;
}
.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-group .btn+.btn,
.btn-group .btn,
.btn-group .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn,
.btn-group-vertical .btn:active,
.btn-group-vertical .btn-group {
  margin: 0;
}
.btn-group>.btn-group,
.btn-group-vertical>.btn-group {
  margin: 0;
}
legend {
  border-bottom: 0;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: 0;
}
a {
  color: #9c27b0;
}
a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}
a.text-info:hover,
a.text-info:focus {
  color: #00a5bb;
}
a .material-icons {
  vertical-align: middle;
}
.main {
  position: relative;
  z-index: 3;
  /* overflow: hidden; */
}
.form-check,
label {
  font-size: 14px;
  line-height: 1.42857;
  color: #AAAAAA;
  font-weight: 400;
}
.main-raised {
  margin: -60px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
/*           Animations              */
.animation-transition-general,
.carousel .carousel-indicators li {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.animation-transition-slow {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td>div,
.bootstrap-datetimepicker-widget table th>div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td>div,
.bootstrap-datetimepicker-widget table th>div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span,
.navbar,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.card-collapse .card-header a i {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.signup-page .card-signup form .form-check {
  padding-left: 20px;
}
.signup-page .card-signup form .form-check .form-check-label {
  padding-left: 35px;
}
.section-signup .card-signup .card-header {
  width: auto;
}
.section-signup .card-signup .card-body .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}
.offline-doc .page-header,
.offline-free-doc .page-header {
  height: 100vh !important;
}
.offline-doc .page-header:after,
.offline-free-doc .page-header:after {
  background: rgba(0, 0, 0, 0.5) !important;
}
.offline-doc .footer,
.offline-free-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #ffffff;
  z-index: 1;
}
.offline-doc .footer .copyright a,
.offline-free-doc .footer .copyright a {
  color: #ffffff;
}
.offline-doc .navbar .navbar-brand,
.offline-doc .navbar .navbar-collapse .nav-link,
.offline-free-doc .navbar .navbar-brand,
.offline-free-doc .navbar .navbar-collapse .nav-link {
  color: #ffffff;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tim-row {
  margin-bottom: 20px;
}
.tim-white-buttons {
  background-color: #777777;
}
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}
#map {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 70px);
  margin-top: 70px;
}
.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note {
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.tim-row {
  padding-top: 50px;
}
.tim-row h3 {
  margin-top: 0;
}
#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
  margin-bottom: 0;
}
.switch {
  margin-right: 20px;
}
#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}
.space {
  height: 130px;
  display: block;
}
.space-110 {
  height: 110px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-70 {
  height: 70px;
  display: block;
}
.navigation-example .img-src {
  background-attachment: scroll;
}
.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
}
#notifications {
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
}
#notifications .alert-danger {
  margin-bottom: 0px;
}
.tim-note {
  text-transform: capitalize;
}
#buttons .btn {
  margin: 0 0px 15px;
}
.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}
.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.parallax .parallax-image img {
  width: 100%;
}
@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}
.separator {
  content: "Separator";
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 20px;
}
.separator-line {
  background-color: #EEE;
  height: 1px;
  width: 100%;
  display: block;
}
.separator.separator-gray {
  background-color: #EEEEEE;
}
.social-buttons-demo .btn {
  margin: 10px 5px 7px 1px;
}
.img-container {
  width: 100%;
  overflow: hidden;
}
.img-container img {
  width: 100%;
}
.section-black {
  background-color: #333;
}
.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}
.sharing-area {
  margin-top: 80px;
}
.sharing-area .btn {
  margin: 15px 4px 0;
  color: #ffffff;
}
.sharing-area .btn i {
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
#navbar {
  margin-bottom: -20px;
}
.sharing-area .btn-twitter {
  background-color: #55acee;
}
.sharing-area .btn-facebook {
  background-color: #3b5998;
}
.sharing-area .btn-instagram {
  background-color: #e4405f;
}
.sharing-area .btn-github {
  background-color: #333333;
}
#navbar .navbar {
  border-radius: 0;
}
@media (max-width: 830px) {
  .main-raised {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .mode button p
  {
    margin-left: 11px !important;
  }
 
  .banner_head h1{font-size: 40px !important;text-align: center;}
  .section-head{margin:0px 0px 10px;}
  .see_all_button{margin-top:20px !important;}
  .see_all_button a{margin-left:0px !important;}
  .container{max-width:95% !important;}
  .title_text_white {
    margin: 50px auto !important;
}
  .header-items button a {
    width: 100px;
}
.banner_head .side_img_head1 {
  max-height: 225px;
  min-height: 225px;
  object-fit: cover;
}
.bannergrid .side_img_head {
  max-height: 150px !important;
  min-height: 150px !important;
  object-fit: cover;
}

.footflex{
  margin-bottom:30px !important;
} 
.body-top .banner_sec_row p{
  max-width:100%;
  width:100%;
}
  section.body-top {
    padding-top: 55px !important;
}
  section {
    padding-top: 20px !important;
}
  .tab-pane#schedule-2 {
    padding-top: 20px;
  }
}
.bd-docs .bd-example.bd-example-popover-static .popover,
.bd-docs .bd-example.bd-example-tooltip-static .tooltip {
  z-index: 3 !important;
}
@media all and (max-width: 375px) {
  /* .page-header {
    height: calc(100vh + 270px);
  } */
  .index-page {
    overflow-x: hidden;
  }
  .pagination.pagination-primary .page-item:nth-of-type(3),
  .pagination.pagination-primary .page-item:nth-of-type(7) {
    display: none;
  }
  .pagination.pagination-info .page-item:nth-of-type(2) {
    display: none;
  }
  .main.main-raised {
    margin-top: -30px;
  }
  .form-group.is-focused .bootstrap-datetimepicker-widget {
    left: -32px !important;
  }
}
.show.modal .dropdown-toggle:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.bootstrap-datetimepicker-widget .timepicker .table-condesed .btn .ripple-container {
  width: 40px;
  height: 40px;
  margin: -11px 3px;
}
.bd-docs .bd-toc-item .bd-sidenav a span {
  float: right;
  margin-top: 5px;
  padding: 3px 7px;
  font-size: 8px;
  line-height: 9px;
  background-color: #9c27b0;
}
.bd-docs .bd-content .bd-title .btn {
  padding: 7px 20px;
  margin-bottom: 10px;
}
.form-check {
  margin-bottom: .5rem;
  padding-left: 0;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 0;
}
.form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:hover,
.form-group.is-focused .form-check .form-check-label:focus {
  color: rgba(0, 0, 0, .54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-input {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-sign:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-sign .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .54);
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
}
.form-check .form-check-sign .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -3px;
  margin-left: 7px;
  width: 0;
  color: #ffffff;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -moz-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.form-check .form-check-input:focus+.form-check-sign .check:after {
  opacity: 0.2;
}
.form-check .form-check-input:checked~.form-check-sign .check {
  background: #9c27b0;
}
.form-check .form-check-input:checked~.form-check-sign .check:before {
  color: #fff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards;
  -moz-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.form-check .form-check-input:checked~.form-check-sign:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.form-check .form-check-input:checked~.form-check-sign .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.form-check .form-check-input:not(:checked)+.form-check-sign:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.form-check .form-check-input:not(:checked)+.form-check-sign .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
fieldset[disabled] .form-check,
fieldset[disabled] .form-check .form-check-input,
.form-check .form-check-input[disabled]~.form-check-sign .check,
.form-check .form-check-input[disabled]+.circle {
  opacity: 0.5;
}
.form-check .form-check-input[disabled]~.form-check-sign .check {
  border-color: #000000;
  opacity: .26;
}
.form-check .form-check-input[disabled]+.form-check-sign .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  transform: rotate(-45deg);
}
.form-check .form-check-input[disabled][checked]+.form-check-sign .check {
  background-color: #000;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.switch label {
  position: relative;
  padding-left: 2.4375rem;
}
.switch label .bmd-switch-track {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  display: inline-block;
  width: 2.125rem;
  height: 0.875rem;
  cursor: pointer;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 50%, #c49bcb 50%, #c49bcb 100%);
  background-position: 0%;
  background-size: 4.25rem 0.875rem;
  border-radius: 2.125rem;
  transition: background-position 0.2s ease-in;
}
.switch label .bmd-switch-track::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background: #f1f1f1;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease;
  transform: translateY(-50%);
}
.switch label .bmd-switch-track:active::after {
  transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}
.switch label input {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.switch label input:checked+.bmd-switch-track {
  background-position: -100%;
}
.switch label input:checked+.bmd-switch-track::after {
  left: calc(100% - 1.25rem);
  background-color: #9c27b0;
}
.switch label input:disabled+.bmd-switch-track:active::after {
  transform: translateY(-50%);
}
.switch label input:disabled+.bmd-switch-track {
  cursor: default;
  background: rgba(0, 0, 0, 0.12);
}
.switch label input:disabled+.bmd-switch-track::after {
  background: #bdbdbd;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 25px;
  position: relative;
}
.form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:hover,
.form-group.is-focused .form-check .form-check-label:focus {
  color: rgba(0, 0, 0, .54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-label span {
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  transition-duration: 0.2s;
}
.form-check .form-check-label .circle {
  border: 1px solid rgba(0, 0, 0, .54);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  top: 1px;
}
.form-check .form-check-label .circle .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #9c27b0;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.form-check .form-check-input {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.form-check .form-check-input:checked~.check,
.form-check .form-check-input:checked~.circle {
  opacity: 1;
}
.form-check .form-check-input:checked~.check {
  background-color: #9c27b0;
}
.form-check .form-check-input:checked~.circle {
  border-color: #9c27b0;
}
.form-check .form-check-input:checked .check:before {
  animation: checkboxOn .5s forwards;
}
.form-check .form-check-input:checked~.circle .check {
  -webkit-transform: scale3d(0.65, 0.65, 1);
  -moz-transform: scale3d(0.65, 0.65, 1);
  -o-transform: scale3d(0.65, 0.65, 1);
  -ms-transform: scale3d(0.65, 0.65, 1);
  transform: scale3d(0.65, 0.65, 1);
}
.form-check .form-check-input[disabled]~.check,
.form-check .form-check-input[disabled]~.circle {
  opacity: 0.26;
}
.form-check .form-check-input[disabled]~.check {
  background-color: #000;
}
.form-check .form-check-input[disabled]~.circle {
  border-color: #000;
}
.form-check .form-check-input[disabled]+.circle .check {
  background-color: #000;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-label .circle:before {
  display: block;
  position: absolute;
  left: -1px;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: -1px;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-label .form-check-input:checked+.circle:before {
  animation: rippleOn .5s;
}
.form-check .form-check-label .form-check-input:checked+.circle .check:before {
  color: #FFFFFF;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkboxOn 0.3s forwards;
}
.form-check+.form-check {
  margin-top: 0;
}
@keyframes checkboxOn {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.carousel .carousel-control.left,
.carousel .carousel-control.right {
  background-image: none;
}
.carousel .carousel-control.right i {
  right: 0;
}
.carousel .card {
  margin-top: 0;
}
.carousel .item {
  text-align: center;
}
.carousel.carousel-full-navigation .carousel-control {
  width: 50%;
}
.carousel.carousel-full-navigation .carousel-control.left,
.carousel.carousel-full-navigation .carousel-control.right {
  background-image: none;
}
.carousel.carousel-full-navigation .carousel-control .material-icons,
.carousel.carousel-full-navigation .carousel-control .fa {
  display: none;
}
.carousel .carousel-control {
  height: 15%;
  margin: auto;
}
.carousel .carousel-control-prev .material-icons,
.carousel .carousel-control-next .material-icons {
  z-index: 5;
  display: inline-block;
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  margin: auto;
}
.carousel .carousel-indicators {
  bottom: 5px;
}
/* .carousel .carousel-indicators li,
.carousel .carousel-indicators .active {
  margin: 11px 10px;
}
.carousel .carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.carousel .carousel-indicators .active {
  margin-top: 10px;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
} */
.carousel .carousel-caption {
  padding-bottom: 45px;
}
.carousel .carousel-caption .material-icons {
  position: relative;
  top: 5px;
}
form {
  margin-bottom: 1.125rem;
}
.card form {
  margin: 0;
}
.navbar form {
  margin-bottom: 0;
}
.navbar form .bmd-form-group {
  display: inline-block;
  padding-top: 0;
}
.navbar form .btn {
  margin-bottom: 0;
}
.form-control {
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  border: 0;
  height: 36px;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  font-size: 14px;
}
.form-control:focus,
.bmd-form-group.is-focused .form-control {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
}
.form-control::-moz-placeholder {
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.form-control::-webkit-input-placeholder {
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.has-white .form-control::-moz-placeholder {
  color: #ffffff;
}
.has-white .form-control:-ms-input-placeholder {
  color: #ffffff;
}
.has-white .form-control::-webkit-input-placeholder {
  color: #ffffff;
}
.bmd-help {
  position: absolute;
  display: none;
  font-size: .8rem;
  font-weight: normal;
}
.bmd-form-group.is-focused .bmd-help {
  display: block;
}
.bmd-help:nth-of-type(2) {
  padding-top: 1rem;
}
.bmd-help+.bmd-help {
  position: relative;
  margin-bottom: 0;
}
.radio label,
.is-focused .radio label,
.radio-inline,
.is-focused .radio-inline,
.checkbox label,
.is-focused .checkbox label,
.checkbox-inline,
.is-focused .checkbox-inline,
.switch label,
.is-focused .switch label {
  color: #999999;
}
.radio label label:has(input[type=radio][disabled]),
.radio label label:has(input[type=radio][disabled]):hover,
.radio label label:has(input[type=radio][disabled]):focus,
.radio label label:has(input[type=checkbox][disabled]),
.radio label label:has(input[type=checkbox][disabled]):hover,
.radio label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .radio label,
fieldset[disabled] .radio label:hover,
fieldset[disabled] .radio label:focus,
.is-focused .radio label label:has(input[type=radio][disabled]),
.is-focused .radio label label:has(input[type=radio][disabled]):hover,
.is-focused .radio label label:has(input[type=radio][disabled]):focus,
.is-focused .radio label label:has(input[type=checkbox][disabled]),
.is-focused .radio label label:has(input[type=checkbox][disabled]):hover,
.is-focused .radio label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .radio label,
fieldset[disabled] .is-focused .radio label:hover,
fieldset[disabled] .is-focused .radio label:focus,
.radio-inline label:has(input[type=radio][disabled]),
.radio-inline label:has(input[type=radio][disabled]):hover,
.radio-inline label:has(input[type=radio][disabled]):focus,
.radio-inline label:has(input[type=checkbox][disabled]),
.radio-inline label:has(input[type=checkbox][disabled]):hover,
.radio-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .radio-inline,
fieldset[disabled] .radio-inline:hover,
fieldset[disabled] .radio-inline:focus,
.is-focused .radio-inline label:has(input[type=radio][disabled]),
.is-focused .radio-inline label:has(input[type=radio][disabled]):hover,
.is-focused .radio-inline label:has(input[type=radio][disabled]):focus,
.is-focused .radio-inline label:has(input[type=checkbox][disabled]),
.is-focused .radio-inline label:has(input[type=checkbox][disabled]):hover,
.is-focused .radio-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .radio-inline,
fieldset[disabled] .is-focused .radio-inline:hover,
fieldset[disabled] .is-focused .radio-inline:focus,
.checkbox label label:has(input[type=radio][disabled]),
.checkbox label label:has(input[type=radio][disabled]):hover,
.checkbox label label:has(input[type=radio][disabled]):focus,
.checkbox label label:has(input[type=checkbox][disabled]),
.checkbox label label:has(input[type=checkbox][disabled]):hover,
.checkbox label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox label:hover,
fieldset[disabled] .checkbox label:focus,
.is-focused .checkbox label label:has(input[type=radio][disabled]),
.is-focused .checkbox label label:has(input[type=radio][disabled]):hover,
.is-focused .checkbox label label:has(input[type=radio][disabled]):focus,
.is-focused .checkbox label label:has(input[type=checkbox][disabled]),
.is-focused .checkbox label label:has(input[type=checkbox][disabled]):hover,
.is-focused .checkbox label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .checkbox label,
fieldset[disabled] .is-focused .checkbox label:hover,
fieldset[disabled] .is-focused .checkbox label:focus,
.checkbox-inline label:has(input[type=radio][disabled]),
.checkbox-inline label:has(input[type=radio][disabled]):hover,
.checkbox-inline label:has(input[type=radio][disabled]):focus,
.checkbox-inline label:has(input[type=checkbox][disabled]),
.checkbox-inline label:has(input[type=checkbox][disabled]):hover,
.checkbox-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .checkbox-inline:hover,
fieldset[disabled] .checkbox-inline:focus,
.is-focused .checkbox-inline label:has(input[type=radio][disabled]),
.is-focused .checkbox-inline label:has(input[type=radio][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type=radio][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]),
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .checkbox-inline,
fieldset[disabled] .is-focused .checkbox-inline:hover,
fieldset[disabled] .is-focused .checkbox-inline:focus,
.switch label label:has(input[type=radio][disabled]),
.switch label label:has(input[type=radio][disabled]):hover,
.switch label label:has(input[type=radio][disabled]):focus,
.switch label label:has(input[type=checkbox][disabled]),
.switch label label:has(input[type=checkbox][disabled]):hover,
.switch label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .switch label,
fieldset[disabled] .switch label:hover,
fieldset[disabled] .switch label:focus,
.is-focused .switch label label:has(input[type=radio][disabled]),
.is-focused .switch label label:has(input[type=radio][disabled]):hover,
.is-focused .switch label label:has(input[type=radio][disabled]):focus,
.is-focused .switch label label:has(input[type=checkbox][disabled]),
.is-focused .switch label label:has(input[type=checkbox][disabled]):hover,
.is-focused .switch label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .switch label,
fieldset[disabled] .is-focused .switch label:hover,
fieldset[disabled] .is-focused .switch label:focus {
  color: #999999;
}
[class^='bmd-label'],
[class*=' bmd-label'] {
  color: #999999;
}
.form-control,
.is-focused .form-control {
  background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .form-control,
.form-control.disabled,
.form-control:disabled,
.form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.form-control.form-control-success,
.is-focused .form-control.form-control-success {
  background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.form-control.form-control-warning,
.is-focused .form-control.form-control-warning {
  background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.form-control.form-control-danger,
.is-focused .form-control.form-control-danger {
  background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #999999;
}
.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(153, 153, 153, 0.8);
  border-radius: .2rem;
}
.was-validated .is-focused .form-control:valid,
.is-focused .form-control.is-valid,
.was-validated .is-focused .custom-select:valid,
.is-focused .custom-select.is-valid {
  border-color: #999999;
}
.was-validated .is-focused .form-control:valid:focus,
.is-focused .form-control.is-valid:focus,
.was-validated .is-focused .custom-select:valid:focus,
.is-focused .custom-select.is-valid:focus {
  border-color: #999999;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.was-validated .is-focused .form-control:valid~.valid-feedback,
.was-validated .is-focused .form-control:valid~.valid-tooltip,
.is-focused .form-control.is-valid~.valid-feedback,
.is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .is-focused .custom-select:valid~.valid-feedback,
.was-validated .is-focused .custom-select:valid~.valid-tooltip,
.is-focused .custom-select.is-valid~.valid-feedback,
.is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .form-check-input:valid~.form-check-label,
.is-focused .form-check-input.is-valid~.form-check-label {
  color: #999999;
}
.was-validated .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .is-focused .form-check-input:valid~.valid-tooltip,
.is-focused .form-check-input.is-valid~.valid-feedback,
.is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-control-input:valid~.custom-control-label,
.is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #999999;
}
.was-validated .is-focused .custom-control-input:valid~.custom-control-label::before,
.is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #d9d9d9;
}
.was-validated .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .is-focused .custom-control-input:valid~.valid-tooltip,
.is-focused .custom-control-input.is-valid~.valid-feedback,
.is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #b3b3b3;
}
.was-validated .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.was-validated .is-focused .custom-file-input:valid~.custom-file-label,
.is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #999999;
}
.was-validated .is-focused .custom-file-input:valid~.custom-file-label::before,
.is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .is-focused .custom-file-input:valid~.valid-tooltip,
.is-focused .custom-file-input.is-valid~.valid-feedback,
.is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-file-input:valid:focus~.custom-file-label,
.is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.is-focused [class^='bmd-label'],
.is-focused [class*=' bmd-label'] {
  color: #9c27b0;
}
.is-focused .bmd-label-placeholder {
  color: #999999;
}
.is-focused .form-control {
  border-color: #d2d2d2;
}
.is-focused .bmd-help {
  color: #555;
}
.has-success [class^='bmd-label'],
.has-success [class*=' bmd-label'] {
  color: #4caf50;
}
.has-success .form-control,
.is-focused .has-success .form-control {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-success .form-control,
.has-success .form-control.disabled,
.has-success .form-control:disabled,
.has-success .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-success .form-control.form-control-success,
.is-focused .has-success .form-control.form-control-success {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-success .form-control.form-control-warning,
.is-focused .has-success .form-control.form-control-warning {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-success .form-control.form-control-danger,
.is-focused .has-success .form-control.form-control-danger {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-success .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.has-success .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem;
}
.was-validated .has-success .is-focused .form-control:valid,
.has-success .is-focused .form-control.is-valid,
.was-validated .has-success .is-focused .custom-select:valid,
.has-success .is-focused .custom-select.is-valid {
  border-color: #4caf50;
}
.was-validated .has-success .is-focused .form-control:valid:focus,
.has-success .is-focused .form-control.is-valid:focus,
.was-validated .has-success .is-focused .custom-select:valid:focus,
.has-success .is-focused .custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .has-success .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-control:valid~.valid-tooltip,
.has-success .is-focused .form-control.is-valid~.valid-feedback,
.has-success .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-select:valid~.valid-tooltip,
.has-success .is-focused .custom-select.is-valid~.valid-feedback,
.has-success .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .form-check-input:valid~.form-check-label,
.has-success .is-focused .form-check-input.is-valid~.form-check-label {
  color: #4caf50;
}
.was-validated .has-success .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-check-input:valid~.valid-tooltip,
.has-success .is-focused .form-check-input.is-valid~.valid-feedback,
.has-success .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .custom-control-input:valid~.custom-control-label,
.has-success .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #4caf50;
}
.was-validated .has-success .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-success .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated .has-success .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-control-input:valid~.valid-tooltip,
.has-success .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-success .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-success .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #6ec071;
}
.was-validated .has-success .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-success .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .has-success .is-focused .custom-file-input:valid~.custom-file-label,
.has-success .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #4caf50;
}
.was-validated .has-success .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-success .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-success .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-file-input:valid~.valid-tooltip,
.has-success .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-success .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-success .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.has-success .is-focused [class^='bmd-label'],
.has-success .is-focused [class*=' bmd-label'] {
  color: #4caf50;
}
.has-success .is-focused .bmd-label-placeholder {
  color: #4caf50;
}
.has-success .is-focused .form-control {
  border-color: #4caf50;
}
.has-success .is-focused .bmd-help {
  color: #555;
}
.has-info [class^='bmd-label'],
.has-info [class*=' bmd-label'] {
  color: #00bcd4;
}
.has-info .form-control,
.is-focused .has-info .form-control {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-info .form-control,
.has-info .form-control.disabled,
.has-info .form-control:disabled,
.has-info .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-info .form-control.form-control-success,
.is-focused .has-info .form-control.form-control-success {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-info .form-control.form-control-warning,
.is-focused .has-info .form-control.form-control-warning {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-info .form-control.form-control-danger,
.is-focused .has-info .form-control.form-control-danger {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-info .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00bcd4;
}
.has-info .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 188, 212, 0.8);
  border-radius: .2rem;
}
.was-validated .has-info .is-focused .form-control:valid,
.has-info .is-focused .form-control.is-valid,
.was-validated .has-info .is-focused .custom-select:valid,
.has-info .is-focused .custom-select.is-valid {
  border-color: #00bcd4;
}
.was-validated .has-info .is-focused .form-control:valid:focus,
.has-info .is-focused .form-control.is-valid:focus,
.was-validated .has-info .is-focused .custom-select:valid:focus,
.has-info .is-focused .custom-select.is-valid:focus {
  border-color: #00bcd4;
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.was-validated .has-info .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-control:valid~.valid-tooltip,
.has-info .is-focused .form-control.is-valid~.valid-feedback,
.has-info .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-select:valid~.valid-tooltip,
.has-info .is-focused .custom-select.is-valid~.valid-feedback,
.has-info .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .form-check-input:valid~.form-check-label,
.has-info .is-focused .form-check-input.is-valid~.form-check-label {
  color: #00bcd4;
}
.was-validated .has-info .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-check-input:valid~.valid-tooltip,
.has-info .is-focused .form-check-input.is-valid~.valid-feedback,
.has-info .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .custom-control-input:valid~.custom-control-label,
.has-info .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #00bcd4;
}
.was-validated .has-info .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-info .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #55ecff;
}
.was-validated .has-info .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-control-input:valid~.valid-tooltip,
.has-info .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-info .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-info .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #08e3ff;
}
.was-validated .has-info .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-info .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.was-validated .has-info .is-focused .custom-file-input:valid~.custom-file-label,
.has-info .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #00bcd4;
}
.was-validated .has-info .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-info .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-info .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-file-input:valid~.valid-tooltip,
.has-info .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-info .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-info .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.has-info .is-focused [class^='bmd-label'],
.has-info .is-focused [class*=' bmd-label'] {
  color: #00bcd4;
}
.has-info .is-focused .bmd-label-placeholder {
  color: #00bcd4;
}
.has-info .is-focused .form-control {
  border-color: #00bcd4;
}
.has-info .is-focused .bmd-help {
  color: #555;
}
.has-white [class^='bmd-label'],
.has-white [class*=' bmd-label'] {
  color: #ffffff;
}
.has-white .form-control,
.is-focused .has-white .form-control {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px);
}
fieldset[disabled][disabled] .has-white .form-control,
.has-white .form-control.disabled,
.has-white .form-control:disabled,
.has-white .form-control[disabled] {
  background-image: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-white .form-control.form-control-success,
.is-focused .has-white .form-control.form-control-success {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-white .form-control.form-control-warning,
.is-focused .has-white .form-control.form-control-warning {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-white .form-control.form-control-danger,
.is-focused .has-white .form-control.form-control-danger {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-white .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ffffff;
}
.has-white .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: .2rem;
}
.was-validated .has-white .is-focused .form-control:valid,
.has-white .is-focused .form-control.is-valid,
.was-validated .has-white .is-focused .custom-select:valid,
.has-white .is-focused .custom-select.is-valid {
  border-color: #ffffff;
}
.was-validated .has-white .is-focused .form-control:valid:focus,
.has-white .is-focused .form-control.is-valid:focus,
.was-validated .has-white .is-focused .custom-select:valid:focus,
.has-white .is-focused .custom-select.is-valid:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.was-validated .has-white .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-control:valid~.valid-tooltip,
.has-white .is-focused .form-control.is-valid~.valid-feedback,
.has-white .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-select:valid~.valid-tooltip,
.has-white .is-focused .custom-select.is-valid~.valid-feedback,
.has-white .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .form-check-input:valid~.form-check-label,
.has-white .is-focused .form-check-input.is-valid~.form-check-label {
  color: #ffffff;
}
.was-validated .has-white .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-check-input:valid~.valid-tooltip,
.has-white .is-focused .form-check-input.is-valid~.valid-feedback,
.has-white .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .custom-control-input:valid~.custom-control-label,
.has-white .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #ffffff;
}
.was-validated .has-white .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-white .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: white;
}
.was-validated .has-white .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-control-input:valid~.valid-tooltip,
.has-white .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-white .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-white .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: white;
}
.was-validated .has-white .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-white .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.was-validated .has-white .is-focused .custom-file-input:valid~.custom-file-label,
.has-white .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #ffffff;
}
.was-validated .has-white .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-white .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-white .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-file-input:valid~.valid-tooltip,
.has-white .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-white .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-white .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.has-white .is-focused [class^='bmd-label'],
.has-white .is-focused [class*=' bmd-label'] {
  color: #ffffff;
}
.has-white .is-focused .bmd-label-placeholder {
  color: #ffffff;
}
.has-white .is-focused .form-control {
  border-color: #ffffff;
}
.has-white .is-focused .bmd-help {
  color: #555;
}
.has-white .form-control:focus {
  color: #ffffff;
}
.has-warning [class^='bmd-label'],
.has-warning [class*=' bmd-label'] {
  color: #ff9800;
}
.has-warning .form-control,
.is-focused .has-warning .form-control {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-warning .form-control,
.has-warning .form-control.disabled,
.has-warning .form-control:disabled,
.has-warning .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-warning .form-control.form-control-success,
.is-focused .has-warning .form-control.form-control-success {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-warning .form-control.form-control-warning,
.is-focused .has-warning .form-control.form-control-warning {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-warning .form-control.form-control-danger,
.is-focused .has-warning .form-control.form-control-danger {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-warning .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff9800;
}
.has-warning .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 152, 0, 0.8);
  border-radius: .2rem;
}
.was-validated .has-warning .is-focused .form-control:valid,
.has-warning .is-focused .form-control.is-valid,
.was-validated .has-warning .is-focused .custom-select:valid,
.has-warning .is-focused .custom-select.is-valid {
  border-color: #ff9800;
}
.was-validated .has-warning .is-focused .form-control:valid:focus,
.has-warning .is-focused .form-control.is-valid:focus,
.was-validated .has-warning .is-focused .custom-select:valid:focus,
.has-warning .is-focused .custom-select.is-valid:focus {
  border-color: #ff9800;
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.was-validated .has-warning .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-control:valid~.valid-tooltip,
.has-warning .is-focused .form-control.is-valid~.valid-feedback,
.has-warning .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-select:valid~.valid-tooltip,
.has-warning .is-focused .custom-select.is-valid~.valid-feedback,
.has-warning .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .form-check-input:valid~.form-check-label,
.has-warning .is-focused .form-check-input.is-valid~.form-check-label {
  color: #ff9800;
}
.was-validated .has-warning .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-check-input:valid~.valid-tooltip,
.has-warning .is-focused .form-check-input.is-valid~.valid-feedback,
.has-warning .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .custom-control-input:valid~.custom-control-label,
.has-warning .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #ff9800;
}
.was-validated .has-warning .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-warning .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #ffcc80;
}
.was-validated .has-warning .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-control-input:valid~.valid-tooltip,
.has-warning .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-warning .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-warning .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #ffad33;
}
.was-validated .has-warning .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-warning .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.was-validated .has-warning .is-focused .custom-file-input:valid~.custom-file-label,
.has-warning .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #ff9800;
}
.was-validated .has-warning .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-warning .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-warning .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-file-input:valid~.valid-tooltip,
.has-warning .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-warning .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-warning .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.has-warning .is-focused [class^='bmd-label'],
.has-warning .is-focused [class*=' bmd-label'] {
  color: #ff9800;
}
.has-warning .is-focused .bmd-label-placeholder {
  color: #ff9800;
}
.has-warning .is-focused .form-control {
  border-color: #ff9800;
}
.has-warning .is-focused .bmd-help {
  color: #555;
}
.has-danger [class^='bmd-label'],
.has-danger [class*=' bmd-label'] {
  color: #f44336;
}
.has-danger .form-control,
.is-focused .has-danger .form-control {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-danger .form-control,
.has-danger .form-control.disabled,
.has-danger .form-control:disabled,
.has-danger .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-danger .form-control.form-control-success,
.is-focused .has-danger .form-control.form-control-success {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-danger .form-control.form-control-warning,
.is-focused .has-danger .form-control.form-control-warning {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-danger .form-control.form-control-danger,
.is-focused .has-danger .form-control.form-control-danger {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-danger .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.has-danger .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem;
}
.was-validated .has-danger .is-focused .form-control:valid,
.has-danger .is-focused .form-control.is-valid,
.was-validated .has-danger .is-focused .custom-select:valid,
.has-danger .is-focused .custom-select.is-valid {
  border-color: #f44336;
}
.was-validated .has-danger .is-focused .form-control:valid:focus,
.has-danger .is-focused .form-control.is-valid:focus,
.was-validated .has-danger .is-focused .custom-select:valid:focus,
.has-danger .is-focused .custom-select.is-valid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .has-danger .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-control:valid~.valid-tooltip,
.has-danger .is-focused .form-control.is-valid~.valid-feedback,
.has-danger .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-select:valid~.valid-tooltip,
.has-danger .is-focused .custom-select.is-valid~.valid-feedback,
.has-danger .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .form-check-input:valid~.form-check-label,
.has-danger .is-focused .form-check-input.is-valid~.form-check-label {
  color: #f44336;
}
.was-validated .has-danger .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-check-input:valid~.valid-tooltip,
.has-danger .is-focused .form-check-input.is-valid~.valid-feedback,
.has-danger .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .custom-control-input:valid~.custom-control-label,
.has-danger .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #f44336;
}
.was-validated .has-danger .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-danger .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #fbb4af;
}
.was-validated .has-danger .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-control-input:valid~.valid-tooltip,
.has-danger .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-danger .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-danger .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #f77066;
}
.was-validated .has-danger .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-danger .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .has-danger .is-focused .custom-file-input:valid~.custom-file-label,
.has-danger .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #f44336;
}
.was-validated .has-danger .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-danger .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-danger .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-file-input:valid~.valid-tooltip,
.has-danger .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-danger .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-danger .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.has-danger .is-focused [class^='bmd-label'],
.has-danger .is-focused [class*=' bmd-label'] {
  color: #f44336;
}
.has-danger .is-focused .bmd-label-placeholder {
  color: #f44336;
}
.has-danger .is-focused .form-control {
  border-color: #f44336;
}
.has-danger .is-focused .bmd-help {
  color: #555;
}
.has-rose [class^='bmd-label'],
.has-rose [class*=' bmd-label'] {
  color: #e91e63;
}
.has-rose .form-control,
.is-focused .has-rose .form-control {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-rose .form-control,
.has-rose .form-control.disabled,
.has-rose .form-control:disabled,
.has-rose .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-rose .form-control.form-control-success,
.is-focused .has-rose .form-control.form-control-success {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-rose .form-control.form-control-warning,
.is-focused .has-rose .form-control.form-control-warning {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-rose .form-control.form-control-danger,
.is-focused .has-rose .form-control.form-control-danger {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-rose .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e91e63;
}
.has-rose .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(233, 30, 99, 0.8);
  border-radius: .2rem;
}
.was-validated .has-rose .is-focused .form-control:valid,
.has-rose .is-focused .form-control.is-valid,
.was-validated .has-rose .is-focused .custom-select:valid,
.has-rose .is-focused .custom-select.is-valid {
  border-color: #e91e63;
}
.was-validated .has-rose .is-focused .form-control:valid:focus,
.has-rose .is-focused .form-control.is-valid:focus,
.was-validated .has-rose .is-focused .custom-select:valid:focus,
.has-rose .is-focused .custom-select.is-valid:focus {
  border-color: #e91e63;
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.was-validated .has-rose .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-control:valid~.valid-tooltip,
.has-rose .is-focused .form-control.is-valid~.valid-feedback,
.has-rose .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-select:valid~.valid-tooltip,
.has-rose .is-focused .custom-select.is-valid~.valid-feedback,
.has-rose .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .form-check-input:valid~.form-check-label,
.has-rose .is-focused .form-check-input.is-valid~.form-check-label {
  color: #e91e63;
}
.was-validated .has-rose .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-check-input:valid~.valid-tooltip,
.has-rose .is-focused .form-check-input.is-valid~.valid-feedback,
.has-rose .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .custom-control-input:valid~.custom-control-label,
.has-rose .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #e91e63;
}
.was-validated .has-rose .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-rose .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #f492b4;
}
.was-validated .has-rose .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-control-input:valid~.valid-tooltip,
.has-rose .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-rose .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-rose .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #ee4c83;
}
.was-validated .has-rose .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-rose .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.was-validated .has-rose .is-focused .custom-file-input:valid~.custom-file-label,
.has-rose .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #e91e63;
}
.was-validated .has-rose .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-rose .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-rose .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-file-input:valid~.valid-tooltip,
.has-rose .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-rose .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-rose .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.has-rose .is-focused [class^='bmd-label'],
.has-rose .is-focused [class*=' bmd-label'] {
  color: #e91e63;
}
.has-rose .is-focused .bmd-label-placeholder {
  color: #e91e63;
}
.has-rose .is-focused .form-control {
  border-color: #e91e63;
}
.has-rose .is-focused .bmd-help {
  color: #555;
}
.bmd-form-group {
  position: relative;
  padding-top: 27px;
}
.bmd-form-group:not(.has-success):not(.has-danger) [class^='bmd-label'].bmd-label-floating,
.bmd-form-group:not(.has-success):not(.has-danger) [class*=' bmd-label'].bmd-label-floating {
  color: #AAAAAA;
}
.bmd-form-group [class^='bmd-label'],
.bmd-form-group [class*=' bmd-label'] {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}
.bmd-form-group [class^='bmd-label'].bmd-label-floating,
.bmd-form-group [class*=' bmd-label'].bmd-label-floating {
  will-change: left, top, contents;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
}
.bmd-form-group.is-filled .bmd-label-placeholder {
  display: none;
}
.bmd-form-group.bmd-collapse-inline {
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 2.1em;
}
.bmd-form-group.bmd-collapse-inline .collapse {
  flex: 1;
  display: none;
}
.bmd-form-group.bmd-collapse-inline .collapse.show {
  max-width: 1200px;
}
.bmd-form-group.bmd-collapse-inline .collapsing,
.bmd-form-group.bmd-collapse-inline .width:not(.collapse),
.bmd-form-group.bmd-collapse-inline .collapse.show {
  display: block;
}
.bmd-form-group.bmd-collapse-inline .collapsing {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-form-group .form-control,
.bmd-form-group label,
.bmd-form-group input::placeholder {
  line-height: 1.1;
}
.bmd-form-group label {
  color: #AAAAAA;
}
.bmd-form-group .radio label,
.bmd-form-group label.radio-inline,
.bmd-form-group .checkbox label,
.bmd-form-group label.checkbox-inline,
.bmd-form-group .switch label {
  line-height: 1.5;
}
.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group label {
  font-size: 0.875rem;
}
.bmd-form-group .bmd-label-floating,
.bmd-form-group .bmd-label-placeholder {
  top: 2.3125rem;
}
.bmd-form-group .is-focused .bmd-label-floating,
.bmd-form-group .is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group .bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group .bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm label,
.bmd-form-group.bmd-form-group-sm input::placeholder {
  line-height: 1.1;
}
.bmd-form-group.bmd-form-group-sm label {
  color: #AAAAAA;
}
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label.radio-inline,
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm label.checkbox-inline,
.bmd-form-group.bmd-form-group-sm .switch label {
  line-height: 1.5;
}
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label {
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
  top: 1.875rem;
}
.bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating {
  top: 0.75rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-static {
  top: 0.1rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-help {
  margin-top: 0;
  font-size: 0.65625rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.34375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg label,
.bmd-form-group.bmd-form-group-lg input::placeholder {
  line-height: 1.1;
}
.bmd-form-group.bmd-form-group-lg label {
  color: #AAAAAA;
}
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label.radio-inline,
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg label.checkbox-inline,
.bmd-form-group.bmd-form-group-lg .switch label {
  line-height: 1.5;
}
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label {
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
  top: 2.4375rem;
}
.bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-help {
  margin-top: 0;
  font-size: 0.9375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.59375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.form-control,
label,
input::placeholder {
  line-height: 1.1;
}
label {
  color: #AAAAAA;
}
.radio label,
label.radio-inline,
.checkbox label,
label.checkbox-inline,
.switch label {
  line-height: 1.5;
}
.checkbox label,
.radio label,
label {
  font-size: 0.875rem;
}
.bmd-label-floating,
.bmd-label-placeholder {
  top: 2.3125rem;
}
.is-focused .bmd-label-floating,
.is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.form-control.form-control-success,
.form-control.form-control-warning,
.form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.form-control.form-control-success,
.form-control.form-control-success:focus,
.bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning,
.form-control.form-control-warning:focus,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.form-control.form-control-danger:focus,
.bmd-form-group.is-focused .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}
.form-control.form-control-success:focus,
.bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning:focus,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.bmd-form-group.is-focused .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
select,
select.form-control {
  --moz-appearance: none;
  --webkit-appearance: none;
}
@media (min-width: 576px) {
  .form-inline .input-group {
    display: inline-flex;
    align-items: center;
  }
}
.form-control-feedback {
  position: absolute;
  top: 33px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
}
.has-success .form-control-feedback {
  color: #4caf50;
  opacity: 1;
}
.has-danger .form-control-feedback {
  color: #f44336;
  opacity: 1;
}
.form-group {
  margin-bottom: 17px;
  position: relative;
}
textarea {
  height: auto !important;
  resize: none;
  line-height: 1.428571 !important;
}
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.form-newsletter .input-group,
.form-newsletter .form-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
  padding-top: 5px;
}
.form-newsletter .btn {
  float: left;
  width: 20%;
  margin: 9px 0 0;
}
.form-file-upload .input-group-btn:last-child>.btn-round {
  border-radius: 30px;
}
.form-file-upload .input-group-btn .btn {
  margin: 0;
}
.form-file-upload .input-group {
  width: 100%;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 15px;
  background-color: transparent;
  border-color: transparent;
}
.input-group .input-group-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 0;
}
.list-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: .5rem 0;
}
.list-group.bmd-list-group-sm {
  padding: .25rem 0;
}
.list-group.bmd-list-group-sm .list-group-item {
  padding: .5rem 1rem;
}
.bmd-list-group-col {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.list-group-item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 1rem;
  line-height: 1;
}
.list-group-item .list-group-item-text {
  min-width: 0;
  max-height: 2.188rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-group-item :first-child {
  margin-right: 2rem;
}
.list-group-item>.pull-xs-right,
.list-group-item>.pull-sm-right,
.list-group-item>.pull-md-right,
.list-group-item>.pull-lg-right,
.list-group-item>.pull-xl-right,
.list-group-item>*~.label:last-child,
.list-group-item>*~.material-icons:last-child {
  margin-right: 0;
  margin-left: auto;
}
.list-group-item .material-icons.pull-xs-right,
.list-group-item .material-icons.pull-sm-right,
.list-group-item .material-icons.pull-md-right,
.list-group-item .material-icons.pull-lg-right,
.list-group-item .material-icons.pull-xl-right,
.list-group-item .material-icons~.material-icons:last-child {
  padding-left: 1rem;
}
.list-group-item .list-group-item-text {
  font-size: .875rem;
  color: #555;
}
.nav-link {
  text-transform: capitalize;
}
.navbar-nav .nav-link {
  padding: 0.5321rem;
  font-size: 0.875rem;
  font-weight: 400;
}
.nav-tabs,
.nav-pills {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}
.nav-tabs .nav-link,
.nav-pills .nav-link {
  padding: 1.4286em 0.8575em;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0;
}
.nav .nav-item {
  position: relative;
}
.img-thumbnail {
  border-radius: 16px;
}
.img-raised {
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.rounded {
  border-radius: 6px !important;
}
.navbar,
.makeStyles-appBar-6 {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 15px 0px !important;
  margin-bottom: 20px;
  color: #fff !important;
  background-color: #1e2023 !important;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
}
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4);
  background-color: #ffffff;
  color: #555;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-color: #555;
}
.navbar.fixed-top {
  border-radius: 0;
}
.navbar .navbar-nav .nav-item .nav-link {
  position: relative;
  color: inherit;
  padding: 0.9375rem;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 20px;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
  position: relative;
  top: 2px;
  margin-top: -4px;
  margin-right: 4px;
}
.navbar .navbar-nav .nav-item .nav-link .material-icons,
.navbar .navbar-nav .nav-item .nav-link .fa {
  font-size: 1.25rem;
  max-width: 24px;
  margin-top: -1.1em;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top: -7px;
  top: 3px;
  position: relative;
  margin-right: 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo {
  padding: 0;
  margin: 0 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo:after {
  display: none;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .profile-photo-small {
  height: 40px;
  width: 40px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .ripple-container {
  border-radius: 50%;
}
.navbar .navbar-nav .dropdown-menu-right {
  transform-origin: 100% 0;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .btn,
.navbar .navbar-nav .nav-item .btn {
  margin-top: 0;
  margin-bottom: 0;
}
.navbar .navbar-toggler {
  cursor: pointer;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 22px;
  height: 2px;
  
  outline: 0;
  display: block;
  border-radius: 1px;
}
.navbar .navbar-toggler .navbar-toggler-icon+.navbar-toggler-icon {
  margin-top: 4px;
}
.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
.navbar .navbar-wrapper {
  display: inline-flex;
  align-items: center;
}
.navbar .navbar-brand {
  position: relative;
  color: inherit;
  height: 50px;
  font-size: 1.125rem;
  line-height: 30px;
  padding: 0.625rem 0;
}
.navbar.bg-primary {
  color: #ffffff;
  background-color: #9c27b0 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46);
}
.navbar.bg-primary .dropdown-item:hover,
.navbar.bg-primary .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #9c27b0;
  color: #ffffff;
}
.navbar.bg-primary .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-info {
  color: #ffffff;
  background-color: #00bcd4 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46);
}
.navbar.bg-info .dropdown-item:hover,
.navbar.bg-info .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background-color: #00bcd4;
  color: #ffffff;
}
.navbar.bg-info .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-warning {
  color: #ffffff;
  background-color: #ff9800 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46);
}
.navbar.bg-warning .dropdown-item:hover,
.navbar.bg-warning .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background-color: #ff9800;
  color: #ffffff;
}
.navbar.bg-warning .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-rose {
  color: #ffffff;
  background-color: #e91e63 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46);
}
.navbar.bg-rose .dropdown-item:hover,
.navbar.bg-rose .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  background-color: #e91e63;
  color: #ffffff;
}
.navbar.bg-rose .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-danger {
  color: #ffffff;
  background-color: #f44336 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46);
}
.navbar.bg-danger .dropdown-item:hover,
.navbar.bg-danger .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background-color: #f44336;
  color: #ffffff;
}
.navbar.bg-danger .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-success {
  color: #ffffff;
  background-color: #4caf50 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46);
}
.navbar.bg-success .dropdown-item:hover,
.navbar.bg-success .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background-color: #4caf50;
  color: #ffffff;
}
.navbar.bg-success .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-dark {
  color: #ffffff;
  background-color: #212121 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46);
}
.navbar.bg-dark .dropdown-item:hover,
.navbar.bg-dark .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
  background-color: #212121;
  color: #ffffff;
}
.navbar.bg-dark .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  padding-top: 25px;
  color: #ffffff;
}
#navbar .navbar {
  border-radius: 0;
}
.badge {
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: #ffffff;
}
.badge.badge-primary {
  background-color: #9c27b0;
}
.badge.badge-info {
  background-color: #00bcd4;
}
.badge.badge-success {
  background-color: #4caf50;
}
.badge.badge-warning {
  background-color: #ff9800;
}
.badge.badge-danger {
  background-color: #f44336;
}
.badge.badge-rose {
  background-color: #e91e63;
}
.badge.badge-default {
  background-color: #999;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: #c1c1c1;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #89229b;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #00a5bb;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #449d48;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #e68900;
  color: #ffffff;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #f32c1e;
}
.badge-rose[href]:hover,
.badge-rose[href]:focus {
  background-color: #d81558;
}
.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px;
}
.alert b {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}
.alert,
.alert.alert-default {
  background-color: white;
  color: #555;
}
.alert a,
.alert .alert-link,
.alert.alert-default a,
.alert.alert-default .alert-link {
  color: #555;
}
.alert.alert-inverse {
  background-color: #292929;
  color: #fff;
}
.alert.alert-inverse a,
.alert.alert-inverse .alert-link {
  color: #fff;
}
.alert.alert-primary {
  background-color: #a72abd;
  color: #fff;
}
.alert.alert-primary a,
.alert.alert-primary .alert-link {
  color: #fff;
}
.alert.alert-success {
  background-color: #55b559;
  color: #fff;
}
.alert.alert-success a,
.alert.alert-success .alert-link {
  color: #fff;
}
.alert.alert-info {
  background-color: #00cae3;
  color: #fff;
}
.alert.alert-info a,
.alert.alert-info .alert-link {
  color: #fff;
}
.alert.alert-warning {
  background-color: #ff9e0f;
  color: #fff;
}
.alert.alert-warning a,
.alert.alert-warning .alert-link {
  color: #fff;
}
.alert.alert-danger {
  background-color: #f55145;
  color: #fff;
}
.alert.alert-danger a,
.alert.alert-danger .alert-link {
  color: #fff;
}
.alert.alert-rose {
  background-color: #ea2c6d;
  color: #fff;
}
.alert.alert-rose a,
.alert.alert-rose .alert-link {
  color: #fff;
}
.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: #fff;
}
.alert-default a,
.alert-default .alert-link {
  color: rgba(0, 0, 0, 0.87);
}
.alert .alert-icon {
  display: block;
  float: left;
  margin-right: 1.071rem;
}
.alert .alert-icon i {
  margin-top: -7px;
  top: 5px;
  position: relative;
}
.alert .close {
  color: #ffffff;
  text-shadow: none;
  opacity: .9;
}
.alert .close i {
  font-size: 20px;
}
.alert .close:hover,
.alert .close:focus {
  opacity: 1;
}
.pagination>.page-item>.page-link,
.pagination>.page-item>span {
  border: 0;
  border-radius: 30px !important;
  transition: all .3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: transparent;
  text-align: center;
}
.pagination>.page-item>.page-link:hover,
.pagination>.page-item>.page-link:focus,
.pagination>.page-item>span:hover,
.pagination>.page-item>span:focus {
  color: #999999;
}
.pagination>.page-item.active>a,
.pagination>.page-item.active>span {
  color: #999999;
}
.pagination>.page-item.active>a,
.pagination>.page-item.active>a:focus,
.pagination>.page-item.active>a:hover,
.pagination>.page-item.active>span,
.pagination>.page-item.active>span:focus,
.pagination>.page-item.active>span:hover {
  background-color: #9c27b0;
  border-color: #9c27b0;
  color: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14), 0 1px 10px 0 rgba(156, 39, 176, 0.12), 0 2px 4px -1px rgba(156, 39, 176, 0.2);
}
.pagination.pagination-info>.page-item.active>a,
.pagination.pagination-info>.page-item.active>a:focus,
.pagination.pagination-info>.page-item.active>a:hover,
.pagination.pagination-info>.page-item.active>span,
.pagination.pagination-info>.page-item.active>span:focus,
.pagination.pagination-info>.page-item.active>span:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14), 0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2);
}
.pagination.pagination-success>.page-item.active>a,
.pagination.pagination-success>.page-item.active>a:focus,
.pagination.pagination-success>.page-item.active>a:hover,
.pagination.pagination-success>.page-item.active>span,
.pagination.pagination-success>.page-item.active>span:focus,
.pagination.pagination-success>.page-item.active>span:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14), 0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2);
}
.pagination.pagination-warning>.page-item.active>a,
.pagination.pagination-warning>.page-item.active>a:focus,
.pagination.pagination-warning>.page-item.active>a:hover,
.pagination.pagination-warning>.page-item.active>span,
.pagination.pagination-warning>.page-item.active>span:focus,
.pagination.pagination-warning>.page-item.active>span:hover {
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14), 0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2);
}
.pagination.pagination-danger>.page-item.active>a,
.pagination.pagination-danger>.page-item.active>a:focus,
.pagination.pagination-danger>.page-item.active>a:hover,
.pagination.pagination-danger>.page-item.active>span,
.pagination.pagination-danger>.page-item.active>span:focus,
.pagination.pagination-danger>.page-item.active>span:hover {
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14), 0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2);
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.nav-pills:not(.flex-column) .nav-item+.nav-item:not(:first-child) {
  margin-left: 5px;
}
.nav-pills.flex-column .nav-item+.nav-item {
  margin-top: 5px;
}
.nav-pills .nav-item .nav-link {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555;
  transition: all .3s;
  border-radius: 30px;
  padding: 10px 15px;
}
.nav-pills .nav-item .nav-link:hover {
  background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #9c27b0;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.nav-pills .nav-item i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}
.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background-color: #00bcd4;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 188, 212, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-rose .nav-item .nav-link.active,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:hover {
  background-color: #e91e63;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
  background-color: #4caf50;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(76, 175, 80, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
  background-color: #ff9800;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(255, 152, 0, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
  background-color: #f44336;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(244, 67, 54, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-icons .nav-item .nav-link {
  border-radius: 4px;
}
.tab-space {
  padding: 20px 0 50px 0px;
}
.info .icon {
  color: #999999;
}
.info .icon>i {
  font-size: 3.85rem;
}
.info .info-title {
  color: #000000;
  margin: 1.75rem 0 0.875rem;
}
.info p {
  color: #999999;
}
.info-horizontal .icon {
  float: left;
  margin-top: 24px;
  margin-right: 10px;
}
.info-horizontal .icon>i {
  font-size: 2.25rem;
}
.info-horizontal .description {
  overflow: hidden;
}
.icon.icon-primary {
  color: #9c27b0;
}
.icon.icon-info {
  color: #00bcd4;
}
.icon.icon-success {
  color: #4caf50;
}
.icon.icon-warning {
  color: #ff9800;
}
.icon.icon-danger {
  color: #f44336;
}
.icon.icon-rose {
  color: #e91e63;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  line-height: 1.5em;
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
.h1 {
  font-size: 40px;
  line-height: 1.15em;
}
h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3,
.h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5,
.h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
  margin-bottom: 15px;
}
h6,
.h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 16px;
  margin: 0 0 10px;
}
b {
  font-weight: 700;
}
small,
.small {
  font-size: 75%;
  color: #777;
}
.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-weight: 500;
}
.title,
.title a,
.card-title,
.card-title a,
.info-title,
.info-title a,
.footer-brand,
.footer-brand a,
.footer-big h5,
.footer-big h5 a,
.footer-big h4,
.footer-big h4 a,
.media .media-heading,
.media .media-heading a {
  color: #000000;
  text-decoration: none;
}
h2.title {
  margin-bottom: 1rem;
}
.description,
.card-description,
.footer-big p {
  color: #999;
}
.text-warning {
  color: #ff9800 !important;
}
.text-primary {
  color: #9c27b0 !important;
}
.text-danger {
  color: #f44336 !important;
}
.text-success {
  color: #4caf50 !important;
}
.text-info {
  color: #00bcd4 !important;
}
.text-rose {
  color: #e91e63 !important;
}
.text-gray {
  color: #999999 !important;
}
.space {
  height: 130px;
  display: block;
}
.space-110 {
  height: 110px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-70 {
  height: 70px;
  display: block;
}
.blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}
.blockquote p {
  font-size: 1.063rem;
  font-style: italic;
}
.blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
.nav-tabs {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}
.nav-tabs .nav-item .nav-link {
  color: #ffffff;
  border: 0;
  margin: 0;
  border-radius: 3px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px;
  background-color: transparent;
  transition: 0.3s background-color 0s;
}
.nav-tabs .nav-item .nav-link:hover {
  border: 0;
}
.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link:hover,
.nav-tabs .nav-item .nav-link:focus {
  border: 0 !important;
  color: #ffffff !important;
  font-weight: 500;
}
.nav-tabs .nav-item.disabled .nav-link,
.nav-tabs .nav-item.disabled .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.nav-tabs .nav-item .material-icons {
  margin: -1px 5px 0 0;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s background-color 0.2s;
}
.nav-tabs .nav-link {
  border-bottom: 0.214rem solid transparent;
}
.nav-tabs .nav-link {
  color: #555;
}
.nav-tabs .nav-link.active {
  color: rgba(0, 0, 0, 0.87);
  border-color: #9c27b0;
}
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  border-color: #9c27b0;
}
.nav-tabs .nav-link.disabled {
  color: #999;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:hover,
.nav-tabs .nav-link.disabled:focus {
  color: #999;
}
.nav-tabs.header-primary .nav-link {
  color: white;
}
.nav-tabs.header-primary .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.active:hover,
.nav-tabs.header-primary .nav-link.active:focus {
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.header-primary .nav-link.disabled,
.nav-tabs.header-primary .nav-link.disabled:hover,
.nav-tabs.header-primary .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link {
  color: white;
}
.nav-tabs.bg-inverse .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active:hover,
.nav-tabs.bg-inverse .nav-link.active:focus {
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link.disabled,
.nav-tabs.bg-inverse .nav-link.disabled:hover,
.nav-tabs.bg-inverse .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}
.card-nav-tabs {
  margin-top: 45px;
}
.card-nav-tabs .card-header {
  margin-top: -30px !important;
}
.tooltip-arrow {
  display: none;
}
.tooltip.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.tooltip {
  opacity: 0;
  transition: opacity, transform .2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  font-size: 0.875rem;
}
.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ffffff;
}
.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #ffffff;
}
.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #ffffff;
}
.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ffffff;
}
.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px;
}
.popover,
.tooltip-inner {
  line-height: 1.5em;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  color: #555;
}
.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.popover.left>.arrow,
.popover.right>.arrow,
.popover.top>.arrow,
.popover.bottom>.arrow {
  border: none;
}
.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  border: 0;
}
.popover-header {
  background-color: #ffffff;
  border: none;
  padding: 15px 15px 5px;
  font-size: 1.125rem;
  margin: 0;
  color: #555;
}
.popover-body {
  padding: 10px 15px 15px;
  line-height: 1.4;
  color: #555;
}
.modal-dialog .modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
.modal-dialog .modal-content .card-signup {
  margin: 0;
}
.modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}
.modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}
.modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.modal-dialog .modal-footer {
  border-top: none;
  padding: 24px;
}
.modal-dialog .modal-footer.text-center {
  text-align: center;
}
.modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-dialog .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-dialog .modal-body+.modal-footer {
  padding-top: 0;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
.modal .modal-header .close {
  color: #999;
}
.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
  opacity: 1;
}
.modal .modal-header .close i {
  font-size: 16px;
}
.dropdown:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown.show>.dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-menu {
  display: none;
  padding: 0.3125rem 0;
  border: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu.showing {
  animation-name: bmd-dropdown-animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.open>.dropdown-menu,
.dropdown-menu.show {
  display: block;
  opacity: 1;
  
}
.dropdown-menu.hiding {
  display: block;
  opacity: 0;
  transform: scale(0);
}
.dropdown-menu[x-placement="bottom-start"] {
  transform-origin: 0 0;
}
.dropdown-menu[x-placement="bottom-end"] {
  transform-origin: 100% 0;
}
.dropdown-menu[x-placement="top-start"] {
  transform-origin: 0 100%;
}
.dropdown-menu[x-placement="top-end"] {
  transform-origin: 100% 100%;
}
.dropdown-menu .disabled>a {
  color: #777;
}
.dropdown-menu .disabled>a:focus,
.dropdown-menu .disabled>a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  color: #777;
}
.dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}
.dropdown-menu.dropdown-with-icons .dropdown-item .material-icons {
  vertical-align: middle;
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 1px;
  margin-right: 12px;
  opacity: .5;
}
.dropdown-menu .dropdown-item,
.dropdown-menu li>a {
  position: relative;
  width: auto;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  color: #333;
  font-weight: normal;
  text-decoration: none;
  font-size: .8125rem;
  border-radius: 0.125rem;
  margin: 0 0.3125rem;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  min-width: 7rem;
  padding: 0.625rem 1.25rem;
  overflow: hidden;
  line-height: 1.428571;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
@media (min-width: 768px) {
  .dropdown-menu .dropdown-item,
  .dropdown-menu li>a {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu a:active,
.dropdown-menu a.active,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-image: var(--theme-grad);
  color: #fff !important;
}
.dropdown-toggle.bmd-btn-icon::after,
.dropdown-toggle.bmd-btn-fab::after {
  display: none;
}
.dropdown-toggle.bmd-btn-icon~.dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-icon~.dropdown-menu.dropdown-menu-top-right,
.dropdown-toggle.bmd-btn-fab~.dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab~.dropdown-menu.dropdown-menu-top-right {
  bottom: 2rem;
}
.dropdown-toggle:after {
  will-change: transform;
  transition: transform 150ms linear;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown-toggle.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-toggle.bmd-btn-fab-sm~.dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab-sm~.dropdown-menu.dropdown-menu-top-right {
  bottom: 2.5rem;
}
.dropdown-toggle.bmd-btn-icon~.dropdown-menu {
  margin: 0;
}
.dropdown-header {
  font-size: 0.75rem;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  text-transform: none;
  color: #777;
  line-height: 1.428571;
  font-weight: inherit;
}
@keyframes bmd-dropdown-animation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dropdown-menu.bootstrap-datetimepicker-widget {
  opacity: 0;
  transform: scale(0);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 0 0;
  will-change: transform, opacity;
  top: 0;
}
.dropdown-menu.bootstrap-datetimepicker-widget.top {
  transform-origin: 0 100%;
}
.dropdown-menu.bootstrap-datetimepicker-widget.open {
  opacity: 1;
  transform: scale(1);
  top: 0;
}
.bmd-layout-drawer {
  position: absolute;
  z-index: 5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 100%;
  overflow: visible;
  overflow-y: auto;
  font-size: .875rem;
  transition: transform;
  will-change: transform;
  transform-style: preserve-3d;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-drawer>* {
  flex-shrink: 0;
}
.bmd-layout-drawer>header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
}
.bmd-layout-drawer>header .navbar-brand {
  padding-left: 1.5rem;
  font-size: 1.25rem;
}
.bmd-layout-drawer>.list-group {
  padding-top: 1rem;
}
.bmd-layout-drawer>.list-group .list-group-item {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  font-size: .8125rem;
  font-weight: 500;
}
.bmd-drawer-f-l>.bmd-layout-drawer {
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  transform: translateX(-250px);
}
.bmd-drawer-f-l>.bmd-layout-header,
.bmd-drawer-f-l>.bmd-layout-content {
  margin-left: 0;
}
.bmd-drawer-f-r>.bmd-layout-drawer {
  top: 0;
  right: 0;
  width: 240px;
  height: 100%;
  transform: translateX(250px);
}
.bmd-drawer-f-r>.bmd-layout-header,
.bmd-drawer-f-r>.bmd-layout-content {
  margin-right: 0;
}
.bmd-drawer-f-t>.bmd-layout-drawer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transform: translateY(-110px);
}
.bmd-drawer-f-t>.bmd-layout-content {
  margin-top: 0;
}
.bmd-drawer-f-b>.bmd-layout-drawer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transform: translateY(110px);
}
.bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l>.bmd-layout-header {
  width: calc(100% - 240px);
  margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l>.bmd-layout-drawer {
  transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l>.bmd-layout-content {
  margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r>.bmd-layout-header {
  width: calc(100% - 240px);
  margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r>.bmd-layout-drawer {
  transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r>.bmd-layout-content {
  margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t>.bmd-layout-header {
  margin-top: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t>.bmd-layout-drawer {
  transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b>.bmd-layout-drawer {
  transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-l>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-l>.bmd-layout-content {
  width: 100%;
  margin-left: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-r>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-r>.bmd-layout-content {
  width: 100%;
  margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-t>.bmd-layout-header {
  margin-top: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 576px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-l>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-l>.bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-r>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-r>.bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 768px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 991.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-l>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-l>.bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-r>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-r>.bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 992px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 1199.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-l>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-l>.bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-r>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-r>.bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 1199.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 1200px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-l>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-l>.bmd-layout-content {
  width: 100%;
  margin-left: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-r>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-r>.bmd-layout-content {
  width: 100%;
  margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-t>.bmd-layout-header {
  margin-top: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 0;
}
.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #DDDDDD;
  margin-bottom: 20px;
}
.progress .progress-bar {
  box-shadow: none;
}
.progress .progress-bar.progress-bar-primary {
  background: #9c27b0 !important;
}
.progress .progress-bar.progress-bar-info {
  background: #00bcd4;
}
.progress .progress-bar.progress-bar-success {
  background: #4caf50;
}
.progress .progress-bar.progress-bar-warning {
  background: #ff9800;
}
.progress .progress-bar.progress-bar-danger {
  background: #f44336;
}
.progress.progress-line-primary {
  background: rgba(156, 39, 176, 0.2);
}
.progress.progress-line-info {
  background: rgba(0, 188, 212, 0.2);
}
.progress.progress-line-success {
  background: rgba(76, 175, 80, 0.2);
}
.progress.progress-line-warning {
  background: rgba(255, 152, 0, 0.2);
}
.progress.progress-line-danger {
  background: rgba(244, 67, 54, 0.2);
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton,
.togglebutton label,
.togglebutton input,
.togglebutton .toggle {
  user-select: none;
}
.togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:hover,
.form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, .54);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.togglebutton label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.togglebutton label .toggle {
  text-align: left;
  margin-left: 5px;
}
.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled]+.toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2.5px;
  border: 1px solid rgba(0, 0, 0, .54);
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type=checkbox][disabled]+.toggle:after,
.togglebutton label input[type=checkbox][disabled]:checked+.toggle:after {
  background-color: #BDBDBD;
}
.togglebutton label input[type=checkbox]+.toggle:active:after,
.togglebutton label input[type=checkbox][disabled]+.toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type=checkbox]:checked+.toggle:after {
  left: 15px;
}
.togglebutton label input[type=checkbox]:checked+.toggle {
  background-color: rgba(156, 39, 176, 0.7);
}
.togglebutton label input[type=checkbox]:checked+.toggle:after {
  border-color: #9c27b0;
}
.togglebutton label input[type=checkbox]:checked+.toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}
.ripple {
  position: relative;
}
.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;
}
.ripple-container .ripple-decorator {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  opacity: 0;
  transform: scale(1);
  transform-origin: 50%;
}
.ripple-container .ripple-decorator.ripple-on {
  opacity: 0.1;
  transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
.ripple-container .ripple-decorator.ripple-out {
  opacity: 0;
  transition: opacity 0.1s linear 0s !important;
}
.bannerheadd{
  border-radius: 15px;
  max-width: 100%;
  min-width: 100%;
    text-align: center;
    margin: auto;
    width: 95% !important ;

}
/* .buernotir{
  background-color: #fff;
} */
.banerimge{
  border-radius: 15px;
}
.bannergrid{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  grid-gap: 15px;
  width: 100%;
  min-height: 100%;
}
/* .side_img_head1{
  max-height: 525px;
  min-height: 525px;
  object-fit: cover;
} */
.side_img_head1{
  max-height: 520px;
  min-height: 520px;
  object-fit: cover;
}
.side_img_head{
  max-height:250px !important;
  min-height: 250px !important;
  object-fit: cover;
}
.owl-theme .owl-nav{margin-top: 0 !important;}
.owl-theme .owl-nav.disabled + .owl-dots{
  position: absolute;
  bottom: 0;
 
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
}
.owl-theme .owl-dots .owl-dot span{
  width: 60px !important;
  height: 4px !important;    
  border-radius: 12px !important;
  padding: 5px 15px !important;
}
.page-header {
  margin-top: 80px;
  height: 60vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  /* background-image: linear-gradient(180deg,#9f0623 0,#bd0e37,#d91547); */
}
.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.page-header .carousel .carousel-indicators {
  bottom: 60px;
}
.page-header>.container {
  color: #ffffff;
}
.page-header .iframe-container iframe {
  width: 100%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.header-filter {
  position: relative;
  overflow: hidden;
  margin-top: 65px;
}
.header-filter:before,
.header-filter:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
}
.header-filter .container {
  z-index: 2;
  position: relative;
}
.clear-filter::before {
  background: none;
}
.purple-filter:after {
  background: rgba(101, 47, 142, 0.64);
  background: linear-gradient(45deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -moz-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -webkit-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
}
footer {
  padding: 0.9375rem 0;
  text-align: center;
  display: -webkit-flex;
  /* Safari */
  /* Safari 6.1+ */
  display: flex;
}
footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
footer ul.social_links li {
  display: inline-block;
  margin: 0 7px;
}
footer ul li .btn {
  margin: 0;
}
footer ul.links-horizontal:first-child a {
  padding-left: 0;
}
footer ul.links-horizontal:last-child a {
  padding-right: 0;
}
footer ul.links-vertical li {
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}
footer ul.links-vertical li a {
  padding: 5px;
}
footer .social-buttons a,
footer .social-buttons .btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
footer .footer-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  margin-left: -15px;
}
footer .footer-brand:hover,
footer .footer-brand:focus {
  color: #000000;
}
footer .copyright {
  padding: 15px 0;
}
footer .copyright .material-icons {
  font-size: 18px;
  position: relative;
  top: 3px;
}
footer .pull-center {
  display: inline-block;
  float: none;
}
.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  width: 100%;
  box-shadow: 0 0 4px 4px #eee9e9;
}
.card .card-category:not([class*="text-"]) {
  color: #999999;
}
.card .card-category {
  margin-top: 10px;
}
.card .card-category .material-icons {
  position: relative;
  top: 8px;
  line-height: 0;
}
.card .form-check {
  margin-top: 15px;
}
.card .card-title {
  margin-top: 0.625rem;
}
.card .card-title:last-child {
  margin-bottom: 0;
}
.card.no-shadow .card-header-image,
.card.no-shadow .card-header-image img {
  box-shadow: none !important;
}
.card .card-body,
.card .card-footer {
  padding: 0.9375rem 1.875rem;
}
.card .card-body+.card-footer {
  padding-top: 0rem;
  border: 0;
  border-radius: 6px;
}
.card .card-footer {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
}
.card .card-footer .author,
.card .card-footer .stats {
  display: inline-flex;
}
.card .card-footer .stats {
  color: #999999;
}
.card .card-footer .stats .material-icons {
  position: relative;
  top: 3px;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 18px;
}
.card.bmd-card-raised {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .card.bmd-card-flat {
    box-shadow: none;
  }
}
.card .card-header {
  border-radius: 3px;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border: 0;
  background: linear-gradient(60deg, #eee, #bdbdbd);
}
.card .card-header .title {
  color: #ffffff;
}
.card .card-header:not([class*="header-"]) {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header .nav-tabs {
  padding: 0;
}
.card .card-header.card-header-image {
  position: relative;
  padding: 0;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
}
.card .card-header.card-header-image a {
  display: block;
}
.card .card-header.card-header-image img {
  width: 100%;
  border-radius: 6px;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image .card-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #ffffff;
  font-size: 1.125rem;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}
.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity .45s;
  opacity: 0;
}
.card .card-header.card-header-image.no-shadow {
  box-shadow: none;
}
.card .card-header.card-header-image.no-shadow.shadow-normal {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image.no-shadow .colored-shadow {
  display: none !important;
}
.card .card-header-primary,
.card.bg-primary,
.card.card-rotate.bg-primary .front,
.card.card-rotate.bg-primary .back {
  background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}
.card .card-header-info,
.card.bg-info,
.card.card-rotate.bg-info .front,
.card.card-rotate.bg-info .back {
  background: linear-gradient(60deg, #26c6da, #0097a7);
}
.card .card-header-success,
.card.bg-success,
.card.card-rotate.bg-success .front,
.card.card-rotate.bg-success .back {
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}
.card .card-header-warning,
.card.bg-warning,
.card.card-rotate.bg-warning .front,
.card.card-rotate.bg-warning .back {
  background: linear-gradient(60deg, #ffa726, #f57c00);
}
.card .card-header-danger,
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
  background: linear-gradient(60deg, #ef5350, #d32f2f);
}
.card .card-header-rose,
.card.bg-rose,
.card.card-rotate.bg-rose .front,
.card.card-rotate.bg-rose .back {
  background: linear-gradient(60deg, #ec407a, #c2185b);
}
.card .card-header-primary {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.card .card-header-danger {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(244, 67, 54, 0.6);
}
.card .card-header-rose {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
}
.card .card-header-warning {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(255, 152, 0, 0.6);
}
.card .card-header-info {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 188, 212, 0.6);
}
.card .card-header-success {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(76, 175, 80, 0.6);
}
.card [class*="header-"],
.card[class*="bg-"] {
  color: #ffffff;
}
.card [class*="header-"] .card-title a,
.card [class*="header-"] .card-title,
.card [class*="header-"] .icon i,
.card[class*="bg-"] .card-title a,
.card[class*="bg-"] .card-title,
.card[class*="bg-"] .icon i {
  color: #ffffff;
}
.card [class*="header-"] .icon i,
.card[class*="bg-"] .icon i {
  border-color: rgba(255, 255, 255, 0.25);
}
.card [class*="header-"] .author a,
.card [class*="header-"] .stats,
.card [class*="header-"] .card-category,
.card [class*="header-"] .card-description,
.card[class*="bg-"] .author a,
.card[class*="bg-"] .stats,
.card[class*="bg-"] .card-category,
.card[class*="bg-"] .card-description {
  color: rgba(255, 255, 255, 0.8);
}
.card [class*="header-"] .author a:hover,
.card [class*="header-"] .author a:focus,
.card [class*="header-"] .author a:active,
.card[class*="bg-"] .author a:hover,
.card[class*="bg-"] .author a:focus,
.card[class*="bg-"] .author a:active {
  color: #ffffff;
}
.card .author .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.card .author a {
  color: #000000;
  text-decoration: none;
}
.card .author a .ripple-container {
  display: none;
}
.card .card-category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
}
.card .card-category-social .material-icons {
  position: relative;
  top: 5px;
}
.card[class*="bg-"],
.card[class*="bg-"] .card-body {
  border-radius: 6px;
}
.card[class*="bg-"] h1 small,
.card[class*="bg-"] h2 small,
.card[class*="bg-"] h3 small,
.card[class*="bg-"] .card-body h1 small,
.card[class*="bg-"] .card-body h2 small,
.card[class*="bg-"] .card-body h3 small {
  color: rgba(255, 255, 255, 0.8);
}
.card .card-stats {
  background: transparent;
  display: flex;
}
.card .card-stats .author,
.card .card-stats .stats {
  display: inline-flex;
}
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .card {
    display: inline-block;
  }
}
.card-carousel {
  overflow: hidden;
}
.card-login .card-header {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -40px;
  padding: 20px 0;
  margin-bottom: 15px;
}
.card-login .text-divider {
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
}
.card-login .card-body {
  padding: 0px 30px 0px 10px;
}
.card-login .form-check {
  padding-top: 27px;
}
.card-login .form-check label {
  margin-left: 18px;
}
.card-login .form-check .form-check-sign {
  padding-right: 27px;
}
.card-login .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}
.card-login .form {
  min-height: 420px;
}
.card-login .social-line {
  margin-top: 1rem;
  text-align: center;
  padding: 0;
}
.card-login .social-line .btn {
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}
.login-page .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #ffffff;
  z-index: 2;
}
.login-page .footer .copyright a {
  color: #ffffff;
}
.card-plain {
  background: transparent;
  box-shadow: none;
}
.card-plain .card-header:not(.card-avatar) {
  margin-left: 0;
  margin-right: 0;
}
.card-plain .card-body {
  padding-left: 5px;
  padding-right: 5px;
}
.card-plain .card-header-image {
  margin: 0 !important;
  border-radius: 6px;
}
.card-plain .card-header-image img {
  border-radius: 6px;
}
.card-plain .card-footer {
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
}
/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.17.37
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget a:hover {
  box-shadow: none !important;
}
.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 4px;
  width: 19em;
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 991px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
  
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before,
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  right: auto;
  left: 12px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top.open {
  margin-top: auto;
  margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: transparent;
  color: #9c27b0;
  box-shadow: none;
}
.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget a[data-action]:hover span {
  background-color: #eee;
  color: #9c27b0;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.125rem;
  margin: 0;
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  height: 38px;
  margin-right: 3px;
  padding: 0;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 3px;
  font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0px !important;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget table.table-condensed tr>td {
  text-align: center;
}
.bootstrap-datetimepicker-widget table td>div,
.bootstrap-datetimepicker-widget table th>div {
  text-align: center;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-weight: 500;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev span,
.bootstrap-datetimepicker-widget table th.next span {
  border-radius: 3px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span,
.bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td>div {
  border-radius: 3px;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td.cw>div {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #999;
}
.bootstrap-datetimepicker-widget table td.day>div {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  position: relative;
  z-index: -1;
  color: #000000;
  font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget table td.minute>div,
.bootstrap-datetimepicker-widget table td.hour>div {
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget table td.day:hover>div,
.bootstrap-datetimepicker-widget table td.hour:hover>div,
.bootstrap-datetimepicker-widget table td.minute:hover>div,
.bootstrap-datetimepicker-widget table td.second:hover>div {
  background: #eee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old>div,
.bootstrap-datetimepicker-widget table td.new>div {
  color: #999;
}
.bootstrap-datetimepicker-widget table td.today>div {
  position: relative;
}
.bootstrap-datetimepicker-widget table td.active>div,
.bootstrap-datetimepicker-widget table td.active:hover>div {
  background-color: #9c27b0;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}
.bootstrap-datetimepicker-widget table td.active.today:before>div {
  border-bottom-color: #ffffff;
}
.bootstrap-datetimepicker-widget table td.disabled>div,
.bootstrap-datetimepicker-widget table td.disabled:hover>div {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #9c27b0;
  color: #ffffff;
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #999;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
/*! nouislider - 14.1.1  */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-connect {
  height: 100%;
  width: 100%;
}
.noUi-origin {
  height: 10%;
  width: 10%;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}
.noUi-horizontal .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: 100%;
  top: -7px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #9c27b0;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
}
.noUi-vertical .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -8px;
  top: -10px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #9c27b0;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  right: -10px;
  left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #c8c8c8;
  border-radius: 4px;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
  transform: scale3d(1.5, 1.5, 1);
}
/* Handle stripes;
 */
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.homeofart{font-size: 28px;
  text-align: center;
  margin-bottom: 30px;}.btnlinksd{background: none;border: none;}
.onmousehovers:hover .nonehover{display: block;}
.nonehover{display: none;}
.regitersfd .dropdown-toggle:after{display: none;}
.reginonehover{
  position: absolute;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  background: #fff;
  border-radius: 5px;
  width: 200px;
  right: 0px;
  top: 52px;
 display: flex;
 transform: translate(10px, -250px);
  flex-direction: column;}
  .registerfrone{width: 100%;display: block;    margin-left: 14px !important;}

  .regitersfd:hover .reginonehover{transform: translate(10px ,10px) !important;}
  .megamenu {height: 100% !important;}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.slider {
  background: #c8c8c8;
}
.slider .noUi-connect {
  background-color: #9c27b0;
  border-radius: 4px;
}
.slider .noUi-handle {
  border-color: #9c27b0;
}
.slider.slider-info .noUi-connect {
  background-color: #00bcd4;
}
.slider.slider-info .noUi-handle {
  border-color: #00bcd4;
}
.slider.slider-success .noUi-connect {
  background-color: #4caf50;
}
.slider.slider-success .noUi-handle {
  border-color: #4caf50;
}
.slider.slider-warning .noUi-connect {
  background-color: #ff9800;
}
.slider.slider-warning .noUi-handle {
  border-color: #ff9800;
}
.slider.slider-danger .noUi-connect {
  background-color: #f44336;
}
.slider.slider-danger .noUi-handle {
  border-color: #f44336;
}
.slider.slider-rose .noUi-connect {
  background-color: #e91e63;
}
.slider.slider-rose .noUi-handle {
  border-color: #e91e63;
}
.index-page .section-components h6.description {
  margin-top: 5px;
  margin-bottom: 30px;
}
.index-page .page-header {
  /* height: 90vh; */
  overflow: hidden;
}
.index-page .brand {
  color: #ffffff;
  text-align: center;
}
.index-page .brand h1 {
  font-size: 4.2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.index-page .brand h3 {
  font-size: 1.313rem;
  max-width: 500px;
  margin: 10px auto 0;
}
.index-page h4.description {
  font-size: 1.5em;
}
.section {
  padding: 50px 0;
}
.profile-page .page-header {
  height: 380px;
  background-position: top center;
}
.profile-page .profile {
  text-align: center;
}
.profile-page .profile img {
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.profile-page .profile .name {
  margin-top: -80px;
}
.profile-page .follow {
  position: absolute;
  top: 0;
  right: 0;
}
.profile-page .description {
  margin: 1.071rem auto 0;
  max-width: 600px;
}
.profile-page .profile-tabs {
  margin-top: 4.284rem;
}
.profile-page .gallery {
  margin-top: 3.213rem;
  padding-bottom: 50px;
}
.profile-page .gallery img {
  width: 100%;
  margin-bottom: 2.142rem;
}
.profile-page .follow .btn-fab {
  margin-top: -28px;
}
.profile-page .card-background .content {
  padding: 30% 0;
  min-width: 160px;
}
.profile-page .work {
  padding: 40px 0px;
}
.profile-page .work .collections {
  margin-top: 20px;
}
.profile-page .work .stats ul>li {
  padding: 5px 0px;
  font-size: 1em;
}
.profile-page .work .stats ul>li b {
  font-size: 1.2em;
}
.profile-page .connections {
  padding: 40px 0px;
}
.profile-page .connections .card-profile {
  text-align: left;
}
.profile-page .tab-content .collections .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profile-page .tab-content .collections .card .card-body .badge {
  display: inline-table;
  margin: 0 auto;
}
.signup-page .page-header {
  min-height: 100vh;
  height: auto;
  display: inherit;
}
.signup-page .page-header .container {
  padding-top: 20vh;
}
.signup-page .card-signup {
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 100px;
  padding: 40px 0px;
}
.signup-page .info-horizontal {
  padding: 0px 0px 20px;
}
.signup-page .social .btn {
  margin: 5px;
}
.signup-page .social h4 {
  margin-top: 20px;
}
.signup-page .footer .container {
  padding: 0;
}
.signup-page .footer .copyright,
.signup-page .footer a {
  color: #fff;
}

.undefinedline{-webkit-appearance:none;appearance: none;border: none;background: none;    font-size: 18px;padding: 0;}
.undefinedline span{color: #03377f;}
.putforsale{font-size: 18px;color: #000;font-weight: 600;}
@media all and (max-width: 991px) {
  [class*="navbar-expand-"]>.container,
  [class*="navbar-expand-"]>.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show {
    right: 101% !important;
  }
  .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show .dropdown-item.open+.dropdown-menu,
  .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show .dropdown-item.open+.dropdown-menu.show {
    left: -165px !important;
  }
  .navbar .navbar-collapse .navbar-nav>li.button-container {
    padding: 15px;
  }
  .carousel .card .card-body {
    max-width: 340px;
    margin: 0 auto;
    min-height: 400px;
  }
  .navbar-collapse {
    position: fixed;
    display: block;
    top: 0px;
    height: 100vh;
    width: 230px;
    right: 0;
    margin-right: 0 !important;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0;
    padding-left: 0;
    max-height: none !important;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    display: block;
    content: "";
    z-index: 1;
  }
  .navbar-collapse .dropdown-toggle:after {
    position: absolute;
    right: 16px;
    margin-top: 8px;
  }
  .navbar-collapse .navbar-nav {
    position: relative;
    z-index: 3;
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #000000;
    margin: 5px 15px;
  }
  .navbar-collapse .navbar-nav .nav-item.active .nav-link,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:hover,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:focus,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:active {
    background-color: #ecedef;
    color: #000000;
  }
  .navbar-collapse .navbar-nav .nav-item.button-container .nav-link {
    margin: 15px;
  }
  .navbar-collapse .navbar-nav .nav-item:after {
    width: calc(100% - 30px);
    content: "";
    display: block;
    height: 1px;
    margin-left: 15px;
    background-color: #e5e5e5;
  }
  .navbar-collapse .navbar-nav .nav-item:last-child:after {
    display: none;
  }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .navbar-translate {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .navbar .dropdown .dropdown-menu {
    display: none;
  }
  .navbar .dropdown-menu .dropdown-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .navbar .dropdown.show .dropdown-menu,
  .navbar .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    padding-bottom: 15px;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transform: none !important;
    width: auto;
    margin-bottom: 15px;
    padding-top: 0;
    height: 300px;
    animation: none;
    opacity: 1;
    overflow-y: scroll;
  }
  .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
    background-color: #ffffff;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 1029;
    overflow-x: hidden;
  }
  #navbar .navbar-collapse,
  #navigation .navbar-collapse {
    display: none !important;
  }
}
@media all and (min-width: 991px) {
  .navbar .navbar-nav {
    align-items: center;
  }
  .navbar .navbar-nav .button-container {
    margin-left: 0.1875px;
  }
  .contactus-2 .card-contact {
    margin: 80px 0 80px 150px;
  }
  .index-page .fileinput-new.thumbnail.img-circle {
    margin-top: 50px;
  }
}
@media screen and (max-width: 991px) {
  .presentation-page .section-components .components-macbook {
    max-width: 850px !important;
    max-height: 480px !important;
    margin-top: 12vh;
    left: -12px;
  }
  .footergrid{grid-template-columns: repeat(1,1fr) !important;}
  .footfour{margin-left: 0 !important; }
  .foote1{order: 1;}
  .presentation-page .section-components .coloured-card-img,
  .presentation-page .section-components .table-img {
    display: none;
  }
  .presentation-page .section-components .social-img {
    left: 47%;
    top: 37%;
  }
  .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }
  .presentation-page .section-components .share-btn-img {
    top: 12%;
  }
  .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
    left: 65%;
  }
  .presentation-page .section-content .area-img {
    max-width: 130px;
    max-height: 170px;
  }
  .presentation-page .section-content .info-img {
    max-width: 170px;
    max-height: 120px;
  }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg);
    }
    45% {
      top: 6px;
      transform: rotate(145deg);
    }
    75% {
      transform: rotate(130deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg);
    }
    75% {
      -webkit-transform: rotate(130deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
    }
  }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg);
    }
    75% {
      -moz-transform: rotate(130deg);
    }
    100% {
      -moz-transform: rotate(135deg);
    }
  }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg);
    }
    45% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      top: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg);
    }
    45% {
      -webkit-transform: rotate(-10deg);
    }
    75% {
      -webkit-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg);
    }
    45% {
      -moz-transform: rotate(-10deg);
    }
    75% {
      -moz-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -moz-transform: rotate(0);
    }
  }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      transform: rotate(-145deg);
    }
    75% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(-135deg);
    }
  }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg);
    }
    75% {
      -webkit-transform: rotate(-130deg);
    }
    100% {
      -webkit-transform: rotate(-135deg);
    }
  }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg);
    }
    75% {
      -moz-transform: rotate(-130deg);
    }
    100% {
      -moz-transform: rotate(-135deg);
    }
  }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg);
    }
    45% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg);
    }
    45% {
      -webkit-transform: rotate(10deg);
    }
    75% {
      -webkit-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg);
    }
    45% {
      -moz-transform: rotate(10deg);
    }
    75% {
      -moz-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0);
    }
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(2) {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(3) {
    opacity: 1;
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(4) {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(2) {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(3) {
    opacity: 0;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(4) {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}
.img_overlay {
  position: relative;
}
.hotcollect_body {
  border-radius: 10px;
  box-shadow: 0 0 9px 4px #0000000a;
  padding: 20px 0;
  position: relative;
}
.profilehot{
  border-radius: 100%;
 margin: auto;
  min-width: 80px;
  max-width: 80px;
  position: relative;
  background: #fff;
}
.profilehot img{
  min-width: 80px;
 position: absolute;
 max-height:80px;
  min-height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  top: -50px;
    left: 0;
    right: 0;
}
.gridtemplate {
  display: grid;
  grid-template-columns: repeat(5 , 1fr);
  margin-top: 30px;
}
.orderflex {
  display: flex;
  align-items: center;
}
.accordian_para{
  text-align: center;
}

.felxfo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 !important;
}
.dayone{
  color: #000 !important;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
}
.select_btn{
  text-align: center !important;
  flex-grow: 0 !important;
}
/* .felxfo {
  display: flex;
  flex-direction: column;
} */
.dayrwwoo{
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  background: -webkit-linear-gradient(#9533d0, #6b33fa);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.orderflex img {
  width: 50px !important;
  height: 50px;
  margin-left: 0px;
  margin-right: 20px;
}
.yachiclub {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

.clearbtn{
 
 
  font-size: 13px;
  font-weight: 900;


  cursor: pointer;
}
input[type="number"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
          border-radius: 5px;
          border: 1px solid #000;
          padding: 10px;
          width: 90% !important;
}

.applybtn span{

  border-radius: 40px;
  color: #7a33eb !important;
  
}
.optionbtn{
  display: flex;
  justify-content: space-around;
}
.explore_btn{
  font-size: 16px;
  /* border: 1px solid #00000030; */
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 500;
  font-family:Poppins;
}
.owl-theme .owl-nav [class*='owl-']{
  margin: 18px !important;
}
.hotcollectimgsoruce{display: flex;align-items: center;justify-content: center;min-height: 150px;max-width:150px;overflow: hidden;margin: 0 auto;}
.hotcollectimgsoruce img{object-fit: contain;}
.explorebg p{font-family:Poppins;}
.hotcollect_body h2{
  text-align: center;
  color: #000;
  font-size: 16px;
  padding: 20px 10px;

  font-weight: 500;
}
.hotcollect_body p{
  text-align: center;
  color: #9d9999;
  font-size: 16px;
  
  font-weight: 500;
}
.auctions{display: grid;grid-template-columns: repeat(4,1fr);gap: 70px;}
.timers p{color: #fff;font-weight: 600;font-size: 16px;}
.timers{
  position: absolute;z-index: 9;    top: 22px;
  right: 18px;

}
.walletconnte{background-color: transparent !important;box-shadow: none !important;}
.timers p .hourds{color: #fff;font-weight: 400;font-size: 16px;background-color: #245278;text-align: center;border-radius: 5px;margin-left: 5px;
  margin-right: 5px;width: 30% !important;padding: 5px 10px;}
.title_text_white,.topcreators h2,.liveauction h2{
  text-align: center;
    font-size: 45px;
    font-weight: 800;
  color: #245278;
}
.topgrid{display: grid;grid-template-columns: repeat(4,1fr);gap: 50px;}
.atcs{display: grid;grid-template-columns: repeat(3,1fr) !important;gap: 20px;}
.topgridban {display: flex;background-color: #dcf2fc;padding: 10px;align-items: center;gap: 20px;border-radius: 5px;position: relative;}
.atcs .topgridban {display: flex;background-color: #dcf2fc;padding: 40px 20px 20px 20px;align-items: center;gap: 20px;border-radius: 5px;position: relative;}
.topgridban img{height: 50px;width: 50px;border-radius: 5px;}
.topgridban h3{font-size: 20px;font-weight: 500;margin: 0;}
.topgridban h5{font-size: 12px;font-weight: 500;color: #444;position: absolute;top: 0;
  right: 11px;}
  
.activityhead{font-size: 16px !important;font-weight: 500;margin: 0;color: #000;}
.activitypara{font-size: 14px !important;font-weight: 500;margin: 0;color: #444 !important;width: 90%;}
.activitypara span{font-size: 14px !important;font-weight: 500;margin: 0;color: #000;}
.topgridban p{font-size: 18px;font-weight: 500;margin: 0;color: #3ba6ff;text-align: left;}
section{background-color: #f8fbfd;}
.main_navbar li a.active{color: #000;}
@media screen and (max-width: 767px) {
  .presentation-page .section-components .components-macbook {
    max-width: 350px !important;
    max-height: 250px !important;
    margin-top: 12vh;
    left: -12px;
  }
  
  .banner_head{background-size: 100%;}
  .hotbid_section .accordian_para{top:30px;}
  .bannergrid{
    grid-template-columns: repeat(2 , 1fr);
  }
  .presentation-page .section-components .coloured-card-img,
  .presentation-page .section-components .table-img {
    display: none;
  }
  .presentation-page .section-components .social-img {
    left: -7%;
    top: 37%;
  }
  .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }
  .presentation-page .section-components .share-btn-img {
    top: 7%;
  }
  .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
  }
  .card.card-form-horizontal .input-group {
    padding-bottom: 10px;
  }
  .presentation-page #cd-vertical-nav,
  .index-page #cd-vertical-nav,
  .section-page #cd-vertical-nav {
    display: none;
  }
  .index-page .cd-section .tim-typo .tim-note {
    width: 60px;
  }
  .login-page .page-header>.container {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 400px) {
  .cd-vertical-nav {
    display: none !important;
  }
}
@media screen and (min-width: 767px) {
  .bd-docs .bd-example .card.card-profile .card-header-image {
    max-height: 100% !important;
  }
  .card-profile .card-header-image {
    max-height: 215px;
  }
}
@media screen and (max-width: 991px) {
  .menu-on-left .page-header,
  .menu-on-left .main,
  .menu-on-left .navbar>div {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  
  .atcs{display: grid;grid-template-columns: repeat(2,1fr) !important;gap: 20px;}
  .menu-on-left .navbar .collapse {
    left: 0;
    top: -25px;
    right: auto;
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .menu-on-left .navbar .navbar-translate .navbar-brand {
    position: absolute;
    right: 0;
    top: -15px;
  }
  .nav-open .menu-on-left .page-header,
  .nav-open .menu-on-left .main {
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
  }
  .nav-open .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .menu-on-left .navbar>div .navbar-translate {
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
@media (min-width: 575px) and (max-width: 991px) {
  .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(-255px, 0, 0);
    -moz-transform: translate3d(-255px, 0, 0);
    -o-transform: translate3d(-255px, 0, 0);
    -ms-transform: translate3d(-255px, 0, 0);
    transform: translate3d(-255px, 0, 0);
  }
  
  .nav-open .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform: translate3d(-25px, 0, 0);
    -o-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    width: 245px;
  }
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #03377f;
}


/* Home Page */
.search_inp_group {
  position: relative;
}
.search_inp::placeholder {
  color: #aca4a1;
}

.search_inp {
    position: relative;
    flex: 1 1 auto;
    
    margin-bottom: 0;
   
    height: 40px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 15px;
    padding-left: 38px;
    background-color: #fcfafa;
}
.search_inp:hover,
.search_inp:focus {
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 11%);
}
.search_inp_group_append {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 20;
  color: #787878;
  cursor: pointer;
}
.main_navbar {
  display: flex;
  align-items: center;
}
.main_navbar li a {
  color: #03377f;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400 !important;
  font-family:Poppins;
}
.main_navbar li a:hover {
  color: #26b6d4;
  background: transparent;
  transition:1s;
}
.main_navbar li a:focus{color: #26b6d4; background: transparent;}
.menu_dropdown button {
  background-color: transparent !important;
  box-shadow: unset;
  color: #454241;
  font-size: 14px;
  text-transform: capitalize;
  padding: 0.9375rem;
  font-weight: 600;
}
.menu_dropdown button:hover,
.menu_dropdown button:focus {
  background-color: transparent !important;
  box-shadow: unset;
  color: #a21530;
}
button.create_btn {
  background-color: #03377f;
  color:#fff !important;
  border-radius: 5px;
  font-family:Poppins;
  height: 40px;
  padding: 8px 15px;
  transition: 0.2s all;
border: 1px solid #03377f;
  font-weight: 400 !important;
  text-transform:capitalize;
  font-size:14px;
}
button.create_btn:hover {
  background-image: linear-gradient(90deg,transparent 0,transparent);
  color:#03377f !important;
  font-weight: 600;
  border: 1px solid #03377f;
}
#spinBut button.create_btn {
width: 20%;
}
.box-dot{
  font-size: 25px;
    color: #bebcbc;
    cursor: pointer;
}
.main_navbar li .create_btn a {
  color: #fff;
}
button.createbtn_color {
  background-color: #26b6d4;
  border-radius: 4px;
border: 1px solid #26b6d4;
  padding: 8px 20px;
}
button.createbtn_color:hover {
  background-color: initial;
  color: #26b6d4!important;
  border: 1px solid #26b6d4;
  box-shadow: none;
}
button.create_btn, button.createbtn_color {
  color: #fff!important;
  font-family: Poppins;
  height: unset!important;
  font-weight: 400!important;
  text-transform: capitalize;
  font-size: 14px;
}
.menu_icons {
  width: 22px !important;
  height: 22px !important;
}
.page-header h1 {
  font-size: 44px;
  font-weight: 600;
  margin: 0;
}
.page-header p {
  color: #090909;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.page-header h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
button.banner_btn {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  border: 2px solid #dc8096;
    background: transparent;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 50px;
    /* margin-bottom: 40px; */
}
button.banner_btn:hover
{
  background: #fff;
  color:#a21530;
}
.top_panel {
  background: #fff;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
  border-radius: 20px;
  text-align: center;
  padding: 20px 10px;
}
.top_panel_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 12px;
}
.top_panel h2 {
  color: #000;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}
.top_panel h3 {
  color: #969696;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
.top_panel_div_single p {
  font-size: 14px;
  color: #969696;
  font-weight: 600;
}
.top_panel_div_single {
  margin-bottom: 5px;
}
section.top_section {
  margin-top: -60px;
  /* background-color: #fff; */
}
.home_header{
  background-color: #fff;

  position: relative;
  z-index: 9;

}

.brand_section h2 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
}
.recent_collections {
  background-color: #fde3e2;
}

.wallet_section h2
 {
  color: #fff !important;
}
.recent_collections h2,
.explore_section h2,.wallet_section h2
 {
  color: #000;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-top: 2rem !important;
}
figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}
figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}
figure a {
  color: #a21530;
  font-size: 18px;
  text-decoration: none;
}
figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: #fff;
  padding: 0 5px;
  justify-self: start;
  margin: 2px;
  opacity: 0;
  transition: 0.2s all;
}
.recrent_collections_img {
  column-count: 4;
  column-gap: 10px;
  transition: 0.2s all;
}
.recrent_collections_img img:hover {
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 50%);
}
.recrent_collections_img figure:hover figcaption {
  opacity: 1;
}
.recrent_collections_img img {
  max-width: 100%;
  display: block;
}
.masonry {
  -webkit-column-count: 4;
  -moz-column-count:4;
  column-count: 4;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  margin: 1.5em;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: .85em;
}
.masonry .item_no_boxshadow {
  box-shadow: unset;

}
.masonry_new .item h2{
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin: 5px 0 8px;
}
.masonry_new .item h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.masonry .item h4,.masonry_new .item h4 {
  font-size: 18px;
  margin: 0;
}
.masonry .item h4 span,
.masonry .item h3 span ,
.masonry_new .item h4 span,
.masonry_new .item h3 span{
  color: #000;
}
.masonry_likes i {
  color: #d3d3d3;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  top: 3px;
}
.masonry_likes i.active {
  color: #c5090d;
}

.masonry .item .fa-ellipsis-h {
  color: #d2d2d2;
}
.masonry_tab_nav {
  display: block;
  /* overflow-x: auto; */
}
.masonry_tab {
  border-radius: 0;
  white-space: nowrap;
  display: inline-block;
  min-width: 100%;
}


.masonry_tab_nav a{box-shadow: none !important;
background-color: transparent !important;
cursor: pointer;
position: relative;padding-bottom: 0 !important;}
.masonry_tab_nav a.active::before{
  content: "";
  position: absolute;
  background: #245278;
  margin: 0;
  padding: 0;
  
  border-bottom: 1px solid #245278;
  height: 1px;
  left: 5px;
  top: auto;
  bottom: 0px;
  width: 100%;
  transition: all 0.5s ease 0s;
  
}
.masonry_tab_nav a:hover::before{
  width: 100%;
  border-bottom: 1px solid #245278 ;
}
.masonry_tab_nav a::before {
  content: "";
  position: absolute;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid transparent;
  height: 1px;
  left: 5px;
  top: auto;
  bottom: 0px;
  width: 0%;
  transition: all 0.5s ease 0s;
}
.nav-link {
  background-color: white !important;
}
.nav-tabs.masonry_tab .nav-link:hover, 
.nav-tabs.masonry_tab .nav-link:focus {
  border: transparent;
}
.explore_tab_content {
  margin-top: 30px;
}
.wallet_section {
 background: var(--theme-grad) !important;
 color:white
}
.wallet_section p {
  font-size: 14px;
  color: #fff;
}
.footer_links {
  padding: 0;
  text-align: left;
}
footer {
background-color: #fff;
  height: 100%;
  padding-top: 70px !important;
}
.footflex
{
  display: flex;
  margin-top: 20px;
  margin-bottom: 80px;
  justify-content: space-between;
}
.footflex ul li{
  display: block;
 
}
.footflex ul li a{
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.footflex ul li a:hover{
  color: gray;
}
footer h4 {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
  margin: 0;
}
.notowe{
display: flex !important;
width: 25%;
justify-content: space-between;
}
.footer_links li a {
  position: relative;
  padding: 10px 20px 0 0;
  font-size: 14px;
  text-transform: capitalize;
  color: #969696;
  font-weight: 500;
}
/* .footer_links li a::after {
  content: "";
  background: #969696;
  position: absolute;
  width: 1px;
  height: 13px;
  right: 9px;
  top: 13px;
} */
.footer_links li:last-child a::after {
  display: none;
}
.social_links {
  padding: 0;
  margin: 0;
}
.newsletter_grp {
  position: relative;
  text-align: left;
  margin-top: 10px
}
.newsletter_grp input {
  height: 50px;
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  border: transparent;
  padding-left: 10px;
}
.newsletter_grp_append {
  position: absolute;
  top: 0px;
  right: 0;
}
.newsletter_grp_append button {
  /* background-color: #a30726; */
  background-image: -webkit-linear-gradient(-11deg,#010001,#333333);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 14px;
  border-radius: 0 10px 10px 0;
  width: 162px;
  /* border: 1px solid #9933cc; */
  box-shadow: none;
}
.newsletter_grp_append button:hover
{
  background-image: none;
  /* border: 1px solid #9933cc; */
  color: #fff;
  background-color: #9933cc;
  padding: 14px;

}
.social_links li a {
  color: #969696;
}
.social_links li a:hover,
.footer_links li a:hover {
  color: #a21530;
}
.copyright_txt {
  font-size: 14px;
  color: hsl(0, 0%, 51%);
  /* margin-left: 50px; */
}
.home_header header {
    padding: 15px 0px !important;
    background-color: #fff !important;
    border-bottom: 1px solid #0000002b !important;
}
header{
  height: 55px;
  box-shadow: 2px 2px #e2e2e26e;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
.slick-arrow.slick-prev,
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-prev:focus {
  background: url("../src/assets/images/prev_icon.png");
  width: 14px;
  height: 27px;
  left: 0;
}
.slick-arrow.slick-next,
.slick-arrow.slick-next:hover,
.slick-arrow.slick-next:focus {
  background: url("../src/assets/images/next_icon.png");
  width: 14px;
  height: 27px;
  right: 0;
}
button.hamburger_icon {
  color: #03377f;
}
.headercntbtn {
  margin-top: 10px !important;
}
/* Media Queries */
@media only screen and (min-width: 1279px){
 
  .headercntbtn {
    padding: 0 !important;
    margin-top: 0 !important;
  }
 
  .main_navbar li a .button.create_btn {
    padding: 10px 30px !important;
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 1279px) {
  .search_inp{width: 200px;}
  .header-items{
    flex:1 !important
  }
  .reginonehover{left: 0;right: 0;}
  .reginonehover{transform: translate(0px ,0px) !important;opacity: 0;}
  .regitersfd:hover .reginonehover{opacity: 1 !important;transform: translate(0px ,0px) !important;}
  .main_navbar .megamenu .dropdown-menu{
    overflow-y: scroll;
    min-height: 500px !important;
    max-height: 500px !important;
    top: 0 !important;
    left: 1px !important;
  }
  .topgridban h3,.subxcribe{font-size: 20px;font-weight: 500;margin: 0;}
  .banner_head h2 span,.home_header h2{font-size: 60px !important;}
  .subxcribe{font-size: 16px !important; margin-top: 10px;}
.banercontentDet h2{font-size: 13px !important; }
.banercontent{width: 100% !important;}
.banercontentDet p{text-align: left;}



  .main_navbar li a .button.create_btn {
    padding: 10px 30px !important;
    
  }
  #menu-list
  {
    left: -43px;
    position: absolute;
  }
  .main_navbar {
    display: block;
  }
  .main_navbar li {
    float: none;
  }
  .main_navbar li::after {
    display: none;
  }
  .main_navbar li a {
    margin: 0;
    font-size: 14px;
    font-weight: 400 !important;
  }
  
  .menu_dropdown button
{
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family:Poppins;
  margin:0px;
}
.main_navbar li p{text-transform:capitalize !important}
}
.topgridban h3,.auctioncards h3,.subxcribe{font-size: 16px !important;}
@media only screen and (max-width: 1199px) {
  .page-header h1 {
    font-size: 36px;
  }
  
  body .activity .nto_scrol_div{top:0px !important;}
  .page-header h3 {
    font-size: 20px;
  }
  .page-header p {
    font-size: 15px;
  }
  .recent_collections h2, 
  .explore_section h2, 
  .wallet_section h2 {
    font-size: 36px;
  }
  footer .footer_right,
  footer .footer_left {
    float: none !important;
  }  
}
@media only screen and (max-width: 959px) {
  section.top_section {
    margin-top: 50px;
  }
  .top_panel {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
 .banner_sec_row h1  /*, .banner_sec_row p */
  {
    text-align: center !important;
  }
  
  .atcs{display: grid;grid-template-columns: repeat(1,1fr) !important;gap: 20px;}
  .customDrop {
    display: block !important;
    text-align: center;
}
  #menu-list {left: -109px;position: absolute;}
  .page-header h1 {
    font-size: 24px;
  }
  .recent_collections h2, .explore_section h2 {
    font-size: 24px;
  }
  .topcreators .explorerecenlyadded .ml-2 {
    font-size: 20px !important;
}
  .nav-tabs.masonry_tab .nav-link {
    font-size: 15px;
    padding: 8px 20px;
  }
}
@media only screen and (max-width: 599px) {
  .masonry {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px){
  .masonry {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px){
  .masonry {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
  }
  .topgrid{grid-template-columns: repeat(3,1fr);}
 
}
@media only screen and (min-width: 1201px) {
  .masonry {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
  }
  
}
.dropdown_header_ul li:hover
{
background-color: transparent !important;
color:#000 !important;
box-shadow: 0 0px 0px 0px rgb(156 39 176 / 28%), 0 0px 0px 0px rgb(0 0 0 / 12%), 0 0px 0px 0px rgb(156 39 176 / 20%) !important;
}
.dropdown_header_ul li a:hover{
  color:#000 !important;
}
/* .dropdown_header_ul li:hover a{
  color:#a21530 !important;
} */

.explore_section
{
  background-color: #fff;
}
.item_bg_red,.item_bg_red .card
{
  background-color: #a21530;
  color:#fff;
  border-radius: 15px;
  text-align: center;
}
.no_shadow_item_head
{
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
}
.no_shadow_item_p
{
  font-size: 22px;
  font-weight: 600;
}
.menu_dropdown button
{
  color: #454241 !important;
  font-size: 14px;
}

/* Innerpages  */
.inner_header header {
  background-color: #fff !important;
  border-bottom: 1px solid #0000002b !important;
}
.inner_pageheader {
  min-height: calc(100vh - 387px);
  padding-top: 150px;
background-size: contain;
  padding-bottom: 50px;
  background-image: url('../src/assets/images/headbanner.png');
  /* background: white; */
}
.explore{
  background-image: url(../src/assets/images/headbanner.png);
  
  background-size: contain;
  height: 100%;
}
.inner_title {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  margin: 0;
  line-height: 30px;
}
.arrow_icon {  
  margin-right: 20px;
}
.create_box_panel {
  display: flex;
  margin-top: 20px;
}
.create_box {
  border: 1px solid #03377f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #a21530;

  background-image: var(--theme-grad);
  border-radius: 15px;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  width: 25%;
  margin-right: 20px;
  padding: 10px;
}
#login .Login_detforgto{    color: #03377f !important;
  font-size: 12px !important;transition: 0.5s ease;
  cursor: pointer;
  display: block;
  text-align: right;}
  #login .Login_detforgto:hover{color: #26b6d4 !important;}
.items_profile{
  object-fit: contain;
  min-height: 150px !important;
  max-height: 150px !important;
}
.Login_det:hover{color: #26b6d4 !important;}
.putonsale_sec{margin: 10px 0;}
.create_box h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.create_box12 h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.create_para {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
@media only screen and (max-width: 767px)
{
  .row.banner_sec_row {
    text-align: center;
}
.logo_span{right: 0 !important;
  width: 160px !important;margin: 0 auto;}
  .create_box {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0px;
}
.create_box12 {
  width: 100%;
  margin-bottom: 15px;
  margin-right: 0px;
}
.inner_title
{
  font-size: 30px;
}
.create_box_panel
{
  flex-wrap:wrap;
}
}


.faq_panel .accordian_head h2 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
.faq_panel .MuiAccordion-root {
  background: transparent;
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 !important;
}
.faq_panel .MuiAccordionSummary-root {
  min-height: unset !important;
}
.faq_panel .MuiAccordion-root:before {
  display: none;
}
.faq_panel .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}


.single_collectible .item h4 span, 
.single_collectible .item h3 span {
  color: #000;
}
.single_collectible .item h4 {
  font-size: 18px;
  margin: 0;
}
.primary_label {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0
}
.form_note {
  font-size: 14px;
  color: #9C9A9A;
  line-height: 20px;
}
.file_btn {
  position: relative;
  overflow: hidden;
  display: flex;
    align-items: center;
    justify-content: center;
}
input.inp_file {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: 1 !important;
}
.primary_btn,
button.primary_btn {
  border: 2px solid #245278;
  background-color: #245278;
  background-image: var(--theme-grad);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 30px;
  transition: 0.2s all;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  font-family:Poppins;
}
.primary_btn:hover,
button.primary_btn:hover

{
  border: 2px solid #245278;
  background-color: transparent;
  background:transparent !important;
  color: #245278;
  text-transform: uppercase;
}
.dark_theme .primary_btn:hover,
.dark_theme button.primary_btn:hover{
  color:#fff ! important;
}
.word_braek_txt_new
{
  word-break: break-all;
}

button.primary_btn:disabled
{
  border: 2px solid #245278;
  background-color: transparent;
  background:transparent !important;
  color: #245278 !important;
  text-transform: uppercase;
}
.toggle_custom.switch input { 
  display:none;
}
.toggle_custom.switch {
  display:inline-block;
  width:60px;
  height:30px;
  transform:translateY(50%);
  position:relative;
}
.toggle_custom .slider {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  border-radius:30px;
  /* box-shadow: 0 0 0 2px #6833fd, 0 0 4px #9733ce; */
  cursor:pointer;
  border:4px solid transparent;
  overflow:hidden;
  transition:.4s;
  background: #03377f;
}
.toggle_custom .slider:before {
  position:absolute;
  content:"";
  width:100%;
  height:100%;
  background:#fff;
  border-radius:30px;
  transform:translateX(-30px);
  transition:.4s;
}
.toggle_model .slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: .4s;
}
.toggle_model.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  transform: translateY(50%);
  position: relative;
}
.toggle_model input:checked + .slider:before {
  transform:translateX(30px);
  background:#fff;
  width: 45% !important;

}
.toggle_model input:checked + .slider {
  box-shadow: 0 0 0 2px #f05a2e, 0 0 2px #f05a2e;
  background: #f05a2e;
}
.bordbot{
  border-bottom: 1px solid #000;
}
.toggle_custom input:checked + .slider:before {
  transform:translateX(30px);
  background:#fff;
}
.toggle_custom input:checked + .slider {
  box-shadow: 0 0 0 2px #f05a2e, 0 0 2px #f05a2e;
  background: #f05a2e;
}
#fixedprice p,.create_sing_bxinactive p{color: #fff;}
.form-control, .is-focused .form-control {
  background-image:var(----theme-grad);
}
.primary_inp {
  font-size: 16px;
  color: #969696;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  margin: 5;
  top: 4px;
  right: 10px;
  color: #969696;
}

.img_full_w
{
  width: 100%;
}
.bg_red_1
{
  /* background-image: var(--theme-grad) !important; */

    color: #000;
  padding-bottom: 45px;
  padding-top:45px;
  margin-top:-50px;
  margin-bottom: 35px;
}
.bg_red_1 .inner_title{
  color:#245278 ! important;
  text-align: center;
  font-weight: 600;
}
.dark_theme .bg_red_1 .inner_title{
  color:#fff ! important;
}
/* .makeStyles-container-1
{
  padding-top: 40px;
} */
.bg_red_1 .inner_title
{
color:#fff;
}
.select1{
    border-top-width: 0px !important;
    border-right-width: 0px  !important;
    border-left-width: 0px !important;
    background-color: #fff !important;
    border-bottom-width: 0px !important;
    border-bottom-color: #e89191 !important;
    border-radius: 0px;
    background: #fff !important;
     box-shadow:0px 0px 0px #fff !important;
     color: #000 !important;
        /* height: 33px !important; */
    /* background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px); */
  /* background-image: linear-gradient(to top, #eeacab 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #a2153066 1px, rgba(210, 210, 210, 0) 1px)  !important;
  height: 36px  !important;
  color:#aca4a1 !important; */
}
.select1 div
{
/* background-color: #fff !important; */
  /* background: #fff !important; */
  box-shadow:0px 0px 0px #fff !important;
  border-top-width: 0px !important;
    border-right-width: 0px  !important;
    border-left-width: 0px !important;
    /* background-color: #fff !important; */
    border-bottom-width: 1px !important;
    border-bottom-color: transparent !important;
    border-radius: 0px;
    color: #787878 !important;
    /* height: 33px !important; */
}
.typecontrol{
  border-top-width: 0px !important;
    border-right-width: 0px  !important;
    border-left-width: 0px !important;
    background-color: #fff !important;
    border-bottom-width: 0px !important;
    border-bottom-color: #000 !important;
    border-radius: 0px;
padding: 0;
    background: #fff !important;
     box-shadow:0px 0px 0px #fff !important;
     color: #000 !important;
}

.react-select__control{background-color: transparent !important;}
.react-select__menu,.react-select__menu-list,.react-select__option{background-color: #fff  !important;background: #fff !important;}
.typecontrol3
 {
  background: none !important;
  background-color: transparent !important;}
.typecontrol div{
  /* background-color: #fff !important; */
  /* background: #fff0 !important; */
  box-shadow:0px 0px 0px #fff !important;
  border-top-width: 0px !important;
    border-right-width: 0px  !important;
    border-left-width: 0px !important;
    /* background-color: #fff !important; */
    border-bottom-width: 1px !important;
    border-bottom-color: #000 !important;
    border-radius: 0px;
    color: #787878 !important;
    /* height: 33px !important; */
}
.Mui-disabled {
  opacity: 0.4;
}
.primary_inp.selxet_app.symboldbo{border-bottom: 1px solid #000 !important;}
.dark_theme .select1{
  background-color:transparent ! important;
}
.css-1okebmr-indicatorSeparator{
  background:transparent !important;
}
.form-control.select1{
  background:transparent !important;
  border-bottom: 0px !important;
}
.select1 {position: absolute !important;right: 0 !important;width: 30% !important;bottom: 5px;z-index: 99;}
.networkdisn .select1 {position: absolute !important;right: 0 !important;width: 10% !important;bottom: 2px;}
.select1 div{
  background:transparent !important;
}
.select1 .css-26l3qy-menu{
  background:#fff !important;
}
.masonry .item img.imgfix, video.imgfix
{
  min-height:250px;
  max-height:250px;
  width: 100%;

}
.recent_collections img.imgfix ,video.imgfix{
  min-height:250px;
  max-height:250px;
  width: 100%;
  border-radius: 5px;

}

.primary_modal {
  z-index: 9999999999;
  background: #80808073;
}
.primary_modal .modal-content
{
background-color: #fff;
border-radius: 2px;
}
@media (min-width: 450px)
{
.primary_modal .modal-sm {
max-width: 400px;
margin: 0 auto;
}
}

.primary_modal .modal-dialog .modal-header {
display: block;
}
.primary_modal .modal-header .close {
float: none;
position: absolute;
color: #6f6f6f;
top: 21px;
right: 25px;
font-size: 30px;
}
.primary_modal h5.modal-title {
margin: 0;
font-size: 24px;
font-weight: 800;
color:#03377f;
max-width: 300px;
margin: 0 auto;
}
.primary_label_dark
{
color:#03377f;
font-size: 16px;
font-weight: 600;
margin:0;
}
.file_btn.btn_small
{
padding-top: 4px !important;
padding-bottom: 4px !important;
}
.small_btn
{
padding-top: 5px !important;
padding-bottom: 5px !important;
}
.hex {
position: relative;
max-width: 100%;
width: 85px;
}
.hex:before {
content: '';
display: block;
padding-bottom: 70%;
}
.hex img {
left: 50%;
position: relative;
top: 50%;
transform: translate(-50%,-50%);
width: 115%;
}
.hex__shape {
position: absolute;
backface-visibility: hidden;
left: 0;
height: 50px;
overflow: hidden;
top: 0;
transform: rotate(240deg);
width:50px;
border-radius: 50%;
}
.font_16 {
font-size: 16px;
}
.font_600 {
font-weight: 600;
}
.line_20 {
line-height: 20px;
}
input[type="file"].photo {
margin-top: 5px;
opacity: 0;
z-index: 999;
max-width: 100%;
}
.profile_edit_panel {
position: relative;
width: 135px;
margin: 0 auto;
}
.profile_edit_icon {
  position: absolute;
  bottom: -10px;
  z-index: 0;
  right: 40px;
  text-align: right;
  width: 50px;
}
.profile_edit_panel img{
  border-radius: 50%;
}
.profile_edit_icon i {
background: #03377f;
color: #fff;
width: 30px;
height: 30px;
border-radius: 40px;
display: flex;
align-items: center;
justify-content: center;
margin: 0 auto;
border: 3px solid #fff;
font-size: 12px;
cursor: pointer;
}
.primary_tab {
display: flex;
}
.proposal_panel {
display: flex;
align-items: center;
padding: 20px 0;
border-bottom: 1px solid #e7e7e7;
}
.proposal_panel_overall .proposal_panel:last-child {
border-bottom: none;
}
.proposal_panel_content {
margin-left: 30px;
}
.proposal_panel_content h3 {
color: #6c6d6d;
font-size: 18px;
font-weight: 600;
margin: 0;
}
.proposal_panel_content p {
color: #000;
font-size: 16px;
margin: 0;
font-weight: 600;
}
.proposal_panel_content p span {
color: #9a9696;
}
.proposal_panel .badge {
padding: 12px 25px;
font-size: 16px;
font-weight: 600;
width: 110px;
border-radius: 30px;
text-align: center;
text-transform: capitalize
}
.proposal_panel .badge_active {
background-color: #33b9f2;
}
.proposal_panel .badge_closed {
background-color: #e95e5e;
}
.proposal_panel .badge_pending {
background-color: #fcd917;
}
.form-control {
color: #000 !important;
font-weight: 600;
}
.text_brown
{
color:#555;
}
.text_desc_sm
{
font-size: 14px;
line-height: 18px;
}
.Login_det{color: #03377f !important;margin-left: 10px;}
@media only screen and (min-width:768px) and (max-width:991px)
{
  .banner_sec_row .white-box p {
    font-size: 15px !important;
  }
  .reginonehover{left: 0;right: 0;}
  .cat_title
  {
    font-size: 16px !important;
  }
  .ethactios {
    min-height: 250px !important;
    max-height: 250px !important;}
  .explore_enf{display: grid;grid-template-columns: repeat(3,1fr);gap: 10px;}
  .explore_enf .noti_ul button{margin: 0 !important;width: 100%;}
  .topgrid{grid-template-columns: repeat(3,1fr);}
.holder
{
width: unset !important;
min-width: unset !important;
}
.holder img
{
width: 100% !important;
min-width: 100% !important;
max-width: 100% !important;
}
.profile_edit_panel
{
max-width: 100%;
}
.flex_items_block
{
display: block !important;
}
}

@media only screen and (min-width:992px)
{
.holder
{
margin-left: 0px !important;
}
}
@media only screen and (max-width:1199px) and (min-width:992px)
{
  .d-flex.cont  {
    display: block !important;
  }
  .baner_image{width: 100% !important;}
  .notowe{
    width: 42%;
  }
  .footflex{
    margin-bottom: 100px;
  }
  footer h4 {
    font-size: 24px;}
    .footflex p{
      text-align: left;
    }
    .makeStyles-right-79 {
      width: 50% !important;
     
    }
    .new_des_footer{
      display: block !important;
    }
.primary_btn.small_btn
{
padding-left: 10px !important;
padding-right: 10px !important;
}
}
@media only screen and (min-width:1200px)
{
.profile_edit_panel
{
margin: unset !important;
}
}
.proposal_tab .nav-link
{
color:#000 !important;
font-size: 17px !important;
}
.proposal_tab .nav-link.active,.proposal_tab .nav-link:hover
{
color:#fff !important;
}
.btn_text
{
justify-content: space-between;
}
.inner_header header {
padding: 15px 0!important;
background-color: #fff!important;
}
@media only screen and (max-width:375px)
{
.proposal_btn
{
padding-left: 10px !important;
padding-right: 10px !important;
}

}
.search_inp_group input::placeholder,
.newsletter_grp input::placeholder
{
  height: 40px;
  line-height: 35px;
}
.error_text
{
  font-size: 14px;
  color: #aca4a1;
  margin-bottom: 0px;
}

.high{

    margin-top: -46px;
    margin-left: 50px;
}


.formCls .form-control{
height: 46px !important;
}

@media (min-width: 992px)
{
  button.banner_btn {
    margin-bottom: 40px;
}
}


/* LOADER CSS */
/* @keyframes loader-02 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.logo_ovelay {
  opacity: .5;
  min-height: 100vh;
  position: relative;
}
.spin_round {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  width: 150px;
  height: 150px;
  border-color: transparent currentcolor;
  border-style: solid;
  border-width: .2em;
  border-radius: 50%;
  -webkit-animation: loader-02 1s linear infinite;
  animation: loader-02 1s linear infinite;
  position: fixed;
  top: 50%;
  left: calc(50% - 75px);
  transform: translate(-50%,-50%);
  z-index: 1000;
  opacity: 1;
}
.logo_load {
  max-width: 80px;
  position: fixed;
  top: calc(50% + 75px);
  left: calc(50%);
  transform: translate(-50%,-50%);
  z-index: 1000;
} */


/* ITEMS CSS */
.items_bg
{
  min-height: 200px;
  margin-top: -38px;
  /* background: url("../src/assets/images/banner.jpg"); */
  /* background-size: cover;
  background-position: center; */
}

.items_header
{
padding-top: 100px !important;
}
.items_bg_pink
{
/* background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0); */
min-height: 335px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.item_prof
{
  position: relative;
}
.item_prof_panel
{
  position: absolute !important;
  top:-20px !important;
}
.item_prof input[type="file"] {
  max-width: 100%;
}
.address_text
{
  color:#32302d;
  font-size: 16px;
  font-weight: 700;
  word-break: break-all;
}
.bio_desc
{
  color:#6f6f6f;
  font-size: 16px;
  font-weight: 700;
}
.not_found_text
{
  color:#32302d;
  font-size: 30px;
  font-weight: 700;
}
.not_found_text_sub
{
  color:#714e4c !important;
  font-size: 16px;
  font-weight: 600;

}
.notes_fa
{
  padding-left: 15px;
  color:#03377f;
}

button.btn_outline_red, form button.create_btn {
  background-image: var(--theme-grad);
  color:#fff  !important;
  height: 40px;
  border-radius: 10px;
  padding: 5px 10px;
  transition: 0.2s all;
  border:2px solid #03377f ;
  text-transform: capitalize;
  border-radius: 8px;
}
button.btn_outline_red:hover {
 color: #03377f !important;
  background: white;
  border: 2px solid #03377f;
}
.btn_outline_cancel{
  background-image: var(--theme-grad);
  color:#fff  !important;
  height: 40px;
  border-radius: 10px;
  padding: 15px 30px !important;
  font-weight: 600 !important;
  transition: 0.2s all;
  border:2px solid #03377f !important;
  text-transform: capitalize !important;
  border-radius: 8px !important; 
}
.btn_outline_cancel:hover {
  color: #03377f !important;
   background: white;
   border: 2px solid #03377f;
 }
.bg_red_icon
{
background-color: #ff0099;
border-radius: 50%;
display: inline-flex;
align-items: center;
justify-content: center;
padding: 10px;
color:#fff;
margin-left: 15px;
}
.items_tab .nav-link{
  text-align: center;
  line-height: 20px;
}
.text-gray {
  color:gray
}
.banner-r img:hover, .hotcoll_lists img:hover, .item_inner_img img:hover{
  opacity: 0.8;
}
.img-border img:hover, .img_tick_div img:hover, .creators_details img:hover {
  opacity: 1;
}
.img_tick_div.tick-l .img_tick {
  position: absolute;
  right: auto;
  top: -5px;
  left: 0;
}
.items_tab .nav-link .tab_head
{
color:#000 !important;
font-size: 17px;
}
.items_tab .nav-link .tab_count
{
color:#245278 !important;
}
.items_tab .nav-link.active .tab_head
{
color:#fff !important;
}
.items_tab .nav-link.active .tab_count,
.items_tab .nav-link:hover .tab_count

{
color:#245278  !important;
}
.navtabs .items_tab
{
  align-items: center;
  /* justify-content: center; */
}
@media only screen and (max-width:1199px){
  h1.banner-head {
    font-size: 42px;
  }
  .banner-r .banner-r-img{
    margin: auto;
  }
  .banner-r img {
    /* height: 223px; */
    object-fit: contain;
}
  .banner-r {
    display: block;
    text-align: center;
    margin-top: 30px;
  }
  .items_tab_outer .primary_tab
  {
    display: block !important;
  }
}
.flex_body
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.follow_media
{
  align-items: center;
padding: 10px;
}
.media_num
{
  color:#9a9696;
  font-size: 24px;
    font-weight: 600;
    line-height: 20px;
  margin-bottom: 0px;
}
.info p{font-size: 16px !important;}
.media_text {
  color:#000;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.following_row .single_collectible .item{
border-radius: 5px !important;
}
.click_button label{line-height: 25px;}
#login .forgotpsss{padding: 5px;color: #08a269 !important;font-size: 14px;text-align: center !important;}
.thank_your{font-size: 24px !important;font-weight: 300 !important;margin-top: 0 !important;text-align: left !important;}
.email_ids{color: #00d285 !important;font-size: 14px !important;text-align: left !important;}
.registerpage{background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  min-height: 100vh;
  background-color: #f6f9fe;
  
  background-image: url("../src/assets/images/register.jpg");
  padding: 20px;}
  .footfour ul li .redoranag{color: #f05a2d !important;}
  .registerpage .container {
    position: absolute;
    width: 500px;
    height: 600px;
    background: #fff;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    right: 0;
    left: 50%;
    top: 30px;
  }
 
  .registerpage .container .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
 
  .registerpage .container .user .imgBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #fafbfd;
    transition: 0.5s;
  }
  
  .registerpage .container .user .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
 

  .registerpage .container .user .formBx {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    transition: 0.5s;
    animation-name: fadeCircle;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-delay: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    /* border-top: 3px solid #03377f; */
  }

  .registerpage .container .user .formBx form h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    color: #555;
  }
  
  .registerpage .container .user .formBx form input {
    position: relative;
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
  }
  .click_button input {
    position: relative;
    margin-right: 10px !important;
    padding: 10px;
    width: unset !important;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
  }
  
  .registerpage .container .user .formBx form input[type='submit'] {
    max-width: 100px;
    background: #677eff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.5s;
  }
  
  .registerpage .container .user .formBx form .signup {
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
  }
  
  .registerpage .container .user .formBx form .signup a {
    font-weight: 600;
    text-decoration: none;
    color: #677eff;
    cursor: pointer;
  }
 
 
  

  
 

  
  
  
  

  
  @media (max-width: 991px) {
    .registerpage .container {
      max-width: 400px;
     right: 0;
     left: 0;
    }
  
    .registerpage .container .imgBx {
      display: none;
    }
  
    .registerpage .container .user .formBx {
      width: 100%;
    }
  }
  
.formregister h2{font-size: 30px;color: #000;font-weight: 600;text-align: center;text-shadow:-1px 6px 10px #00000078;}
.formregister p{font-size: 14px;color: #b9b4b4;font-weight: 400;text-align: center;}
.registerbann{height: 100vh;
  width: 100%;
  object-fit: cover;}
.registeruserinput{
  display: flex;

  align-items: start;

  
    border-radius: 5px;  

}
.password_sent{
  font-size: 14px !important;text-align: left !important;margin-top: 20px;color: #7a7d7f !important;
}
.info .mid-cont{display:block !important}
.registeruserinput input[type=text]{
  -webkit-appearance:none;
  appearance: none;
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}
.registeruserinput input[type=password]{
  -webkit-appearance:none;
  appearance: none;
  position: relative;
    width: 100%;
    padding: 10px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
}
.edafacecopyrights {
  font-size: 12px;
  color: #7a7d7f;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  bottom: 0px;
  right: 80px; */
}
.reglogintbn {
  background-color: #03377f;
  padding: 5px 30px;
  border-radius: 2px !important;
  border: 1px solid #03377f;
  color: #fff !important;
  text-decoration: none;
  margin-top: 20px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registeruserinput input[type=text] {
  appearance: none;
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}
.registerpage h2{font-size: 36px;color: #245278;font-weight: 300;text-align: center;}
.registerpage p{font-size: 18px;color: #4d5154;font-weight: 400;text-align: center;}
.registerbann{height: 100vh;
  width: 100%;
  object-fit: cover;}
.registeruserinput{
  display: flex;
  
  align-items: start;
}
.password_sent{
  font-size: 14px !important;text-align: left !important;margin-top: 20px;color: #7a7d7f !important;
}
.registeruserinput input[type=text]{
  -webkit-appearance:none;
  appearance: none;
  border: none;padding-bottom: 5px;


}
.registeruserinput input[type=password]{
  -webkit-appearance:none;
  appearance: none;
  border: none;padding-bottom: 5px;


}
.alreadycacc{display: flex;justify-content: space-between;align-items: center;margin-top: 30px !important}
.alreadycacc h3{color: #000;font-size: 14px;cursor: pointer;}
.edafacecopyrights{font-size: 12px;color: #7a7d7f;text-align: right;}
.reglogin{color: #000;font-size: 16px;margin-left: 10px;}
.reglogintbn{background-color: #03377f;padding: 5px 30px;border-radius: 5px;border: 1px solid #03377f;color: #fff;text-decoration: none;margin-top: 20px;}
.reglogintbn:hover{background-color: transparent;padding: 5px 30px;border-radius: 5px;border: 1px solid #03377f;color: #03377f !important;}
/* END ITEMS CSS */


/* INFO CSS */
.flex_center
{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.info_header
{
  padding-top: 97px;
  padding-bottom: 0px;
}
.info_row
{
  min-height: calc(100vh - 307px);
  height: 100%;
  background: #fff;
}
.bg_pink
{
  /* background-color: #fde3e2; */
  background: #fff;
  padding: 30px 15px;
}
.info_title
{
  color:#245278;
  font-size: 21px;
  font-weight: 700;
}
.info_h3
{
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.info_h3 span{
  color: #000;
  font-size: 22px;
    font-weight: 700;
}
.info_des
{
  color: #000;
  font-size: 16px;
  padding-top: 15px;
}
.info_media
{
  border-bottom: 1px solid #9e9e9eb0;
  padding: 20px 15px 20px 0px;
}
.info_media .media_text
{
  /* font-size: 20px; */
    font-weight: 700;
}
.flex_txt
{
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
}
.prce_red
{
  color:#a21530;
  padding-right: 20px;
  font-size: 20px;
font-weight: 700;

}
.prce_grey
{
  color: #9a9696;
}
.prce_grey p
{
  line-height: 20px;
}
.prce_grey .price_1
{
font-weight: 500;
font-size: 14px;
}
.prce_grey .price_2
{
font-weight: 700;
font-size: 16px;
}
.owner_card
{
border-radius: 8px;
border:1px solid #d37878;
background: #fff;
}
.media_text_big_1
{
  font-size: 14px;
  color:#9a9696;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.media_text_big_1 .text_blk
{
  color: #4a4a4a;
  font-weight: 700;
}
.media_text_big_2 .text_blk
{
  color: #4a4a4a;
  font-size: 16px !important;
}
.media_text_big_2
{
  font-size: 20px;
  color:#9a9696;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}
.info_big_card
{
  border-radius: 10px;
  background: #fff;
}

button.btn_outline_red
{
  font-weight: 600;
  font-size: 14px;
}
.info_big_card button.btn_outline_red
{
  min-width:125px;
  font-family:Poppins;
}
.proposal_panel_overall .info_media:last-child
{
  border-bottom:none !important;
}
.info_tab .nav-tabs.masonry_tab .nav-link
{
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 16px !important;
}
.info_tab .nav-tabs.masonry_tab
{
  border-bottom: 1px solid #f0bebe !important;
}

@media only screen and (max-width:400px)
{
  .info_big_card button.btn_outline_red,
  .info_big_card button.create_btn
  {
    width: 100% !important;
    margin-left: 0px !important;
  }

}
@media only screen and (max-width:575px)
{
  .white-box p
  {
    font-size:12px !important;
  }
  .reginonehover{left: 0;right: 0;}
  .banner_head .container-theme{display: block !important;}
  .footone h4, .foottwo h4, .footfour h4, .footfive h4{font-size: 16px !important; margin-top: 20px;}
  .footone ul li a, .foottwo ul li a, .footfour ul li a, .footfive ul li a{font-size: 14px !important;}
  .footthree #news_submit{font-size: 15px !important;}
  .bg_red_1{
    padding-bottom:0px;
    margin-bottom:0px;
  }

  .footfour ul li {margin-top: 0 !important;padding-top: 0 !important;}
  .foote,.foote1{display: block !important;}
  .footergrid{gap: 0 !important;}
  .proposal_panel_content h3
  {
    font-size: 16px;
  }
  .proposal_panel_content p
  {
    font-size: 14px;
  }
  .proposal_panel_content
  {
    margin-left: 20px;
  }
  .flex_items_block
  {
    display: block !important;
    margin-bottom: 20px;
  }
  .grid_toggle
  {
    display: grid !important;
    grid-template-columns: 75% 25%;
    gap: 2%;
  }
  .d-flex.cont,.flex_block_mob
  {
    display: block !important;
  }
  .copyright_txt
  {
    margin-left: 0px !important;
  }
  .coll-img
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  button.create_btn
  {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .info_flex_body
  {
    display: block !important;
  }
  .ml-cus
  {
    margin-left: 0px !important;
    margin-top: 15px !important;

  }
  .home_header h3{font-size: 18px !important;}
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
  .info_flex_body
  {
    display: block !important;
  }
  .ml-cus
  {
    margin-left: 0px !important;
    margin-top: 15px !important;

  }
  .info_big_card button.btn_outline_red,
  .info_big_card button.create_btn
  {
    width: 100% !important;
    margin-left: 0px !important;
  }
}


.arrow_expand .fas
{
  color:#9a9696;
  font-size: 25px;
}
.img_des
{
  text-align: center;
  margin-top: 20px;
  display: none;
}
.arrow_expand .fas
{
  position: absolute;
    top: 20px;
    right: 0px;
}
.arrow_expand
{
  padding: 20px;
}
#image_div.expand_img
{
  flex: 0 0 100%;
    max-width: 100%;
}
#img_des.show_des
{
  display: block;
}
#detai_div.hide_detail
{
  display: none;
}
#arrow_icon
{
  cursor: pointer;
}
#arrow_icon.fa-shrink:before
{
  content: "\f065" !important;
}
.buy_title_sm
{
  color:#000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 700;
}
.buy_desc_sm
{
  color:#000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 400;
}
.buy_desc_sm_bold
{
  color:#000;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}
.buy_title_md
{
  color:#000;
  font-size: 18px;line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;

}
.approve_text
{
  color: #4a4a4a;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
.dark_theme .bor_bot_modal label{color:#fff;}
.approve_desc
{
  color: #9a9696;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
}
.approve_media .fas
{
  font-size: 25px;
}
.approve_media .fas.pro_complete
{
color:#03377f ;
}
button:disabled
{
  color:#ffffffa1 !important;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinner_icon
{
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
  font-size: 25px;
}
.inner_div_info
{
  height: 265px;
  max-height: 265px;
  overflow-y: auto;
}
.purchase_text
{
  color: #6f6f6f;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0px;
}
.purchase_desc
{
  color: #9a9696;
    font-size: 16px;
    font-weight: 600;
}
.place_bit_desc
{
  color:#f44336 ;
  font-weight: 700;
  font-size: 16px;
}
.place_bit_desc_2
{
color:#402c2d;
font-weight: 700;
font-size: 16px;
}
.place_bit_desc_2 .text_red
{
font-size: 20px;
font-weight: 700;
color:#000;
}
.input_grp_style_1 .input-group-text
{

height: 35px !important;
font-weight: 700;
}
.user_profileim{font-size: 18px;color: #000;font-weight: 600;}
.profile_banner input[type="file"].photo{
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  border-radius: 50px;
  position: absolute;
  bottom: -33px;
  z-index: 0;
  right: 5px;
  text-align: right;
  width: 100%;

}
input[type="file"].photo{
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  border-radius: 50px;
  position: absolute;
  bottom: -13px;
  z-index: 0;
  right: 5px;
  text-align: right;
  width: 100%;

}
.input_grp_style_1 input::placeholder
{
 color:#aca4a1 !important; 
 font-weight: 600;
}
.label_muted
{
  color:gray;
}
.bid_form label
{
  color: #000;
  font-weight: 600;
  text-align:left;
  font-size: 18px;
  width: 100%;
    margin-bottom: 0px;
}
/* END INFO CSS */

/* OWNER INFO CSS */
#image_div_owner.expand_img
{
  flex: 0 0 100%;
    max-width: 100%;
}
#img_des_owner.show_des
{
  display: block;
}
#detai_div_owner.hide_detail
{
  display: none;
}
#arrow_icon_owner
{
  cursor: pointer;
}
#arrow_icon_owner.fa-shrink:before
{
  content: "\f065" !important;
}

.img_accept img
{
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto;
}
.accept_desc
{
  line-height: 20px;
  margin-top: 20px;
}
/* END OWNER INFO CSS */

/* my items new css */
.btn_outline_red
{
  font-weight: 600;
  font-size: 16px;
}
.btn_outline_red {
    background-image: linear-gradient(90deg,transparent 0,transparent);
    color:#a21530 !important;
    height: 40px;
    border-radius: 5px;
    padding: 10px 30px;
    transition: 0.2s all;
    border:1px solid #a21530;
    text-transform: capitalize;
  }
  .btn_outline_red:hover {
    background-image: linear-gradient(90deg,#a21530 0,#a21530);
    color:#fff !important;
  }
  .items_bg img
  {
    height: 100%;
    object-fit: cover;
    width: 100%;
    max-height: 200px;
    min-height: 200px;
  }
  .edit_cover_text
  {
    position: absolute;
    right:20px;
    bottom:20px;
    color:#fff;
    font-size: 20px;
    font-weight: 700;
    text-shadow: black 0.1em 0.1em 0.2em;
    cursor:pointer;
    opacity: 0;
  }
  .items_bg:hover .edit_cover_text
  {
    opacity: 1;
  }
  .edit_cover_text:hover
  {
    color:#03377f;
    text-shadow: #ccc 0.1em 0.1em 0.2em;

  }
  .edit_cover_container
  {
    position: relative;
  }
  @media only screen and (max-width:350px)
  {
    .edit_cover_text
    {
      bottom: 72px !important;
    }
  }
  /* end my items new css */


  /* COMMUNITY CSS */
  .btn_header
  {
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    width: calc(100% - 30px);
  }
  .btn_header>span
  {
    justify-content: center !important;
  }
  @media only screen and (max-width:1279px){
    .auctions{grid-template-columns: repeat(3,1fr);}
    .headerflexs{display: block !important;} 
  .banercontentDet p{font-size: 12px;}
  
.auctions .banercontentDet h2,.highestbid h4,.hearting span,.hearting i{font-size: 15px !important;}
  }
  @media only screen and (min-width:960px) and (max-width:1280px)
  {
    .commun_navbar.main_navbar li
    {
      margin: 18px auto;
      text-align: center;
    }
    .button.create_btn {
      padding: 5px 20px !important;
     
    }
    .banner_head{display: flex !important;align-items: center !important;}
    .homebnns h2{font-size: 50px !important;}
    .homebnns h3{font-size: 50px !important;}
  }
  @media only screen and (max-width:1600px) and (min-width:1366px) 
  {
    .commun_navbar.main_navbar li:nth-child(2)
    {
      margin-left:15px;
      text-align: center;
    }
    .search_input_community .search_inp
    {
      width: 400px;
    }
   
    .baner_image{width: 100%;}
  }
  button.btn_outline_red
  {
    background-color: transparent !important;
  }
  button.create_btn:hover
  {
    background-color: transparent !important;
   
  }
  form  button.create_btn:hover
  {
    background: white;
    border: 2px solid #03377f;
}
  .login_form label
  {
    color:#000;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .login_form input::placeholder
  {
    color:#aca4a1 !important; 
 font-weight: 600;
  }
  .pwd_link
  {
text-decoration: underline;
color:#867e7c;
font-weight: 700;
cursor: pointer;
  }
  .pwd_link:hover
  {
    color:#a21530 !important;
  }
  .create_link
  {
    text-decoration: underline;
    color:#a21530;
    font-weight: 700;
    cursor: pointer;
  }
  .create_link:hover
  {
    color:#867e7c !important;
  }
  .forgot_desc
  {
    color:#6f6f6f;
    font-weight: 600;
    /* font-size: 14px; */
    margin-bottom: 20px;
  }


  @media only screen and (max-width:575px)
  {
    .info_title
    {
      font-size: 18px !important;
    }
    .primary_modal h5.modal-title
    {
      font-size: 20px !important;
    }
    .main_navbar .megamenu .dropdown-menu{
      overflow-y: scroll;
      min-height: 500px !important;
      max-height: 500px !important;
      top: 0 !important;
      left: 1px !important;
    }
    
  }
  .reg_desc
  {
    color:#6c6d6d;
  }
  .privacy_link
  {
    color: #6f6f6f;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
  .privacy_link:hover,.privacy_link:focus{
   color: #a21530 !important; 
   text-decoration: underline;

  }
  .terms_des,.terms_ul li
  {
    color: #000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
  }
  .dur_text
  {
    position: absolute;
    top:15px;
    right: 15px;
    /* font-weight: 500 !important; */
    font-size: 14px !important;
  }
  .filter_dropdown 
  {
    justify-content: flex-end;
  }
  .filter_dropdown  button
  {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .filter_menu
  {
    padding: 15px;
    min-width: 8rem !important;
    border-radius: 10px !important;
  }
  .filter_menu .custom-control-label{
    padding-left: 20px;
    height: 22px;
    display: flex;
    align-items: center;
    color: #737373;
    font-weight: 400;
  }
  .filter_menu .custom-control-label::before
  {
    background-color: #9f9f9f;
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before
  {
    background-color: #245278;
    border-color: #245278;
  }
  .form_ino_che span{
    position:absolute;
    top:35px;
    /* font-size:16px; */
  }
  .filter_menu .custom-checkbox
  {
    padding-left: 0px;

  }
  header svg,.main_navbar svg
  {
    fill:#000 !important;
  }
  .dark_theme .MuiButton-label svg{
    fill:#fff !important;
  }
  .dark_theme .dark svg{
    fill:#fff !important;
  }
  .dark_theme header svg{
    fill:#000 !important
  }
  .makeStyles-button-50{
    background:#fff;
  }
  header svg:hover,.main_navbar svg:hover
  {
    fill:#03377f !important;
  }
  .main_navbar .icon_txt{
    color:#03377f !important;
  }
  .main_navbar a:hover .icon_txt
  {
    color:#03377f !important;
  }
  .main_navbar a:hover svg
  {
    fill:#03377f !important;
  }
  .filter_btn
  {
    background-color: transparent !important;
    color: #03377f !important;
    font-size: 18px;
    border:none !important;
    padding: 0px !important;
    box-shadow: 0 0px 0px 0 rgb(250 250 250 / 14%), 0 0px 0px -0px rgb(250 250 250 / 20%), 0 0px 0px 0 rgb(250 250 250 / 12%) !important;  
  }
  .noti_head
  {
    color: #000;
    text-align: center;
    font-weight: 700;
    padding-top: 10px;
    font-size: 18px;
    font-family:Poppins;
  }
  .no_noti
  {
    color: #9a9696;
    font-size: 14px;
    text-align: center;
    padding: 20px 0px;
    margin-bottom: 0px;
  }
  .see_all_link
  {
    font-size: 15px;
    font-weight: 600;
    color: #a21530 !important;
    width: 100%;
    text-align:center;
    text-decoration: underline !important;
  }
  .see_all_link:hover
  {
  color:#787878 !important;
  }
  /* .noti_ul .makeStyles-caret-65
  {
    display: none !important;
  } */
  .title_div
  {
    padding: 10px 0px;
    border-bottom: 1px solid #c3bbbb47;
  }
  .title_discussion
  {
    color:#000;
    font-weight: 600;
  }

  .list_grp_style_1 .list-group-item :first-child
  {
  margin-right: 0px !important;
  }
  .list_grp_style_1 .list-group-item
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
    display: block;
    border-bottom: 1px solid #c3bbbb47;
  }
  .cat_title
  {
    color:#6f6f6f;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
  }
  .cat_desc
  {
    font-size: 14px;
    color:#929292;
    font-weight: 600;
    line-height: 25px;
  }
  .cat_link
  {
    font-size: 15px;
    color:#929292;
    font-weight: 700;
    line-height: 25px;
  }
  .cat_link_green
  {
    color:#76d238 !important;
  }
  .cat_link_blue
  {
    color:#6076e0 !important;
  }
  .cat_dur
  {
    color:#443f40;
    font-weight: 600;
  }
  .masonry_tab.dis_tab
  {
border-color: #c3bbbb47 !important;
border-bottom-width: 1px !important;
  }
  .creators_details_td img {
    max-width: 35px;
    min-width: 35px;
    max-height: 35px;
    min-height: 35px;
    border:1px solid gray;
    border-radius: 50%;
    margin-right: 3px;
}
.sel_period
{
  background: #ffffff69 !important;
  padding-left: 15px;
  padding-right: 15px;
  color:#969696 !important;
  --moz-appearance: menulist  !important;
    --webkit-appearance: menulist  !important;
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
    max-width: 270px;
}
.table_style_1 th{
  color: #969696 !important;
  font-weight: 600 !important;
}
.table_style_1 td
{
  font-weight: 600 !important;

}

.table_style_1 th:first-child,.table_style_1 td:first-child
{
  min-width: 200px;
}
.table_style_1 th:nth-child(2),.table_style_1 td:nth-child(2)
{
  min-width: 175px;
}
.table_style_1 th:last-child,.table_style_1 td:last-child
{
  min-width: 200px;
}
.cat_title_sm
{
  font-size: 18px !important;
}
.dis_head .inner_title
{
  align-items: baseline;
}
.icon_red
{
  color:#a21530;
}
.dis_accordion>div[role="button"]{
  /* background-color: #fde3e2; */
  background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0);

}
.dis_accordion 
{
  border-bottom: none !important;
}
.m-0.panel_header_dis>div{
  margin: 0px !important;
  padding: 5px 0px !important;
}
.text_gry
{
  color:#969696;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.panel_header_dis 
{
  align-items: baseline !important;
  margin-top: 15px !important;
}
@media only screen and (min-width:992px)
{
  .pl-lg-cus
  {
    padding-left: 2.5rem !important;
  }
}
.accordian_para
{
  font-weight: 500;
  color:#000;
  line-height: 25px;
  width: 100%;
  font-size: 14px;
}
.desc_discussion
{
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  padding-top: 15px;
}
.reply_uer_name
{
  font-size: 16px;
  color:#000;
  font-weight: 700;
}
.reply_txt
{
  font-size: 20px;
    font-weight: 600;
    color: #000;
    margin: 0;
    line-height: 30px;
}
.accordion_form
{
  /* background-color: #fde3e2; */
  background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0);
  margin-top: 20px;
}
.post_link
{
  font-size: 15px;
  color: #594c4b;
  font-weight: 700;
  line-height: 25px;
  cursor: pointer;
}
.post_link:hover,.post_link.active,.cat_link:hover
{
color:#a21530 !important;
text-decoration: underline;
}
@media only screen and (min-width:768px)
{
  .border_left_md
  {
    border-left:1px solid #ccc;
    padding-left: 30px;
  }
}
.cat_title:hover
{
  color:#a21530 !important;
}
  /* END COMMUNITY CSS */

  .spinner_red
  {
    color:#a21530;
    font-size: 30px;
  }

  /* faq css */
  .faq_a
  {
    color:#a21530;
    text-decoration: underline;
  }
  .faq_a:hover, .faq_a:focus
  {
    color:#787878;
    text-decoration: underline;
  }
  .faq_bold_text
  {
    font-weight: 700;
  }


  /* dopdown */
  .dropdown-toggle.filter_btn::after
{
  content: none !important;
}
.filter_menu.filter_menu_info
{
  background-color: #fff;
  border-radius:5px !important;
  padding: 0px;
  
  
}
.filter_menu_info .menu_itm.active,.filter_menu_info .menu_itm:hover
{
  background-color: #7633ef;
  border-radius:2px !important;
}
.menu_itm
{
  padding: 5px 15px;
  cursor: pointer;
}
.menu_itm span
{
  color:#000;
}
.menu_itm.active span,.menu_itm:hover span
{
  color:#fff;
}
#share_modal .icon_div
{
  cursor: pointer;
}
#share_modal .icon_div .fab
{
  color:#000;
  font-size: 30px;
}
 .icon_div p
{
  color:#000 !important;
  font-weight: 600;
}
.icon_div:hover .fab,.icon_div:hover p{
  color:#03377f !important;
}
.connect_row .create_box
{
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  border-color:#03377f;
  text-align: center;
  cursor: pointer;
}

.dark_theme .card_inner_item h2{
  color:#fff;
  background-image:-webkit-linear-gradient(transparent,transparent);
}
.connect_row .create_box p{
  color: #fff;
}

.connect_row .create_box img
{
  max-width: 30px;
  min-width: 30px;
  filter: brightness(0) invert(1);
}
.connect_row .create_box:hover
{
  border-color:#03377f;
}
.connect_row .create_box h2
{
  color:#000 !important;
}
.connect_row .create_box .text-white
{
  color:#807676 !important;
}
#onsale-tab .tab_count{color: #245278 !important;}
.connect_row .create_box .active{background-color: #000;}

.connect_row .create_box12
{
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  border-color:#ccc;
  text-align: center;
  cursor: pointer;
}
.connect_row .create_box12 p{
  color: #fff;
}

.connect_row .create_box12 img
{
  max-width: 30px;
  min-width: 30px;
  filter: brightness(0) invert(1);
}
.connect_row .create_box:hover
{
  border-color:#03377f;
}
.connect_row .create_box12 h2
{
  color:#000 !important;
}
.connect_row .create_box12 .text-white
{
  color:#807676 !important;
}


  /* notification */
  .buyeruliil li{
    list-style-type: none;
    /* border-bottom: 1px solid #ccc; */
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .explorebg li{
    list-style-type: none;
    /* border-bottom: 1px solid #ccc; */
    padding-top: 12px;
    padding-bottom: 15px;
  }
  
  .noti_ul_dd li
  {
    list-style-type: none;
    /* border-bottom: 1px solid #ccc; */
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .explorebg{
    padding: 0;
  }
  .noti_parent p span{
    display: inline-block;
    /* background: linear-gradient(90deg, rgb(255, 0, 184) 0%, rgb(100, 161, 255) 100%); */
    border-radius: 4px;
    padding: 4px;
    color:#000;
    font-size: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 12px;
  }
  .noti_parent_class p span{
    display: inline-block;
    /* background: linear-gradient(90deg, rgb(255, 0, 184) 0%, rgb(100, 161, 255) 100%); */
    border-radius: 4px;
    padding: 4px;
    color:#000;
    font-size: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
  }
  .noti_ul_dd
  {
    padding-left: 0px;
  }
  .buyeruliil .explorebg{
    padding-left: 0;
  }
  .noti_ul #menu-list
{
/* background-color: #217fe3 !important; */
min-width: 200px !important;
box-shadow: -2px 5px 20px #0000007d !important;
border-radius: 0px !important;
}
/* .noti_ul #menu-list li
{
  margin: 0px !important;
  margin-bottom: 0 !important;
 
} */
.noti_time
{
  color:#000;
  font-weight: 600;
}
.noti_msg
{
  color:#787878;
  word-break: break-word;
  white-space: break-spaces;
}
/* new css */
.form_note.font_12
{
  font-size: 14px !important;
}
.create_sing_bx
{
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 25px 15px !important;
cursor: pointer;
}
.create_sing_bx .fas
{
  color:#9a8288;
  font-size: 20px;
}
.create_sing_bx p
{
  margin-bottom: 0px !important;
  color:#fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 10px;
}
.fa-infinity::before
{
  content: "\f534";
}
.selct_form_input_h
{
  min-height: 46px !important;
}
.selxet_app
{
  min-width: 100px !important;
}
.font_we_700_note_txt
{
  font-weight: 700;
  font-family:Poppins;
}
.form_note_link_boild
{
  color:var(--theme-pink);
  font-weight: 700;
  cursor: pointer;
}
.form_note_link_boild:hover
{
  color:#969696 !important;
}
.single_dd_1 button
{
  background-color: transparent !important;
  box-shadow: 0 0px 0px 0 rgb(153 153 153 / 14%), 0 0px 0px 0px rgb(153 153 153 / 20%), 0 0px 0px 0 rgb(153 153 153 / 12%) !important;
  border-bottom: 1px solid #e89191 !important;
  border-radius: 0px !important;
  color: #ACA4A1 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
  text-align: left !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;

}
.single_dd_1 button span
{
  justify-content: flex-start !important;
  width: 100% !important;
}
.single_dd_1 .filter_menu div
{
  cursor: pointer;
  padding: 5px;
}
.single_dd_1 .filter_menu div:hover
{
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px #03377f52;
    background-image: var(--theme-grad);
    color: #fff !important;
}
.rdtPicker
{
  width: 100% !important;
  background-color: transparent !important;
  background: transparent !important;

}
.rdtPicker
{
  border-color: transparent !important;
}
.rdtPicker th,.rdtPicker table,.rdtPicker thead,.rdtPicker tbody,.rdtPicker td,.rdtDays,.dtPicker tr,.rdtPicker tfoot
{
  border-color:transparent !important;
}
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker thead tr:first-child th:hover,
.rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover,.rdtCounter .rdtBtn:hover
{
  background-color: #03377f12 !important;
}
#doneStartDate:hover{color: #000;}
.single_dd_1 .filter_menu
{
  width: 100% !important;
}

.single_dd_1 .filter_btn
{
display: flex;
justify-content: space-between;
}
.single_dd_1 .filter_btn i
{
  font-size: 15px !important;
}
.slider_sec_1 .owl-prev
{
position: absolute;
top:45%;
left:-20px;
background-color: #fde3e2 !important;
background: #fde3e2 !important;

color:#a21530 !important;
padding: 10px !important;
width: 30px;
height: 30px;
border-radius: 50% !important;
display: flex !important;
align-items: center;
justify-content: center;
font-size: 30px !important;
}
.slider_sec_1 .owl-prev span
{
  margin-top: -6px !important;
}
.slider_sec_1 .owl-next
{
  position: absolute;
  top:45%;
  right:-20px;
  background-color: #fde3e2 !important;
  background: #fde3e2 !important;
  
  color:#a21530 !important;
  padding: 10px !important;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 30px !important;
}
.slider_sec_1 .owl-next span
{
  margin-top: -6px !important;
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  .main_navbar .megamenu .dropdown-menu{
    overflow-y: scroll;
    min-height: 500px !important;
    max-height: 500px !important;
    top: 0 !important;
    left: 1px !important;
  }
  .cat_title
  {
    font-size: 16px;
  }
  .d-flex.cont  {
    display: block !important;
  }
  .single_collectible .item h3
  {
    font-size: 16px !important;
  }
  .topgrid{grid-template-columns: repeat(2,1fr);}
}
.overlay_card_live
{
  position: relative;
  margin-bottom: 20px;
}
.overlay_card_live .badge
{
  border-radius: 25px;
  padding: 5px 10px;
  background-color: #fff;

  color:#000;
  font-size: 14px;
  border:2px solid #a21530;
  position: absolute;
  bottom:-10px;
  cursor: pointer;
  left: 50%;
    transform: translate(-50%,0%);
}
.overlay_card_live .badge:hover
{
  background-color: #a21530;
  background-image: linear-gradient(to bottom,#a21530,#a30726,#d01242);
  color:#fff;
}
.overlay_card_live .badge i
{
  padding-left: 10px;
}
.a_footer_text
{
  color: #969696 !important;
  font-weight: 600;
}
.a_footer_text:hover
{
  color: #a21530 !important;
}


/* NEW SEARCH CSS */
.slick-arrow.slick-prev, .slick-arrow.slick-prev:focus, .slick-arrow.slick-prev:hover
{
  z-index: 10 !important;
}

.dropdown_header_ul.user_dd_ul
{
position: relative;
margin-left: 2px;
}

.usemneu_dd
{
  position: absolute;
    will-change: transform;
    top: 55px;
    right: 0px;
    /* transform: translate3d(-98px, -4px, 0px); */
    background-color: #fff !important;
    min-width: 270px!important;
    max-width: 100% !important;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,.6);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -o-box-shadow: 0 0 10px rgba(0,0,0,.6); */
    border-radius: 10px;
    padding: 10px;
    color:#000 !important;
    z-index: 10 !important;
    border:1px solid rgba(0,0,0,0.1);
}

@media only screen and (max-width:1279px)
{
  .usemneu_dd
  {
    max-width: 240px !important;
    min-width: 240px !important;
    left:0px !important;
  }
  .searchmneu_dd
  {
    max-width: 235px !important;
    min-width: 235px !important;
    left:0px !important;
  }
}
.earn_crypto_section  .create_btn
{
  font-size: 14px !important;
  font-weight: 500 !important;
}
@media only screen and (min-width: 1280px)
{
  .pl-cust-wal
{
  padding-left: 0px !important;
}
  .img_user_noti {
    width: 40px!important;
    height: 40px!important;
    margin-right: 20px!important;
}
.user_ul_new
{
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
  margin-right: 20px!important;
  border-radius: 50%;
}
}
@media only screen and (max-width: 1279px)
{
  .img_user_noti {
    width: 40px!important;
    height: 40px!important;
    margin-right: 20px!important;
}
.user_ul_new
{
  max-width: 25px;
  min-width: 25px;
  border-radius: 50%;
  max-height: 25px;
  min-height: 25px;
}
}
.dropdown_header_ul .noti_ul_dd li:hover {
  background-color: #23262f26!important;
  background: #23262f26!important;
}

.noti_ul_dd li
{
  cursor: pointer;
  white-space: break-spaces;
}
.noti_ul_dd li:hover
{
  color:#000 !important;
}
.buyeruliil li,.explorebg li
{
  cursor: pointer;
  white-space: break-spaces;
}
.buyeruliil li:hover,.explorebg li:hover
{
  color:#000 !important;
}

.font_we_700
{
font-weight: 700;
}
/* END NEW SEARCH CSS */

button.new_btn_grad {
  background-image: var(--theme-grad) !important;
  height: 40px;
  border-radius: 5px;
  padding: 15px 30px;
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  border: 2px solid #03377f;
  color:#fff !important;
}
.new_btn_grad a {
  color: #fff !important;
 
}
button.new_btn_grad:hover {
  background-image: linear-gradient(90deg,transparent,transparent) !important;  
  
  border: 2px solid #03377f;
}
button.new_btn_grad:hover a
{
  color:#03377f !important;

}
.copyright_txt a:hover{
  color:#000 !important;
  transition: 1s;
}
/* new css */
.flex_center_ban
{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.slick-arrow.slick-prev,.slick-arrow.slick-prev:hover,.slick-arrow.slick-prev:focus
{
  position:absolute;
  left:2%;
}
.slick-arrow.slick-next,.slick-arrow.slick-next:hover,.slick-arrow.slick-next:focus
{
  position:absolute;
  right:2%;
}
.img_banner_bg
{
  position: absolute;
  top:0%;left:0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* transform: translate(-50%,50%); */
}


/* coming soon css */
.coming_soon_sec
{
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.coming_soon_sec p.com_text
{
  font-size: 60px;
    font-weight: 700;
    line-height: 100px;
    margin: 0;
    color: #245278;
    text-align: center;
}
.btn_com
{
  font-size: 20px !important;
  font-weight: 700 !important;
  padding: 25px 50px !important;
}
@media only screen and (max-width:575px)
{
  .cat_title
  {
    font-size: 16px;
  }
  .coming_soon_sec p.com_text
  {
    font-size: 35px !important;
  }
  
  .topgrid{grid-template-columns: repeat(1,1fr);gap: 10px;}
}
/* end coming soon css */

/* new topic css */
.btn_new_topic
{
  background-color: #000 !important;
  height: 40px;
  border-radius: 5px;
  padding: 15px 30px;
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  font-size: 14px !important;
}
button.btn_new_topic:hover {
  background-image: linear-gradient(90deg,#18a0fb,#cbda3e) !important;  
  --webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border: 2px solid #18a0fd;
}
.login_form textarea::placeholder
{
  color:#aca4a1 !important; 
font-weight: 600;
}
/* end new topic css */


/* NEW CSS */
.top_panel_div_single_img
{
  max-width: 92px;
  min-width: 92px;
  max-height: 92px;
  min-height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px solid #ccc;
  overflow: hidden;
}
.recent_collections_row_new img
{
  width: 100%;
}
.recent_collections_row_new div.recent_collections_row_new_div
{
  position: relative;
  border:1px solid #ccc;
  max-width: 100%;
  min-width: 100%;
  max-height: 350px;
  /* min-width: 350px; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.price_desc
{
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #fff;
  padding: 1px 4px;
  color:#2ea4cf;
  font-weight: 700;
  opacity: 0;
}
.price_desc a
{
  color:#2ea4cf !important;

}
.like_desc
{
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  padding: 1px 8px;
  color:#ea40a5;
  font-weight: 700;
  opacity: 0;
}
.recent_collections_row_new div.recent_collections_row_new_div:hover .price_desc,
.recent_collections_row_new div.recent_collections_row_new_div:hover .like_desc{
opacity: 1;
}
.like_desc.liked .far,.masonry_likes.liked .far
{
  font-weight: 900 !important;cursor: pointer;
}
.masonry_new .item
{
  display: inline-block;
    background: #fff;
    padding: 1em;
    width: 100%;
    --webkit-transition: 1s ease all;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
    border-radius: 2px;
    margin-top: 10px;
}
.colct_img_div
{
  position: relative;
  border:1px solid #ccc;
  max-width: 100%;
  min-width: 100%;
  max-height: 350px;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.overlay_card_live_img_div
{
  position: relative;
  border:1px solid #ccc;
  max-width: 100%;
  min-width: 100%;
  max-height: 220px;
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; 
}
.masonry_new .item .fa-ellipsis-h{
color:#ccc !important;
}
.featured_sec_new
{
  background-image:linear-gradient(to bottom,#a21530,#a30726,#d01242)
}
.fea_tex_white
{
  color:#fff;
  font-size: 42px;
  font-weight: 600;
  text-align: center;}
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  .walet_icon_sec  img
  {
    cursor: pointer;
  }
.walet_icon_sec img:hover
  {
    -moz-animation: hvr-pulse 2s infinite;
    -webkit-animation: hvr-pulse 2s infinite;
    animation: hvr-pulse 2s infinite;
  }
  .banner_scoll_hpme
  {
    overflow: hidden;
  }
  .searchmneu_dd
  {
    position: absolute;
    will-change: transform;
    top: 60px;
    right: 0px;
    /* transform: translate3d(-98px, -4px, 0px); */
    background-color: #fff !important;
    min-width: 270px;
    max-width: 100% !important;
    box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,.6);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 60%);
    -o-box-shadow: 0 0 10px rgba(0,0,0,.6);
    border-radius: 10px;
    padding: 10px;
    color: #000 !important;
    z-index: 10 !important;
  }
  .noti_head {
    padding: 15px 15px 0!important;
    text-align: left;
    font-weight: 700 !important;
}
.noti_head span {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}
.info_des {
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 600;
}
.flex_body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.header_media .media_num {
  color: #969696;
  font-size: 14px;
  font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
}
.balance_txt_header {
  font-weight: 700;
  font-size: 14px;
  color: #000;
}
.user_prof_ul li:nth-child(n) {
  content:'';
  list-style-type: none!important;
  border-bottom: 1px solid #ccc;
  padding: 10px 0!important;
}
.user_prof_ul li:last-child {
  content:none;
  list-style-type: none!important;
  border-bottom: 0px solid #ccc;
  padding: 10px 0!important;
}
.user_prof_ul li a:hover,.user_prof_ul li a:hover span
{
  color:#03377f  !important;
  transition:1s;
}
.balance_txt_header span{
  font-family:Poppins;
  font-size:13px;
  font-weight:600;
}
.user_prof_ul li span
{
  color: #454241;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600 !important;
}
/* .main_navbar li a {
  color: #777e90;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}
.main_navbar li a.active, .main_navbar li a:hover {
  color: #6c62ff;
  background: transparent;
} */
.user_prof_ul {
  padding: 0!important;
  margin: 20px 0 0px!important;
}
.heade_switch .toggle_custom.switch {
  margin-right: 0!important;
  top: -8px!important;
  width: 50px;
    height: 22px;
}
.heade_switch .toggle_custom .slider:before
{
  min-width: 45px !important;
}

/* END NEW CSS */


/* DARK THEME CSS */
.dark_theme .home_header,
.dark_theme .home_header header
{
background-color: transparent !important;
}
.dark_theme .search_inp
{
  background-color:#212121;
  border-color:#212121;
  color:#fff;

}
.dark_theme .main_navbar li a

{
  color:#b3aeae;
}
.dark_theme .noti_ul_dd a{color:#000 !important;}
.dark_theme .noti_ul_dd p{color:#000 !important;}
.dark_theme .menu_dropdown button
{
  color:#b3aeae !important;
}
.dark_theme .main_navbar li a:hover
{ 
  color: #6a33fb;
  background: transparent;

}
.colors_ul{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
   
}
.explorerecenlyadded {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 5px 20px;
  margin-right: 10px;
  appearance: none;
}
.explorerecenlyadded i {
  font-size: 14px;
}
.explorerecenlyadded .ml-2 {
  font-size: 16px !important;
  color: #000;
}
.exploretabs {
  appearance: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 5px 10px;
  margin-right: 10px;
}
.exploretabs span {
  color: #000;
  font-size: 16px;
  margin-right: 20px;
  font-weight: 600;
  text-align: center;
}
.exploretabs i {
  color: #000;
  font-size: 16px;
}
.explorerecenlyadded p {
  position: absolute;
  font-size: 12px;
  color: #000;
  margin-bottom: 0 !important;
  font-weight: 300;
  transform: translate(17px ,-14px);
  background-color: #fff;
  width: 100px;
}
.filter_btn.select_btn {
  max-width: 100%;
  font-size: 30px !important;
  font-weight: 600;
  color: #000 !important;
}
.panel_acc_inline .filter_btn {
  width: auto !important;
  display: inline !important;
  margin-left: 10px !important;
} 
.exploremenu {
  display: flex;
  padding-bottom: 20px;
  margin-left: 30px;
}
.categoreies{
  background: #fff;
  list-style: none;
}
.makeStyles-primaryHover-51:hover{
  background: none !important;
  color: #000 !important;
}
.makeStyles-button-65:hover{
  background: none !important;
  color: #000 !important;
}
/* 
.explorecontentstile {
  display: flex;
  justify-content: center;
} */
.colors_ul{cursor: pointer;}
.dark_theme .colors_ul a{
  color:#fff;
}
body .dark_theme .card_inner_item h2{
  color:#fff ! important;
}
.dark_theme .brand_section h2
{
  color:#fff;
}
.dark_theme .recent_collections,
.dark_theme .wallet_section
{
background-color: #131313;
}
.dark_theme .recent_collections h2,.dark_theme  .explore_section h2,.dark_theme  .wallet_section h2,
.dark_theme .wallet_section p
{
  color:#fff;

}
.dark_theme .explore_section
{
  background-color:#020202;
}
.dark_theme .masonry_tab
{
border-bottom-color: #3a3636;
}
.dark_theme .nav-tabs.masonry_tab .nav-link
{
  color:#fff;
}
.dark_theme footer
{
  background-color: #030303;
}
.dark_theme .main_navbar li .create_btn a
{
color:#fff !important;
}
.dark_theme .main_navbar li .create_btn:hover a
{
color:#a21530 !important;
}
.dark_theme .single_collectible h2
{
  color:#000 !important;
}
.dark_theme, .dark_theme .home_header header {
  background-color: #000 !important;
}
.dark_theme .usemneu_dd
{
  background-color: #131313 !important;
}
.dark_theme .noti_head,.dark_theme .noti_head span,.dark_theme .balance_txt_header,
.dark_theme .noti_time
{
  color:#fff;
}
.dark_theme .user_prof_ul li span
{
  color: #b3aeae;
}
.dark_theme .user_prof_ul li
{
  border-bottom-color:#2a29296b;
}
.recent_collections_row_new div.recent_collections_row_new_div
{
 border-color:#6e67677a;
}
/* END DARK THEME CSS */
html {
  font-family: 'Poppins', sans-serif !important;
}
.coll-img button.create_btn:hover
{
background: #fff !important;
}
.sellerlists,.white-box{
  border:1px solid transparent;
}
.white-box:hover{
  border:1px solid transparent;
}
.dropdown-menu .dropdown-menu-item
{
  cursor: pointer;
}
.primary_modal .modal-dialog
{
margin-top: 20px;
margin-bottom: 20px;
}
.create_box:hover{
 
  background-image: linear-gradient(41deg,#9533d0, #6933fc);
  border: 1px solid #03377f !important;
}
.rdtPicker td.rdtToday:before
{
  border-bottom-color:#03377f !important;
}
.formCls .form-control {
  height: 46px!important;
}
.single_collectible .item_multiple {
  position: relative;
  border: #a21530;
  border-bottom: 15px groove #a21530;
}
.primary_modal 
{
  padding-right: 0px !important;
  overflow: auto !important;
}
.bid_form .create_box{padding: 3px !important;}
.primary_modal .modal-title
{
  display: grid;
    grid-template-columns: 100% 10%;
    /* gap: 2%; */
}
#learn_modal p,
#learn_modal li,
.accordian_para p,.accordian_paras p

{
  font-size: 14px;
}
.nav-tabs.masonry_tab.proposal_tab .nav-link
{
  padding: 12px 25px !important;
}
.nav-tabs.masonry_tab.proposal_tab
{
  margin: 20px 0px;
  padding-left: 5px !important;
}
.font_14 
{
  font-size: 14px !important;
  font-family:Poppins;
}
.noti_parent p{
  font-family:Poppins;
  font-size: 13px !important;
    /* line-height: 7px; */
}
.noti_parent_class p{
  font-family:Poppins;
  font-size: 14px !important;
    line-height: 27px;
}
/* .noti_ul_dd p
{
  font-weight: 600;
} */
.item_prof_img
{
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border:1px solid gray;
  border-radius: 50%;
  margin-top: -100px;
  margin-bottom: 20px;
}
.cur_poinet,.dropdown-menu .dropdown-item
{
  cursor: pointer;
}
.bg_red_icon.cur_poinet
{
  width:35px;
  height:35px;
  background: var(--theme-grad);
}
.ma_no_gap
{
  column-gap: 0em !important;
}
.img_media_new
{
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border:1px solid gray;
  border-radius: 50%;
}
.media_num.font_14
{
  font-size: 14px !important;
}
.icon_img
{
  position: relative;
}
.bg_red_icon_sm
{
  position: absolute;
  top:-5px;
  left:-5px;
  margin-left: 0px;width:20px;height: 20px;
}
.bg_red_icon_sm i
{font-size: 9px;

}
.img_dis
{
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border:1px solid gray;
  border-radius: 50%;
}
.select_show_dd
{
  --moz-appearance: menulist !important;
    --webkit-appearance: menulist !important;
}
.main_navbar li .registerfrone{color: #000 !important;}
.main_navbar li .registerfrone:hover{box-shadow: none !important;color: #26b6d4  !important;}
.arrow_back
{
  font-size: 27px;
    color: #03377f;
}
.arrow_back:hover{
  color:#03377f;
}
.cur_pointer
{
  cursor: pointer;
}

.wallet_section h2.mt-0
{
  margin-top: 0px !important;
}
.primary_inp
{
  font-size: 14px !important;
}
.create_btn.bg-white:hover{
  color:#000 !important;
}
.filter_btn.white_text_filter_btn
{
  color:#fff !important;
}
.connect_row_conn_wal.connect_row .create_box img 
{
filter:unset !important;
}
.connect_row_conn_wal.connect_row .create_box h2
{
color:#fff !important;
}
.connect_row_conn_wal.connect_row .create_box .text-white
{
  color:#ded1d1 !important;
}
.create_btn .text_back a
{
  color:#fff !important;
}
.regicenter{display: flex;flex-direction: column;justify-content: center;height: 100vh;}
.coming_soon_btn
{
  border: 2px solid #245278;
  color: #fff !important;
  background-color: #245278;
}
.coming_soon_btn a{color: #fff;}
.coming_soon_btn:hover a{color: #245278;}
.coming_soon_btn:hover
{
  border: 2px solid #245278;
  color: #245278 !important;
  background-color: transparent;
}
.dropdown .drop-down
{
font-size: 20px !important;
line-height:15px;
}

.minumadale{font-size: 18px;color: #000;}
.change_price_img_div
{
 
  max-height: 200px;
  
  min-height: 200px;
 
  display: flex;
  
  justify-content: center;
  overflow: hidden;
   
    margin: 20px auto;
}
.font_we_600
{
  font-weight: 600;
}
.font_12
{
  font-size: 12px !important;
}
.info_media_img_div
{
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border:1px solid gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.dd_info_inline
{
  display:inline !important;
}
.acti_card .follow_media.icon_img
{
  align-items: flex-start !important;
}
.follow_media.icon_img .media_num
{
  font-size: 13px !important;
}
.acti_card .card-body .follow_media {
  align-items: center !important;
  justify-content: center;
  height: 100%;
}
.acti_card .bg_red_icon_sm
{
  top:unset !important;
  top:-10px !important;
}
.icon-img-div
{
  position: relative;
}

.connect_row_conn_wal .create_box h2
{
font-size: 19px !important;
text-align: center;
}

.connect_row.connect_row_conn_wal .create_box img {
  max-width: 45px;
  min-width: 45px;
}
.baner_r_span
{
  font-size: 25px !important;
}

/* NEW CSS */
.form-control:disabled, .form-control[readonly]
{
  background-color: transparent !important;
  background-image: linear-gradient(to top, #ffffff 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #decacb 1px, rgba(210, 210, 210, 0) 1px) !important;
  /* background: transparent !important; */

}
.min_h_45_px
{
  min-height: 45px;
}


/* NEW CSS HAS BEEN REMOVED */
/* .text_hide_blk a
{
  color:#242424 !important;
}
.footer_links li.text_hide_blk a::after
{
content: none !important;
}
.footer_links li.cont_none_fo a::after

{
content: none !important;

} */

.new_des_footer
{
  /* height: 100%; */
display: flex;
flex-direction: column;
align-items: inherit;
justify-content: flex-end;
}

@media only screen and (min-width:1199px)
{
  .new_des_footer
  {
    height: 100%;
  }
}


.header_media img
{
  max-width:30px;
}

.img_info_new_div img

{
  min-width:35px;
  max-width:35px;
  border-radius: 50%;
}

.img_info_new_div
{
  min-width: 35px;
}
.word_brak_txt
{
  word-break:break-all;
}
.popup_loader
{
  min-height: 100vh;
  display: flex;
  align-self: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  flex-direction: column;
}
.popup_loader p
{
  font-size: 18px !important;
    color: #000;
    font-weight: 700;
    margin-top:20px !important;
    margin-left:0px !important;
}
.popup_loader img
{
  max-width: 70px;
    margin: 0 auto;
}

.itemd_heih_adj
{
height:100% !important;

}

.itemd_heih_adj .card_inner_item
{
height:100% !important;
min-height: 381px;
/* padding-bottom: 0px; */


}

.text_blck_wen
{
  color:#000 !important;
  font-size: 16px !important;
}

.banner_sec_row h1{
  font-weight: 600 !important;
  color: #000 !important;
  margin-bottom: 42px;
  text-shadow: -4px 2px 4px rgb(0 0 0 / 50%);
  letter-spacing:1px;
}

.banner_sec_row h1 span {
  color: #ca113e;
}
.banner_sec_row p
{
  font-size: 18px;
color: #323232;
margin-bottom: 42px;
width:260px;
}
.learnmore{
  color: #000000 !important;
    font-size: 18px;
    margin-top: 20px;
    border: none;
    background: none;
}
.learnmore a{
  color: rgba(0,0,0,0.5) !important;
  font-weight: 600;
  font-size:14px;
}
.com_ban_row h1
{
  font-weight: 700 !important;
  color: #000 !important;
  font-size:40px !important;
}
.prof-tick{
  position:relative;
}
.prof-tick:before {
  content:'';
  background-image: linear-gradient(45deg, #9833cd , #6f33f6);
  border-image-slice: 1;
  border-radius: 50%; 
  padding:2px !important;
  width:84px;
  height:84px;
  display:block;
  position: absolute;
    top: -2px;
    left: 18px;
}
.com_ban_row p
{
  font-size: 20px;
color: #9c9a9a;
margin-bottom: 22px;
}

.banner_sec_row .white-box p {
  font-size: 15px !important;
  color:#3f3e3e !important;
}

@media only screen and (max-width:575px)
{
  .banner_sec_row .white-box p {
    font-size: 12px !important;
    color:#3f3e3e !important;
  }
}

.form_ino_che
{
display: flex;
}
.form_ino_che .custom-checkbox
{
padding-left: 0px !important;
}

.form_ino_che .custom-control-label::after,
.form_ino_che .custom-control-label::before

{
left:-16px !important;
top:12px !important;
border-radius: 0;
}

section.pb-2
{
  padding-bottom: 0.5rem !important;
}
.sect_whi_row .white-box
{
  height:100% !important;
  border:2px solid #a30726;
}

.badge_red
{
  background-color: #a30726;    
    background-image: var(--theme-grad);
    padding: 10px !important;
    font-size: 16px;
}

.min_h_35
{
  min-height:35px !important;
  font-size: 14px !important;
}

/* @media only screen and (min-width:430px)
{
  .banner-r img
  {
    aspect-ratio: 3/2;
  }
} */



/* .my_itens_sec_img img, */
/* .my_itens_sec_img video, */
.my_itens_sec_img object

{
max-width:100% !important;
max-height:350px !important;
overflow: hidden;
min-height: 350px;
object-fit: scale-down;
}
.my_itens_sec_img object img
{
  text-align:center;
}
.my_itens_sec_img object body
{
  text-align:center !important;
}
 
 iframe img {
   width: 100% !important;
 }
 /* surya ditmax */

 
.banner-r {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;

  box-shadow: -2px 4px 8px 3px #00000021;
}
.item_inner_img .img-fluid {
  /* object-fit: contain !important; */
    /* height: 100%;                    */
    max-height:200px;
    min-height:150px;
    border-radius:10px;
}

.banenrimagesd{
  width: 100%;
 /* border-radius: 20px; */
 height: 80%;
}
.profileabnner{
  display: flex;
  padding: 20px;
  justify-content: space-between;
    align-items: center;
}
.profileabnner img
{
  width: 50px;
  height: 50px;   
   box-shadow: 0 0 7px 2px #00000036;
  border-radius: 51%;
}
.profillist{
  display: flex;
  align-items: center;
  
}

.insername{
  margin-bottom: 0 !important;
  font-size: 20px;
  color: #03377f !important;
  background: -webkit-linear-gradient(#03377f, #03377f);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.insertdiv{
  padding-left: 20px;
  
}
.profileabnner .ethereume{
  width: 30px;
  height: 30px;
  border: 2px solid #8333e1;
  border-radius: 50%;
  box-shadow: 0 0 3px #0000005e;
  margin-left:auto;
}
.insertdiv .insername{
  margin-top:0px;
}
.insertdiv .insernamenft{
  margin-bottom: 0 !important;
    font-size: 12px !important;
    font-weight: 600;
    color: rgba(0,0,0,0.6) !important;
    margin-top:5px;
}
.rowone{
  /* background-color: #ffffff52; */
  background-image:-webkit-linear-gradient(-61deg,#dadada,#fff);
  box-shadow: 0 0 7px #00000024;
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(5 , 1fr);
  gap: 15px;
}
/* .MuiAccordionDetails-root{
  background: #fff;
  box-shadow: 1px 2px 15px -1px #0b0b0b;
  width: 200px;
  margin: auto;
  border-radius: 10px;
  position: absolute;
    left: 0px;
    right: 0px;
    top: 69px;
    padding:0px !important;
} */
.MuiAccordionDetails-root ul{
  padding-left:0px;
}
.MuiAccordionDetails-root ul li{
  text-transform: capitalize;
}
.rowone1{
 
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(5 , 1fr);
  gap: 15px;
}
.xenox,.carton{
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.xenox img{
  border: 1px solid #dddddd;
  border-radius: 50%;
  position: relative;
  min-width: 80px;
  max-width: 80px;
  aspect-ratio: 1/1;
  background: #fff;
  padding: 4px;
}
.xenox span.img_tick.img_tick_lg img{
  position: relative;
  background: #fff;
  min-width: 20px;
  min-height: 20px;
  border: 0px solid;
  top: 55px;
  left: 13px;
  padding: 0px;
}
.carton img{
  border: 5px solid #dddddd;
    border-radius: 50px;
  
}
.ticket::after {
  content: "";
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMi41IChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NjIwMTFGOTdEMTgxMUVDQTQ5NjlGMDA3MEM4OEQzMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NjIwMTFGQTdEMTgxMUVDQTQ5NjlGMDA3MEM4OEQzMiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY2MjAxMUY3N0QxODExRUNBNDk2OUYwMDcwQzg4RDMyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY2MjAxMUY4N0QxODExRUNBNDk2OUYwMDcwQzg4RDMyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+9quY4AAAAelJREFUeNqsls8rRFEUx98bkyILRGGhIRYiouz9/gNECgtKfsWOzZAiJAtFs/NjQUNNxEpkxQqNbAwLagZlYu3HeIbne+s8vW7vPXfmzalP87rv3O+573TOuSOr0oIkYE4wCPrBNZigX6GNIjYLRum5BFSCFnAZT4BskA5+QDmduonzKQQHYBVsgQiIgifwqXeUdSmSSWySgsRjR6APBLUFh+5lFpi2Ic6sEXTpF/QBMkCmZN/yzQJ8S4mxL7MAZXGI3QEvUHVrBSCFD5AEhmIUfwAdoBNs69ZriL8AyWAONMQgfg9awTmJVXOlvwSqtAC9YMRAJARODNZZCbaRODuUD7g4nyKwBpodJqlRwDCoA5vcydvBGagHGxZlXcG0WYAbg5cqjQNWWT2U4xCl5RTUkniORRpZRwdYJ5fiYRcUGzjNgHGQRicNUjN5BRqSzS8P+4IAffazgdMYBXklcZaWdQFxD+0Na8POD5bptLy5wQe4oOGWI1Bh80aN5rPYNAV2QK5ACR+DR6MAEYtNsr47/zHFbFREEzSLVLMAYXBoU1zhNZxcigZAN8ijG81FleMwEdS6XSF/duHsmd1oRpZKs2aR2p8vRTZzbu1c+u9gH7yAFWr/NxJ2J/JfhZ+uU5bCKwogZL8CDAApmGwhfQiy+wAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background-color: transparent;
  background-size: 100%;
}
.ticket{
  position: relative;
}
.xenox div{
  padding-left: 20px;
}
.carton div{
  padding-left: 20px;
}
.xenoxpara{
  margin-bottom: 0 !important;
  font-size: 24px !important;
  color: #000;
  font-weight: 600;
}
.xenoxcreate{
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #000;
}

.nameimg h2{
  background: -webkit-linear-gradient(#000, #000);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.highsedbit{
  font-size: 12px !important;
}
.owl-nav{
  position: absolute;
  top: 43%;
  display: flex !important;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}
.owl-next{
  position: relative;
  right: -20px;
}
.owl-prev{
  position: relative;
  left: -20px;
}
.MuiPaper-elevation1{
  box-shadow: none !important;
}
.MuiAccordionSummary-content{
  justify-content: left;
 
}
.card{
  background: none;
  box-shadow: none;
}
.explorerecenlyadded span{
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  background: -webkit-linear-gradient(#9533d0, #6b33fa);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-body{
  /* width: 15%; */
  margin: 0 auto;
}
.explorerecenlyadded i{
  font-size: 20px;
  padding-left: 10px;
}
.MuiPaper-root{
  background-color:transparent !important;
}
.colors_ul li{
  list-style: none;
  font-size: 18px;
  font-weight: 600;
  margin-bottom:10px;
}
.makeStyles-drawerPaper-27{
  background-color: #fff !important;
}
.makeStyles-right-79{
  width: 30%;
  padding: 0 !important;
}
.footflex p{
  color: #fff;
}

.copyright_txt a{
  color: #9d9d9e !important;
  text-align: center;
  font-weight: 400;
}
.bordrline{
  background-color: #82828233;
  width: 100%;
  height: 1px;
  margin-top: 30px;
}
#spinButs .create_newbtn{
  background:none !important;
  border: none;
  width: 100%;
}
#spinButs .create_newbtn span{

  color: #000;
 
}
.bGNPUg > .minus ,.bGNPUg > .plus {
  color: #245278 !important;
}


@media (min-width:1200px) {

  h1.banner-head{
    font-size: 42px;
  }
  .xenoxpara{
    font-size: 18px !important;
  }
  

}
@media (min-width:1440px){.search_inp{max-width: 350px;
  min-width: 350px;}}
@media (max-width:1440px){.search_inp{max-width: 200px;
  min-width: 200px;}}

@media (max-width:1280px){
 
  .rowone{
    grid-template-columns: repeat(3 , 1fr );
  }
  .ethactios .lazyload-wrapper img{
    min-height:250px
  }
  .newsletter_grp_append{width:100px !important}
  .banner_head h1 {font-size: 50px !important;}
  .rowone1{
    grid-template-columns: repeat(3 , 1fr );
  }
 
}
@media (max-width:991px){
  .rowone{
    grid-template-columns: repeat(2 , 1fr );
  }
  .main_navbar .megamenu .dropdown-menu{
    overflow-y: scroll;
    min-height: 500px !important;
    max-height: 500px !important;
    top: 0 !important;
    left: 1px !important;
  }
  .banner_head{height: unset !important;}

  .title_text_white, .topcreators h2{font-size: 24px !important;}
  .noti_parent p{
    line-height: 20px;
  }
 
  .noti_ul #menu-list{
    max-width: 200px !important;
    min-width: 200px !important;
  }
  .rowone1{
    grid-template-columns: repeat(2 , 1fr );
  }
  
  .makeStyles-navLink-127 > span:first-child{
    justify-content: center;
  }
  
  footer .new_des_footer {
    width: 100%;
    padding-top:0px;
    padding-lefT:30px;
}
  .notowe{
    width: 35%;
  }
  .makeStyles-right-79{
    width: 50% !important;
  }
  .footflex p{
    text-align: left;
  }
  .new_des_footer{
    display: block !important;
  }
  .footflex{
    margin-bottom: 100px;  
  }
  footer h4 {
    font-size: 24px;}
    #spinBut button.create_btn {
      width: 25%;
  }
  h1.banner-head {
    font-size: 31px;
}
/* .card-body {
  width: 45%;} */
}
/* @media (max-width:960px){
  .mode button p svg{
    left:7px;
  }
} */
@media (max-width:767px){
  .rowone{
    grid-template-columns: repeat(2 , 1fr );
  }
  .ethactios .lazyload-wrapper img {
    min-height: 250px;
  }
  .banner_head h3,.banner_head h2{display: block !important;}
  .registerbann{display: none;}
  .regicenter{height: 100vh;}
  .gridtemplate{
    grid-template-columns: repeat(3 , 1fr);
    overflow-x: scroll;
    grid-gap: 20px;
  }
  .insertdiv{
    padding-left:10px;
  }
 
  .buyeruliil{
    padding: 0;
  }
  .noti_ul #menu-list{
    min-height: 80px;
  }
 

  .topcreators{
  text-align: center;}
 
  .rowone1{
    grid-template-columns: repeat(2 , 1fr );
  }
 
  .xenoxpara {
    font-size: 20px !important;
  }
  .masonry .item h3 span{
    font-size: 10px;
  }
  .masonry_likes span {
    font-size: 13px;
  }
  .masonry_likes i{
    font-size: 16px;
  }
  .card-body {
    width: 65%;
  }
  #spinBut button.create_btn {
    width: 35%;
}

.makeStyles-right-79 {
  width: 100% !important;
}
.notowe {
  width: 100%;
  margin-bottom: 30px;
}
}
@media (max-width:575px){
  .footflex{
    display: block;
  }
  .select_flex{
    display: flex;
    flex-direction: column;
  }
  .explore_enf .noti_ul button{width:100%;margin-bottom:10px;}
  .noti_parent_class{background:#fff;}
  .noti_parent_class p span{font-size:14px;}
  /* body .main_navbar li p {font-size: 13px !important;} */
  .gridtemplate{
    grid-template-columns: repeat(3 , 1fr);
  }
  .rowone{
    grid-template-columns: repeat(1 , 1fr );
    text-align: center;
  }
  .rowone1{
    grid-template-columns: repeat(1 , 1fr );
    text-align: center;
  }
  .xenoxpara{
    font-size: 18px !important;
  }
  .xenox , .carton{
    justify-content: center;
  }
  .banner_sec_row h1{
    font-size: 28px;
  }
  .btn .newbtn .mr-4 {
    margin-right: 0 !important  ;
  }
  
  .profillist{
    text-align:left;
  }
  .banner_sec_row p{
    width:100px;
  }
  /* .liveauction h2{
    font-size: 20px;
  } */
  .carton::after{
    right: -82px;
  }
  button.btn.newbtn{
    min-width: 103px;
  }
  span.gradbtn{
    min-width: 100px;
  }

  .newsletter_grp_append button{
    width: 112px;
  }
  #spinButs .create_newbtn{
    width: 100%;
  }
 
}
@media(max-width:425px){
  .items_bg_pink{
    padding: 10px;
  }
}
.form-control{
  border-bottom: 1px solid #000000 !important;

}
 /* surya ditmax*/
/******Ashwin ditmax*********/
.dark_theme p.light{
  display:none;
}
.dark_theme p.dark{
  display:block;
}
p.light{
  display:block;
}
p.dark{
  display:none;
}
.mode button{
  padding:0px;
  width:40px;
  height:40px;
  border-radius:50%;
  margin-left:15px;
}
.MuiButton-label span{
  font-family:Poppins;
}
.mode button p{
  margin:0px ! important;
}
.mode button p svg{
  margin:0px ! important;
}
.img_prgo_re{
  padding-left:0px ! important;
}

/* .hotcollect .owl-nav button.owl-next .fa-chevron-right{
  background-color: #eee !important;
  border-radius: 0px !important;
  padding: 14px;
  position: absolute;

  color: #000;
  clip-path: polygon(100% 53%, 0 0, 0 100%);
  left: 69%;
  top: 0%;
} */
.hotcollect .owl-nav button.owl-prev{
  background-color: #eee !important;
  border-radius: 0px !important;
  padding: 14px;
  clip-path: polygon(100% 53%, 0 0, 0 100%);
 
}
.hotcollect .owl-nav button.owl-prev .fa-chevron-left{
  color: #8c8c8c;
  font-size: 15px;
  margin-right: 21px;
}
.hotcollect .owl-nav button.owl-next{
  background-color: #eee !important;
  border-radius: 0px !important;
  padding: 14px;
  clip-path: polygon(100% 100%, 100% 0, 0 52%);
 
}
.owl-carousel .owl-stage-outer {
  padding: 10px !important;
  margin: -10px;
}
.owl-theme .owl-nav [class*='owl-']:hover{color: #000 !important;}
.hotcollect .owl-nav button.owl-prev .fa-chevron-right{
  color: #8c8c8c;
  font-size: 15px;
  margin-right: 21px;
}

footer .container-fluid{
  text-align:left;
} 
.plus,.minus{
  color:#245278 !important;
}

.inner_header{
  background-color: #fff;
  
    position: relative;
    z-index: 9;
   
}
#spinButs{margin-top: 80px;}
/*************Dark Mode*************/
.dark_theme .home_header {
  background-color: #fff;
  background-image: url("./assets/images/delete-Ditmax\ UI/Cropping/DARK-BC.png");
  position: relative;
  z-index: 9;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.dark_theme button.create_btn{
  /* background-image:-webkit-linear-gradient(-31deg,#000,#1f1f1f); */
  color:#000 ! important;
  font-weight:600 ! important; 
  box-shadow:0px 0px;
  text-transform: uppercase;
}
.dark_theme .banner_sec_row h1{
  color:#fff ! important;
}
.dark_theme span.gradbtn.grad-bordbtn{
  background-image:-webkit-linear-gradient(-31deg,#000,#1f1f1f);
}
.dark_theme span.gradbtn.grad-bordbtn{color:#fff;}
.dark_theme .create_para{
  color:#fff;
}

.dark_theme .banner_sec_row p{
  color:#fff !important;
}
.dark_theme .profileabnner{
  background-image:-webkit-linear-gradient(-31deg,#000,#1f1f1f);
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px; 
}
.dark_theme .banner-r{
  border:1px solid #fff;
}
.dark_theme .learnmore a{
  color:#fff ! important;
}
.dark_theme .rowone {
  background-color: #0b0b0b;
  border:1px solid #29143e;
}
.dark_theme .media_text{
  color:#fff;
}
.dark_theme .card_inner_item{
  background:#121212;
  border:1px solid #9933cc;
}
.dark_theme .dropdown .drop-down{
  color:#fff;
}
.dark_theme .xenoxpara{
  color:#fff;
}
.dark_theme .xenoxcreate{
  color:#fff;
}
.subxcribe{color: #03377f;font-size: 20px !important;font-weight: 600;}
/* .dark_theme .masonry .item h2,.dark_theme .dropdown-card .item h2{
  color:#fff ! important;
  
  background:transparent;
} */
.dark_theme .navtabs .item h2{
  color:#fff;
  background-image:-webkit-linear-gradient(#fff 100%, #fff 100%) ! important;
}
.artnft{font-size: 28px;}
.dark_theme header button.create_btn:hover{
  color:#000 !important;
  transition:1s;
  border:2px solid transparent;
}
.dark_theme .MuiButton-label b{color:#fff !important;}
.recentlyadded.explore .container-fluid{
  padding-top:50px;
}
/* .user_collect_img_tab{
  position: absolute;
  top: 0;
} */
.dark_theme .card_inner_item h2{
  background-image:-webkit-linear-gradient(#fff,#fff)
}
.mobile_nav.dark_theme button.create_btn{
  background-image:-webkit-linear-gradient(#fff,#fff);
}
.timed_sec .input-group-append p {
  display: flex;
  align-items: center;
  padding-lefT: 8px;
  text-align: center;
}
.dark_theme .nameimg img{
  box-shadow: 0 0 1px 3px #8f29d6;
  padding:0px;
}
.dark_theme .makeStyles-caret-230{
  color:#fff !important;
}
.dark_theme .inner_header{
  background-color: #fff;
    background-image: url("./assets/images/delete-Ditmax\ UI/Cropping/DARK-BC.png");
    position: relative;
    z-index: 9;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.dark_theme .not_found_text{
  color:#fff;
}
.dark_theme #spinButs .create_newbtn span{
  color:#fff ! important;
}
.dark_theme .items_tab .nav-link .tab_head{
  color:#fff ! important;
}
.dark_theme .accordian_head h2{
  color:#fff;
}
.dark_theme .accordian_para p{
  color:#fff;
}
.dark_theme .inner_header header{
  background:#0b0b0b;
  background-color:#0b0b0b ! important;
}
.dark_theme .section-head,.dark_theme .section-head-exp{
  color:#fff;
}
.dark_theme .primary_label{
  color:#fff;
}
.dark_theme .makeStyles-navLink-46:hover, .dark_theme .makeStyles-navLink-46:focus{
  background:transparent;
}
.dark_theme .makeStyles-caret-226{
  color:#fff;
}
.makeStyles-caret-226{
  color:#000;
}
.dark_theme .form-control{
  color:#fff !important;
  border-bottom: 1px solid #fff !important;
}
.dark_theme form button.create_btn:hover{
  color:#fff !important;
  transition:1s;
  font-weight:500;
}
.dark_theme .primary_modal .modal-content {
  background-color: #000;
}
.dark_theme .primary_modal .modal-content h5{
  color:#fff;
}
.dark_theme .primary_modal .modal-content span{
  color:#fff !important;
  position: relative;
  top: 0px;
  font-family:Poppins;
}
.dark_theme .MuiGrid-container{
  color:#fff;
}
.dark_theme .address_text{
  color:#fff;
}
.dark_theme .bio_desc{
  color:#fff;
}
.makeStyles-white-21{
  background:#fff !important;
}
.dark_theme .dark_logo{
  display:block;
}
.sub-linear{
  word-break: break-all;
}
.user_image{
  min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;

    overflow: hidden;
    border: 1px solid gray;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 20px;
}
.dark_theme .light_logo{
  display:none;
}
.dark_logo{
  display:none;
}
.light_logo{
  display:block;
}
.dark_theme .info_row{
  background:transparent;
}
.dark_theme .bg_pink{
  background:#0b0b0b;
}
.dark_theme .info_title{
  color:#fff;
}
.dark_theme .info_h3{
  color:#fff;
}
.dark_theme .info_h3 span{
  color:#fff;
}
.dark_theme .card-body p{
  color:#fff;
}
.dark_theme button.create_btn:hover{
  color:#fff !important; 
}
.dark_theme .modal-body p{color:#fff;}
.dark_theme button .MuiButton-label{color:#fff ! important;background:transparent;font-weight:500;}
.dark_theme header button .MuiButton-label{color:#000 ! important;background:transparent;font-weight:500;}
.dark_theme .MuiGrid-item .MuiButton-label{color:#fff ! important;}
.dark_theme button.btn_outline_red:hover{color:#fff !important;}
.dark_theme .btn_outline_red span{color:#fff ! important;}
.dark_theme .btn_outline_red:nth-child(2){
  content:'';
  background:transparent;
  text-transform:uppercase;
}
.btn_outline_red:nth-child(2){
  content:'';
  background:transparent;
  color:#000 !important;
}
.dark_theme .timed_sec .input-group-append p{
  color:#000 !important;
}
/* .dark_theme .modal-content  button.MuiButtonBase-root {
  background-image:-webkit-linear-gradient(-31deg,#000,#1f1f1f);
} */
.dark_theme .btn.newbtn:nth-child(2) span.gradbtn.grad-bordbtn{
  content:'';
  background:transparent ! important;
}
.dark_theme #share_modal .icon_div .fab{
  color:#fff;
}
/* .dark_theme .bid_form button.create_btn{
  background-image:-webkit-linear-gradient(-31deg,#000,#1f1f1f);
} */
.dark_theme .gradbtn.grad-bordbtn a{
  color: #fff;
}
.dark_theme #share_modal .icon_div p{
  color:#fff ! important;
}
.dark_theme .makeStyles-caret-97{
  color:#787878;
}
.dark_theme .mobile_nav{
  background:#000 !important;
}
.dark_theme header button.create_btn{
  background:#fff ! important;
}
.dark_theme .rowone{
  background-image:-webkit-linear-gradient(#0b0b0b,#000);
}
@media (max-width:380px){
  button.btn.newbtn{
    width:106px;
  }
  span.gradbtn{
    width:96%;
  }
}
.tokendetaiss{
  font-size: 12px !important;
}
.coin_header{
  border-radius: 50px;
}
.MuiAccordion-rounded{
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
@media (max-width: 767px) {
  .mode button p
  {
    margin-left: 11px !important;
  }
  .noti_parent_class{background:#fff;}
  .formCls span.img-file{font-size:14px;}
  .liveauction h2{font-size:24px;}
  .xenox {
    padding: 20px 0px;
  }
  .carton {
    padding: 20px 10px;
  }
  .select_flex{
    display: flex;
    /* flex-direction: column; */
  }
 
  .ml-cus button{font-size:12px;}
}

.owl-carousel .owl-item .item_inner_img  img
{
    width:auto !important;
}
.create_sing_bxactive{
  background-image:linear-gradient(41deg,#f05a2e,#f05a2e);
  border:1px solid #f05a2e !important;
}
.buycnacel .timed-aution-ended{height:auto ! important;padding:10px !important;font-size: 16px !important;border: 1px solid #03377f;color: #03377f !important;}
.items_tab .nav-link.active .tab_head,.items_tab .nav-link:focus .tab_head{color:#245278 ! important}
.create_box:hover {
  background-image: linear-gradient(41deg,#03377f, #03377f);
}
.banner-r a img{object-fit:cover;max-height:400px;min-height:400px;}
.colors_ul ul li{margin-bottom:10px;}
.yachiclub{margin-top:10px;}
.carousel-nav .owl-nav.disabled button span, .carousel-nav .owl-nav button span{line-height:26px;}
.live-auctions .carousel-nav .owl-nav button span{line-height:0px;}

.item_inner_img .img-fluid {
  width: auto !important;
  min-height:200px;
}
.buycnacel{display: flex;gap: 10px;justify-content: center;align-items: center;}
footer .new_des_footer{
  padding-left:0px;
}
.carousel-nav .owl-nav.disabled button span, .carousel-nav .owl-nav button span {
  line-height: 33px;
}
.modal-dialog .modal-header{padding-top:0px;padding-bottom:0px;}
.modal-dialog .modal-content{padding:25px 0;}
.model_acceptbid{padding:20px !important;}
.dark_theme .noti_parent p{color:#fff;}
.dark_theme .noti_parent_class p{color:#000;}
.form-control .primary_inp{margin-top:-1px;}
.dark_theme hr{border-color:#fff;}
.MuiAccordionDetails-root ul li{text-align:center;}
button.create_btn{height:auto;}
.modal-open .select1 div{border-bottom:0px solid transparent !important;}
.modal-open .timed_sec .input-group-append p {position: relative;top: 5px;}
.modal-open .single_dd_1 button{border-bottom:1px solid #101010 !important;}
.modal-open .single_dd_1 .filter_menu div{font-size:12px;}
.card-body {width: 100%;}
.ml-cus button{position:relative;left:-8px;}
.media-body .media_text{line-height:23px;}
.waiting_for_owner{height:auto !important;}
.card .card-body, .card .card-footer{padding:0px;}
.formCls span.img-file{content:'';position: absolute;top: 48px;right: 0px;}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #cdcdcd;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
.xenox img{width:80px;height:80px;}
.xenox .img_tick.img_tick_lg img{width:20px;height:20px;z-index:10}
.info .mid-cont img{max-height:400px;min-height:400px;object-fit:contain;height:auto;
position:relative;vertical-align:inherit;flex-grow:1;border-radius:8px;}
.info .flex_center{height:auto;}
.info .btn_ipfs{height: auto;background: transparent;border: 1px solid #245278;font-size: 14px;
  font-weight:400;border-radius: 5px;color: #245278;padding: 5px 10px;margin-top: 15px;}
  .info .mid-cont {
    /* width: 50%;
    height: 50%;*/
    max-height:600px;
    min-height:600px; 
    display: flex !important;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    background: transparent;
    aspect-ratio: auto;
    /* width:75%; */
    border-radius:8px;
    /* width:100%;height:100%;max-height:100%; */
  }
  .info .mid-cont img{
    width:90% !important;
    height: 90% !important;
    min-width:90% !important;
    min-height: 90% !important;
    max-width:90% !important;
    max-height: 90% !important;
  }
@media screen and (max-width:991px){
  .bg_pink{margin-top:20px}
  .buyer_div .mid-cont {max-height: 100%;}
  .nto_scrol_div{height:100% !important;}
  .banner_head h2 span,.home_header h2{font-size: 40px !important;}
  .topgridban h3,.auctioncards h3,.subxcribe{font-size: 16px !important;}

.banercontentDet h2{font-size: 13px !important; }
.banercontent{width: 100% !important;}
.banercontentDet p{text-align: left;}
.banercontent img{width: 50px !important;}
.auctions{grid-template-columns: repeat(2,1fr);}
 
  .banercontentDet p{font-size: 12px;}
.auctions .banercontentDet h2,.highestbid h4,.hearting span,.hearting i{font-size: 15px !important;}
}
@media screen and (max-width:767px){

  .info .mid-cont{max-height:100%;min-height:100%;}
  .customDrop button span{font-size:17px !important;}
  
  /* .nto_scrol_div {
    height: auto !important;
} */
.noti_online{font-size: 15px;
  color: #000;
  font-family: Poppins;}
  .colors_ul{background:transparent !important}
  .topcreators .accordian_para {
    top: 75px;
    left: 0px;
    position: absolute;
    text-align: center;
    right: 0px;
    width: 100px;
}
}
.info_media_img_div img{max-height: 40px;object-fit: cover;}
.mb-button{margin-bottom:30px !important;}
.click_here_text{font-size:14px;}
.modal-dialog-centered::before{content:'';display:block}
body .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.accordian_para{
  top: 100px;
  right: 99px;
  background: #fff;
  border-radius: 5px;
  /* box-shadow:0 0 6px #00000052; */
  border:1px solid rgba(0,0,0,0.1);
}
.accordian_paras{
  top: 20px;
  right: 99px;
  background: #fff;
  border-radius: 5px;
  box-shadow:0 0 6px #00000052;
}
.accordian_para .card{
  padding: 10px;
}
.colors_ul span{font-size:15px !important;}
.img-overlay_grid {
  border: 1px solid #392470;
  border-radius: 10px;
}
.exploreimgtabs {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.explore_img {
  display: flex;
}
.filter_menu.filter_menu_info {
  box-shadow: 0px 0px 6px 6px rgb(0 0 0 / 22%);
  background-color: #fff;
  border-radius: 2px !important;
  padding: 0px;
  min-width: 10rem !important;
  color: #000 !important;
}
.menu_itm {
  padding: 5px 15px;
  cursor: pointer;
}
.filter_menu_info .menu_itm.active span, .filter_menu_info .menu_itm:hover {
  color: #fff !important;
}
.menu_itm span {
  color: #777e90;
  font-size: 14px;
}
.img-overlay_grid .explorecollections img {
  margin: 0 auto !important;
  border-radius: 10px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.TwinCryptoPunks {
  display: flex;
  justify-content: space-between;
  padding-top: 22px !important;
  padding-left: 20px;
  padding-right: 22px;
  margin-bottom: 0;
  align-items: baseline;
}
.TwinCryptoPunks p {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}
.TwinCryptoPunks img {
  width: 30px;
  height: auto;
}
.numberseh {
  color: #000;
  font-weight: 600;
  padding-left: 20px;
  margin-bottom: 0;
}
.numberseh span {
  color: #ccc;
  font-size: 12px;
  padding-left: 10px;
}
.TwinCryptoPunks1 {
  justify-content: space-between;
    display: flex;
    padding: 21px;
}
.TwinCryptoPunks1 .buybe {
  color: #007aff !important;
}
.buybe {
  color: #007aff;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 20px;
}
.stock_list {
  font-size: 16px;
}
.explorefire_img img {
  width: 30px;
  height: auto;
  border-radius: 50px;
  border:2px solid #9a59e7;
  margin-left: -8px;
}
.explorefire img {
  width: 30px;
  height: auto;
  border-radius: 50px;
  text-align: center;
  border: 2px solid #9a59e7;
}
.MuiAccordion-root.Mui-expanded{
  margin: 0 auto !important; 
}
.dark_theme .dayone,.dark_theme .yachiclub,.dark_theme .buyerbtnn,.dark_theme .noti_online {
  color: #fff !important;
}
.dark_theme .explore_btn{
  border: 1px solid #ffffff42;
}
.dark_theme .filteradd{
  filter: invert(1);
}
.dark_theme .applybtn span{
  color: #7a33eb !important;
}
.faq_panel .MuiAccordion-rounded{display:inline;}
.faq_panel .accordian_para p{text-align:justify;padding:10px;}
.rowone1{overflow:auto;}
.topcreators button span{text-align: center;font-size: 30px;font-weight: 600;background: -webkit-linear-gradient(#9533d0,#6b33fa);
  --webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.topcreators .dropdown_header_ul{text-align:center;}
.buyeruliil p{font-family:Poppins !important;}
.buyeruliil{padding-left:0px;}
.dark_theme .hotcollect_body h2{
  text-align: center;
    color: #fff;
    font-size: 16px;
    padding-top: 20px;
    font-weight: 600
}
.faq .accordian_para{box-shadow:0px 0px;background:transparent;}
.faq .faq_panel .accordian_head h2{text-align:left;}
.faq .accordian_para p{padding:0px;}
.owl-nav .owl-prev,.owl-nav .owl-next{width:40px;height:40px;}
.owl-nav .owl-prev span,.owl-nav .owl-next span{font-size: 50px;position: relative;
  top: 4px;color:#fff;}
.topcreators .explorerecenlyadded{border:0px solid;}
.topcreators .explorerecenlyadded .ml-2 {
  font-size: 30px !important;}
.topcreators .accordian_para {top: 60px;}
.hotbid_section .accordian_para .card{position:absolute;height:100%;}
.hotbid_section .accordian_para{top:20px;}
.orderflex p{width:30px;}
.hotbid_section .gridtemplate{padding-left:0px !important;}
@media screen and (max-width:575px){
  .gridtemplate {
    grid-template-columns: repeat(2 , 1fr);
    overflow:hidden;
}
.media-body{
  flex-direction: column;
  align-items: self-start;
}
.follow_media {align-items: flex-start;}
.xenox_td{
  width: 50px;
}
/* .buynowbtnt button{
 display: none;
} */
.customDrop{
  display: block !important;
  text-align: center;
}
.profile_banner{text-align:center;}
.user_profileim{font-size:25px;text-align:center}
}
@media screen and (max-width:360px){
  .gridtemplate {
    grid-template-columns: repeat(1 , 1fr) !important;
    overflow:hidden;
}
}
.tokens .baner_image{width: 100%;}
.tokens .banercontentauction img{border-radius: 50%;
  width: 30px !important;
  height: 30px;
  border: 2px solid #ccc;
}
  
.explore .explorebg a{color:#000;font-size:14px !important;}
.explore .explorebg p{color:#000;font-size:14px !important;font-weight:600;text-transform:capitalize}
.home_header header{z-index:9999;}
.explore_enf .noti_ul button{border:1px solid rgba(0,0,0,0.3);margin-right: 10px;
  border-radius: 5px;
  padding: 5px 10px;}
  .title_text_white{margin:25px auto !important;}
  .profile_banner img{width:135px;height:135px;}
  .profile_banner input{width:100%;margin-bottom:20px;}
.dark_theme .hotcollect_body{border:1px solid #fff;}
header .noti_online{font-family:Poppins;}
.colors_ul,.colors_ul li{margin-bottom:0px;}
.colors_ul{    width: 100px;
  background: #fff;
  /* padding: 10px !important; */
}
.hotbid_section .colors_ul{padding:10px !important}
.topcreators .accordian_para .card-body{padding-top:0px !important;padding-bottom:0px !important;} 
/* .explorebg,.buernotir{height:90px} */
.image.user_collect_img_tab img{width:135px;height:135px;border-radius:50%;}
.home_header .ico_border{margin-top:-60px !important;}
.profile_banner{position:relative;width:150px;}
.profile_edit_banner{position:absolute;    bottom: -10px;
  right: 45%;background: #03377f;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;}
  .profile_edit_banner i{    position: relative;
    left: 0px;
    font-size: 13px;
    color: #fff;
    top: -1px;}
    .profile_edit_panel img{width:135px;height:135px;}
    /* .explore .noti_ul #menu-list{max-height:115px;min-height:115px;} */
.explorebg{padding:10px 0px 10px;}
.main_navbar li a{font-size:14px;}
.main_navbar li p{font-size:15px !important;}
.fa-heart.liked{color: #fe0000;}
p.balance_txt_header p.tokendetaiss.det {
  font-size: 12px !important;
}
.create_sing_bx:hover{background-image:none !important;background: #f05a2e;border: #f05a2e !important;}
.create_sing_bx.active{background-image:none !important;background: #f05a2e;border: #f05a2e !important;}
div#searchmneu_dd span {
  font-weight: 300;
  padding-left: 0;
  font-size: 14px;
}
.header-input ul li ul.noti_ul_dd li:nth-child(n) {
  content:'';
  border-bottom: 1px solid #e7e7e7;
}
.header-input ul li ul.noti_ul_dd li:last-child {
  content:none;
  border-bottom: 0px solid;
}
/******************Re-Work*******************/
header .noti_online {font-family: Poppins;font-size: 15px;color: #000;}
header .noti_ul #menu-list{border-radius:10px !important;}
.posAbsolute{position:relative}
.posAbsolute .bannerTitle{position:absolute;top:10px;height:100%;
padding:20px;}
.posAbsolute .bannerTitle h1{color:#fff;font-weight:600;}
.bannergrid a{position:relative;}
.bannergrid .bannerTitle{position:absolute;top:10px;;max-height:250px;min-height:250px;
  padding:20px;}
.bannergrid .bannerTitle h4{color:#fff;font-weight:600;}
.posAbsolute img{filter:brightness(60%);}
.bannergrid img{filter:brightness(60%);}
.explore_enf .nto_scrol_div{background:#fff;border-radius:10px;height:115px !important;border:1px solid rgba(0,0,0,0.1)}
.noti_ul #menu-list{background:transparent !important;box-shadow:0px 0px 0px 0px rgba(0,0,0,0) !important;}
.footflex ul li a:hover{color:#fff !important;transition:all 0.5s ease;margin-left:10px;}
.searchmneu_dd .flex_body{margin-left:25px;}
input[type="file"]#coverImg{top:-14px;}
.buyersSellers{border:0px solid transparent;text-align:center;}
.topcreators .accordian_para {top: 80px;left: 0px;position: absolute;text-align:center}
.filter_btn .fa-chevron-down:before {font-size: 15px;}
@media screen and (max-width:480px){
  .posAbsolute .bannerTitle h1{font-size:25px;}
  .posAbsolute .bannerTitle h1 p{font-size:16px !important;}
}
.banner_head {background-image: url("./assets/images/headbanner.png");
background-repeat: no-repeat;
background-size: 100% 100%;
margin-top: -21px;
height: 100vh;
}
.banner_head .container-theme{
display: flex;
    align-items: center;
    justify-content: center;}
.banner_head h2 {
  text-shadow:0px 10px 15px #0000003d;
  font-weight: 800;
  font-size: 80px;
  line-height: 1.2;
 color: #245278;
 display: block;
 /* margin-right: 20px; */
 margin-bottom:40px;
}    

.banner_head h3 {
  text-shadow:0px 10px 15px #0000003d;
  font-weight: 800;
  font-size: 100px;
  line-height: 1.2;
  margin: 0;
  display: inline;
 color: #f15a2d;
}        

.banner_head h1{font-size: 80px;color: #061724;text-shadow: 0 10px 15px rgb(0 0 0 / 24%);font-weight: 800;margin: 0;}
.banner_head h2 span{color: #245278;font-weight: 800;font-size: 100px;}

.banner_head p{color: #245278;font-weight: 400;font-size: 14px;}
.baner_image{width:70%;
  position: relative;
  /* height: 100%; */
  overflow: hidden;
  box-shadow:0 0 12px 1px #0000001f;
  border-radius: 20px;
  margin-left: auto;}
.banercontent img{width: 50px;height: 50px;border-radius: 50% !important;}

.banercontentDet{text-align: left;margin-left: 20px;}
.banercontentDet h2{color: #245278;font-size: 18px;font-weight: 600;margin: 0;}
.banercontentDet p{color: #061724;font-size: 14px;font-weight: 400;margin: 0;}
.banercontent{display: flex;
  align-items: center;
 
  background-color: #fff;
  max-width: 500px;
  min-width: 300px;
  padding: 20px;
 
 }

.hearting{color: #b0afaf;font-size: 18px;}
.hearting span{color: #b0afaf;font-size: 18px;}

 .banner_head p{color: #245278;font-weight: 400;font-size: 13px;}
.auctions .baner_image{width: 100%;
  position: relative;
  height: 100%;
  text-align: right;
  background-color: #fff;box-shadow:0 0 12px 1px #0000001f;
  border-radius: 20px;
  margin: 0 auto;}
.auctions .banercontentauction img{width: 30px !important;height: 30px;border-radius: 50% !important;object-fit: cover !important;box-shadow: 0 0 3px #000c;}
.profikdds{margin-left:-15px}
.highestbid img{width: 15px !important;height: 15px !important;box-shadow: none !important;object-fit: contain !important;}
.ethactiosban{
  min-height: 400px;
    max-height: 400px;
   
    overflow: hidden;
    /* height: auto; */
    display: flex;
    /* margin: 0 auto; */
    align-items: center;
    justify-content: center;
}
.ethactiosban img{object-fit: cover;height: 100%;min-height: 400px !important;width: 100% !important;}
.ethactiosban video{object-fit: cover;height: 100%;min-height: 400px !important;width: 100% !important;}
.ethactios{
  min-height: 270px;
  max-height: 270px;
  
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ethactios img {object-fit: contain;}
.banercontentDet{text-align: left;margin-left: 20px;}
.auctions .banercontentDet h2{color: #245278;font-size: 18px;font-weight: 600;margin: 0;text-align: left;}
.banercontentDet p{color: #061724;font-size: 14px;font-weight: 400;margin: 0;}
.auctioncards{
  width: 100%;
  padding: 20px;
}
.auctioncards h3{color: #245278;font-size: 16px;font-weight: 600;text-align: left;margin: 0;}
.highestbid{display: flex;align-items: center;justify-content: space-between;}
.highestbid p{color: #242425;font-size: 14px;font-weight: 400;margin-bottom: 0;}
.highestbid h4{color: #242425;font-size: 16px;font-weight: 400;display: flex;align-items: center;line-height: 0;margin: 0;}
.banercontentauction{display: flex;align-items: center;}
.abaucdetail{display: flex;align-items: baseline;justify-content: space-between;}
.customDrop button{background:transparent !important;box-shadow:0px 0px;border:0px solid;padding:0px 5px;margin-top:-1px;
}
.customDrop button:focus,.customDrop button:hover{box-shadow:0px 0px;background:transparent;}
.customDrop button span{  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #245278;
  font-family:Poppins;}
  .fa-chevron-down:before {
    content: "\f078";
    font-sizE: 14px;
}
.backgorundd:hover{
  background-color:transparent;
  box-shadow: none;
}
.hotbid_section .seealllister{
  font-size: 16px !important;
  color: #000 ;
  border: 1px solid #000;
 
  border-radius: 50px;
  padding: 5px 22px;
  
}
.explorebg.btnbuyerss{background: #fff;border-radius: 10px;border: 1px solid rgba(0,0,0,0.1);}
.buyersbtns{text-transform:capitalize}
.makeStyles-caret-60{color:#6b33fa;}
#agree_modal111 button.btn_outline_red{padding:7px 20px !important;}
.faq .accordian_para {box-shadow: 0px 0px;
  background: transparent;
  border: 0px solid;
  color: #000;
}
.customDropdown{margin-top:-2px;}
p.balance_txt_header p.tokendetaiss.det{line-height:2}
.hotcollect_body h2{text-align: center;
  font-size: 20px;
  font-weight: 600;
 color: #061929;
  min-height:65px;
  max-height:65px;
  text-transform:capitalize}
  .card_inner_item h2{
    text-align: center;
  font-size: 16px;
  font-weight: 600;
  background: -webkit-linear-gradient(#000, #000);
  --webkit-background-clip: text;
  -webkit-text-fill-color: transparent;text-transform:capitalize
  }
  .hotcollect_body img{width: 70% !important;margin: 0 auto !important;}
  .orderflex p{text-transform: capitalize;}
  .makeStyles-primaryHover-56:hover,.makeStyles-primaryHover-51:hover{background-color:transparent !important;
  color: #000 !important;
box-shadow: none !important;}
.xenox_td img{
  width: 50px;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 5px;
}
.title_textactivbity{
  font-size: 30px;
    font-weight: 600;
    color: #000;
}
.makeStyles-caret-60{
  color: #4e4e4e;
}
.table thead th{
  font-weight: 400;
}
.dflextdspace{
  display: flex;
  flex-direction: column;

}
.dflextdspace td p{
  font-weight: 500;
}
.dflextdspace td.dflextdspace td{
  padding: 0 !important;
}
.xenoxdfles{
  display: flex;
}
.dark_theme .table thead th{
  color: #fff;
}
.dark_theme .table th, .dark_theme .table td{
  color: #fff;
}
.dark_theme .buynowbtnt button{
  border: 1px solid #fff;
}
.activity .buernotir{
  position:relative;height:170px;}
.activity .explore_btn{border:1px solid #000;}
.activity ul.explorebg p{font-size:14px;}
.activity .nto_scrol_div{background:#fff;position:absolute !important;top:10px;width:100% !important;
top:65px !important;z-index:9;}
.inner_header header{z-index:9999;}
.explorebg1{padding-left:0px;}
.activity .buernotir{
  height: 200px;
    width: 200px;
    overflow: scroll;
}
body .explorebg1 li{padding-left:0px !important;}
.activity .inner_title{margin-bottom:30px;}
.createCollection{background:#000;border:1px solid #000}
.activity .customDropdown{text-transform:capitalize;color: #245278;}

@media screen and (max-width:1279px){
  .noti_parent{background: #fff !important;
    height: 300px;
    overflow: auto;}
    .auctions{grid-template-columns: repeat(2,1fr);}
   
    .banercontentDet p{font-size: 12px;}
  .auctions .banercontentDet h2,.highestbid h4,.hearting span,.hearting i{font-size: 15px !important;}
}

.hotcollect_body h2{font-size: 18px !important;}
.see_all_button{margin-top:3px;}
@media screen and (max-width:767px){
  .home_header h2{line-height:1.5;text-align: center;}
  .home_header h3
  {text-align: center;}
  
  .baner_image h2{margin-bottom: 0;}
  .baner_image{width: 100%;}
  .banner_head p{text-align: center;}
  .table-res .table-responsive{max-width:100%;overflow:auto;}
  .explore_enf .noti_ul button{width:100%;margin-bottom:10px;}
  .ethactios {
    min-height: 300px;
    max-height: 300px;
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .abaucdetail{flex-direction: column-reverse;}
  .timers p .hourds{font-size: 12px;}
  .starreiaf{flex-direction: column;}
  .auctions{grid-template-columns: repeat(2,1fr);}
 
}
.explore_enf{width:100%;}
body li.MuiListItem-root:hover {
  color: #000 !important;
  box-shadow: 0px 0px !important;
  background-color: transparent !important;
}
@media screen and (max-width:1200px){
  .nto_scrol_div{height:100% !important;}
  
}
header .exploreLinks .nto_scrol_div div{position:static !important;height:auto !important;}
.selct_form_input_h{
  border-bottom: 1px solid #000 !important;
}
.quantity_wirh{
  width: 100%;
  margin-bottom: 12px;
}

@media screen and (max-width:575px){
  .xenox,.carton{justify-content: start;}
  .liveauction .owl-nav button.owl-next:hover span {top: 0px;right: 0px;}
.liveauction .owl-nav button.owl-prev:hover span {top: 0px;left: 0px;}
.banner_head h2 span,.home_header h2,.home_header h3{font-size: 40px;text-align: center;line-height: 1.2;}
.banner_head p{text-align: center;}
.ethactiosban{min-width: unset !important;min-height: unset !important;}
.banercontentDet h2{font-size: 16px;}
.banercontent{width: 100%;}
.banercontentDet p{text-align: left;}
.banercontent img{width: 50px !important;}
.banner_head{background-size: 100%;}
.ethactios {
  min-height: 300px;
  max-height: 300px;
  overflow: hidden;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auctions{grid-template-columns: repeat(1,1fr);}
.banercontentDet p{font-size: 12px;}
.auctions .banercontentDet h2,.highestbid h4,.hearting span,.hearting i{font-size: 15px !important;}



}
@media screen and (max-width:410px){
  .auctions .banercontentDet h2,.highestbid h4,.hearting span,.hearting i{font-size: 15px !important;}
  .baner_image{width: 100% !important;}

.auctioncards h3{font-size: 14px !important;}
}
@media screen and (max-width:1279px){
.mobile_nav.dark_theme  button .MuiButton-label{color:#000 !important;}
}
.dark_theme .main_navbar .noti_online{color:#b3aeae !important}
.dark_theme .modal-body button {color:#fff !important}

header.fixedTop {
  position: fixed;
  z-index: 9999;
}
.home_header header {
  padding: 15px 0px !important;
  background-color: #fff !important;
}

header .MuiToolbar-gutters{justify-content: space-between;}
.footergrid{display: grid;grid-template-columns: repeat(3,1fr);gap: 80px;}
.foote {display: flex;justify-content: space-between;}

.footfour {
  margin-left: 30px;
}
.foote1 {display: flex;justify-content: space-between;}
.footone h4,.foottwo h4,.footfour h4,.footfive h4{font-size: 20px;color: #03377f;font-weight: 500;margin-bottom: 20px;}
.footone ul,.foottwo ul,.footfour ul,.footfive ul{list-style: none;}
.footone ul li a,.foottwo ul li a,.footfour ul li a,.footfive ul li a{color: #1a3c57 !important;font-size: 16px;font-weight: 500;cursor: pointer;transition: 0.5s ease;font-family: 'Roboto', sans-serif;}
.footone ul li a:hover,.foottwo ul li a:hover,.footfour ul li a:hover,.footfive ul li a:hover{color: #f05a2d !important;}

.footthree input{border: 1px solid #03377f;border-radius: 30px;padding: 2px 20px;}
.footthree #news_submit{    background-image: -webkit-linear-gradient(-11deg,#03377f,#03377f);color: #fff;border-radius: 30px;}
/* .footfour{margin-left: 30px;} */
.star-rating {
  direction: rtl;
  display: inline-block;
  padding: 0px;
  cursor: default;
}
.star-rating input[type=radio] {
  display: none;
}
.star-rating i{font-size: 14px;}
.star-rating label {
  color: #bbb;

  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.star-rating label:hover, .star-rating label:hover ~ label, .star-rating input[type=radio]:checked ~ label {
  color: #f2b600;
}
.starreiaf{display: flex;align-items: center;justify-content: space-between;}
.typecontrol3.form-control{border-bottom:0px solid #000000 !important ;}
.single_collectible .item_inner_img{display: flex;align-items: flex-start;justify-content: center;max-height: 250px;min-height: 250px;overflow: hidden;}
.single_collectible .item_inner_img img{
  object-fit: contain !important;
  border-radius: 10px !important;
}
.artnft{
  text-align: center;font-size: 18px;margin-bottom: 50px !important;
}
.star5 label.one i{color:#f2b600}
.star5 label.two i{color:#f2b600}
.star5 label.three i{color:#f2b600}
.star5 label.four i{color:#f2b600}
.star5 label.five i{color:#f2b600}
.star4 label.resourdces .one i{color:#f2b600}
.star4 label.two i{color:#f2b600}
.star4 label.three i{color:#f2b600}
.star4 label.four i{color:#f2b600}
.star3 label.one i{color:#f2b600}
.star3 label.two i{color:#f2b600}
.star3 label.three i{color:#f2b600}
.star2 label.one i{color:#f2b600}
.star2 label.two i{color:#f2b600}
.star1 label.one i{color:#f2b600}
.main_navbar .megamenu button.create_btn{padding-left:25px !important;padding-right:25px !important} 
.main_navbar .show>.btn.btn-secondary.dropdown-toggle{background-color: transparent; }
.main_navbar .megamenu .btn.btn-secondary{background-color: transparent;    color: #03377f;
  font-size: 14px;
  text-transform: capitalize;
  font-family: Poppins;
  font-weight: 400 !important;    padding: 16px;}
  .main_navbar .megamenu .btn.btn-secondary:hover{color: #26b6d4;}
  .main_navbar .megamenu .btn.btn-secondary:hover{box-shadow: none;}
  .main_navbar .megamenu  .dropdown-menu{transition: none;}
  .dropdwongrod{display: flex;}
  .main_navbar .megamenu .dropdown-menu .dropdown-item{padding: 20px;position: relative;}
  .resourcesimgs img{width: 20px;height: auto;}
  .main_navbar .megamenu .dropdown-menu .dropdown-item:hover{box-shadow: none;
    background-image: -webkit-linear-gradient(89deg, #fff0,#fff0);
    color: #26b6d4 !important;}
    .main_navbar .megamenu  .dropdown-menu .dropdown-item{font-weight: 600;font-size: 14px;}
@media screen and (max-width:1279px){
  .main_navbar .megamenu button.create_btn{margin-top:20px;}
  .dropdwongrod{display: block;}
}
.foote,.foote1{gap: 30px !important;}
.resourcesswe .accordion-header{margin-top: 0;}
.resourcesswe .accordion-button:not(.collapsed){font-weight: 600;}
.resourcesswe .accordionparalist h2{color: #26b6d4;font-size: 16px;font-weight: 600;}
.resourcesswe .accordion-button{font-weight: 600;font-size: 18px;}
.resourcesswe .accordionparalist ul{list-style: unset;}
.resourcesswe .accordionparalist span{color: #fe0000;font-size: 16px;}
.passowrdd{position:relative;}
.passowrdd .faeyee {position: absolute;
    right: 14px;
    top: 21px;}
    .signoutwallet{color: #03377f !important;
      font-size: 14px !important;
      text-transform: capitalize !important;
      font-weight: 400 !important;
      margin-left: 20px !important;
      font-family: Poppins !important;}
      .ethactios object{object-fit: cover;max-width: 100%;}
      .loadmorebtnds{    background: var(--theme-grad) !important;color: #fff !important;margin: 0 auto !important;padding: 8px 20px !important;border: 1px solid var(--theme-grad) !important;border-radius: 4px !important;}
      .signoutwallet:hover{background-color: transparent !important;}
      .loadmorebtndssec{text-align: center !important;}
@media  (max-width:1440px) and (min-width:1281px) {
  body .banner_head{
    padding-top:130px
  }
  .newsletter_grp_append{width:100px}
}
@media screen and (max-width:480px){
  .ethactiosban img {
    height: 250px;
  }
}
.footfour ul li .reoranga{color: #f05a2d !important;}
.ethactios a{width:100%;}
.ethactios .lazyload-wrapper {text-align:center;}
.ethactios .lazyload-wrapper img{object-fit: cover !important;width:100% !important;}
.profile_edit_banner{display:flex;align-items: center;justify-content: center;}
@media screen and (max-width:599px){
  .collection form{margin-top:30px;}
}
.my_items .tokens{margin-top:20px;}
.ethactios .lazyload-wrapper img{min-height:300px;}
@media (max-width:1198px) and (min-width:992px){
  .newsletter_grp_append{right:-40px}
  .newsletter_grp_append button{width:100px;}
  .footthree input{font-size:13px;font-weight:500;padding: 2px 10px;}
}
.fade.show{display:block;}
.click_button{display: flex;align-items: baseline;}
.click_button a{color: #03377f;}
.nobidyet{color: #999999;text-align: center;font-size: 18px;}
.myitemsds{position: relative;}
.myitemsds button{padding: 0 !important;color: #000 !important;}
/* .myitemsds b{display: none;} */
.myitemsds .nto_scrol_div {
  background: #fff;
  border-radius: 10px;
  height: 115px !important;
  border: 1px solid rgba(0,0,0,0.1);
}
.myitemsds .myitemscolelds{color: #000 !important;font-size: 16px !important;transition: all 0.5s ease;}
.myitemsds .myitemscolelds:hover{color: #245278 !important;font-size: 16px !important;}
.myitemsbtns{font-size: 16px;
  /* border: 1px solid #00000030; */
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 600;
  padding: 0;
  font-family: Poppins;}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: #fff;
    width: 100%;
    height: 40px;
}
select {
    display: none;
}
.wrap h1{
    text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px #49ff18, 0 0 30px #49FF18, 0 0 40px #49FF18, 0 0 55px #49FF18, 0 0 75px #49ff18, -40px -40px 0px rgba(28,110,164,0);
    color: #181B33;
    background: #232323;
    font-size: 47px;
    text-align: center;
    font-family: monospace;
}
.custom-select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    color: #9E9E9E;
    padding: 8px 15px;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}
.custom-select:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color:#9E9E9E transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
    transform: rotateZ(0deg);
    transition: all 0.5s;
    transform-origin: 50% 25%;
}
/*.custom-select:hover {
    background-color: #b83729;
}
*/
.custom-select:active, .custom-select.active {
    background-color: #fff;
    color: #9E9E9E;
}
.custom-select:active:after, .custom-select.active:after {
    transform: rotateZ(180deg);
    transition: all 0.5s;
    transform-origin: 50% 25%;
}
.custom-select:focus{
   outline: 2px;
}
.select-options {
    max-height: 200px;
    overflow-y: auto;
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #AED581;
    border-radius: 0px 0px 5px 5px;
}
.select-options li {
    color: #424242;
    margin: 0;
    padding: 12px 0;
   /* text-indent: 15px;
   */
   /* border-top: 1px solid #AED581;
   */
    -moz-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    padding-left: 15px;
}
.select-options li:hover {
    color: #424242;
    background: #fff;
}
.select-options li[rel="hide"] {
    display: none;
}
::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
    border-radius: 0 0 5px 0;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#9E9E9E;
}

.congrrats{color: #03377f !important;}
.startomek{color: #1babfe !important;}
.faq .accordian_para p {
  padding: 0px;
  font-weight: 400;
  font-size:16px;
}
.accordian_para h3{text-align: left;font-size: 20px;
  font-weight: 600;}
.accordian_para ul li{list-style-type: none;
  font-weight: 400;text-align: left;}
.accordian_head h2{color: #26b6d4 !important;
  font-family: Poppins,sans-serif !important;
  font-size: 20px;
  font-weight: 500 !important;
  margin-bottom: 25px;
  margin-top: 50px;}
.plus,.minus{
  color:#26b6d4 !important 
}
.faq h2.inner_title{color: #2315a7 !important;
  font-family: Poppins,sans-serif !important;
  font-weight: 620;
  text-align: center;
  width:100%;
  font-size:1.75rem;
}
.faq_panel .accordian_head h2{
  font-size: 20px !important;
}
.faq_panel .MuiAccordionSummary-content {
  margin: 20px 0;
}
.faq_panel .accordian_para{background-color: #f5f8fc;
  color: #000000a1;
  padding: 25px;}
.faq_panel .bg_red_1{padding-bottom: 0px;margin-bottom: 0px;
letter-spacing: 1px;}
.category{position: relative;}
.category .dropdown{position: absolute;
  width: 100%;
  background: #fff;
  z-index: 999;}
.category .dropdown .sub-dropdown{
  display:none
}
.category .dropdown ul li{list-style-type: none;}
.category .dropdown .dropdown-sub:hover .sub-dropdown{
display:block
}
.dropdown-sub{cursor:pointer}
.sub-dropdown li{font-size:13px;}
.category .form-control{height:auto !important}
.category .dropdown-sub{color:#000 !important}
.category .dropdown .dropdown-sub ul{padding-left:10px !important}
.audio_align{
display: block !important;
z-index: 100000 !important;
}

.MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #03377f;
    color: #fff !important;
    border-radius: 5px;
    font-family: Poppins;
    height: 40px;
    padding: 8px 15px;
    transition: 0.2s all;
    border: 1px solid #03377f;
    font-weight: 400 !important;
    text-transform: capitalize;
    font-size: 14px;
    opacity: 1;
}
header .img-fluid {
  max-width: 110px;
}
.form-row.price{padding-left:0px !important;padding-right:0px !important}
.info_btn_kralign{display: flex;justify-content: flex-end;align-items: center;}
.mint_freecost{font-size: 13px !important;}
.card.previewy_card{
  border-radius: 20px !important;
  box-shadow: 0 0 12px 1px #0000001f;
  position: relative;
}
.previewy_card video,.previewy_card audio{
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 250px !important;
  max-height: 250px !important;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

}
.previewy_card audio{
  position: absolute;
  top: -20px;
  left:0;
}
.previewy_cardban.card-img-top{
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  min-height: 250px !important;
  max-height: 250px !important;
  object-fit: cover;
}
.card-body.previewy_card_body{
  padding: 20px !important;
}
.previewy_name{
  font-size: 16px;
  color: #245278;
  font-weight: 600;
  margin: 0 !important;
}
.previewy_price{
  font-size: 13px !important;
  margin: 0 !important;
}
.previewy_card_ttl,.previewy_bot{
  display: flex;
  justify-content: space-between;
}
.previewy_nbn{
  height: 15px;
  width: 15px;
}
.noimage_previewy{
  width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
    box-shadow: 0 0 5px #000c;

}
.noimg_address{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.previewy_addre{
  font-size: 14px;
  color: #245278;
  font-weight: 600;
  margin: 0;
  margin-left: 5px;

}
.uploaded_file.primary_label{
  font-size: 25px !important;
}
.tokencard_tok.tokens{
  position: relative;
}
.tokencard_tok.tokens .audio_align{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 35%;
  max-width: 90% !important;
}
@media screen and (max-width:1440px){
  .banner_head h2{font-size:80px}
}
.form-group.network{padding-right:0px !important}
.form-group.network .form-row{margin-right:8px;}
.headerflexs a.NFT{font-size:20px;font-weight:700}

#create_item_modal2 .approve_desc{margin-top:-10px;margin-bottom:5px;}
.form-row.type{margin:0px -15px !important}
.agreement{align-items: flex-start;margin-bottom:20px;margin-top:10px}
.agreement label{color:#000}
.banner_desc_user:hover{color:#26b6d4 !important;transition: all 0.5s ease;}

/* .headerflexs ul li:last-child a{content:none;display:none} */

.dropdown-menu1{list-style-type: none;}
.sub-dropdown{list-style-type:none;display:none}
.dropdown-sub:hover .sub-dropdown{
  display:block
}
.banner_head .row{
  display:flex;
  align-items: center;
  justify-content: center;
}
.banner_head h3{
  font-size:35px !important;
  color:#f25e32 !important;
  margin-top:30px !important
}
.homebnns p{
  margin-top:30px;
}
.accordionparalist span{
  color:#26b6d4 !important;
  display:block !important
}
.mid_conts.mid-cont:hover audio{
  display: block;
}
@media only screen and (min-width:319px) and (max-width:575px){
  .resform_note.form_note{
    max-width: 320px;

  }
}
@media only screen and (min-width:576px) and (max-width:991px){
  .resform_note.form_note{
    max-width: 350px;

  }
}
@media only screen and (min-width:992px) {
  .resform_note.form_note{
    max-width: 670px;

  }
}
.cursor {
  cursor: pointer;
}


.audio_pos
{
  position: absolute;
    margin: 0 auto;
    width: 90%;
    bottom:10px;
}

.ethactiosban_pos_rel_au
{
  position: relative;
}
.ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
  height: 400px !important;
  width: 100% !important;
  min-height: 400px !important;
  min-height: 400px !important;
  max-height: 100% !important;
  display: flex;
  align-items: flex-start;

}
.ethaction_video.ethactiosban video{
  height: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  object-fit: unset;
  margin: 0 !important;
  padding: 0 !important;

}
@media only screen and (min-width:300px) and (max-width:400px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 180px !important;
    min-height: 180px !important;
    max-height: 180px !important;
  }
}
@media only screen and (min-width:400px) and (max-width:500px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 220px !important;
    min-height: 220px !important;
    max-height: 220px !important;
  }
}
@media only screen and (min-width:500px) and (max-width:600px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 270px !important;
    min-height: 270px !important;
    max-height: 270px !important;
  }
}
@media only screen and (min-width:600px) and (max-width:700px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 340px !important;
    min-height: 340px !important;
    max-height: 340px !important;
  }
}
@media only screen and (min-width:700px) and (max-width:768px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 410px !important;
    min-height: 410px !important;
    max-height: 410px !important;
  }
}
@media only screen and (min-width:768px) and (max-width:800px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 275px !important;
    min-height: 275px !important;
    max-height: 275px !important;
  }
}
@media only screen and (min-width:800px) and (max-width:900px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 210px !important;
    min-height: 210px !important;
    max-height: 210px !important;
  }
}
@media only screen and (min-width:900px) and (max-width:1000px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 250px !important;
    min-height: 250px !important;
    max-height: 250px !important;
  }
}
@media only screen and (min-width:1000px) and (max-width:1100px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 270px !important;
    min-height: 270px !important;
    max-height: 270px !important;
  }
}
@media only screen and (min-width:1100px) and (max-width:1200px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 300px !important;
    min-height: 300px !important;
    max-height: 300px !important;
  }
}
@media only screen and (min-width:1200px) and (max-width:1300px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 270px !important;
    min-height: 270px !important;
    max-height: 270px !important;
  }
}
@media only screen and (min-width:1300px) and (max-width:1400px){
.ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
  height: 280px !important;
  min-height: 280px !important;
  max-height: 280px !important;
}
}
@media only screen and (min-width:1400px) and (max-width:1500px){
  .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
    height: 280px !important;
    min-height: 280px !important;
    max-height: 280px !important;
  }
  }
  @media only screen and (min-width:1500px) and (max-width:1600px){
    .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
      height: 300px !important;
    min-height: 300px !important;
    max-height: 300px !important;
    }
    }
    @media only screen and (min-width:1600px) and (max-width:1700px){
      .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
        height: 330px !important;
      min-height: 330px !important;
      max-height: 330px !important;
      }
      }
      @media only screen and (min-width:1700px) and (max-width:1800px){
        .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
          height: 360px !important;
        min-height: 360px !important;
        max-height: 360px !important;
        }
        }
        @media only screen and (min-width:1800px) and (max-width:1900px){
          .ethaction_video.ethactiosban.ethactiosban_pos_rel_au{
            height: 380px !important;
          min-height: 380px !important;
          max-height: 380px !important;
          }
          }